import { useMemo, useState } from "react";
import { Appointment } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import { differenceInMilliseconds } from "@scrile/tools/dist/lib/TimeHelpers";
import { t } from "locales";
import { get12formatTime, getDate } from "lib/timeHelper";

export default function useViewController(appointment: Appointment, isConsultant: boolean) {
  const [showModal, setShowModal] = useState(false);
  const user = useMemo(() => (isConsultant ? appointment.consumer : appointment.provider), [isConsultant, appointment]);
  const [isAppointmentPaid, setIsAppointmentPaid] = useState(appointment.paid);
  const status = useMemo(() => (isAppointmentPaid ? t("Paid") : t("Unpaid")), [isAppointmentPaid]);
  const date = getDate(appointment.startTime);

  const time = get12formatTime(appointment.startTime);
  const min =
    differenceInMilliseconds(new Date(appointment.endTime), new Date(appointment?.startTime || "")) / 1000 / 60;

  const onOpenModal = () => {
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onAppointmentPaidSuccess = () => {
    setIsAppointmentPaid(true);
  };

  return {
    user,
    date,
    time,
    min,
    status,
    showModal,
    onOpenModal,
    onCloseModal,
    isAppointmentPaid,
    onAppointmentPaidSuccess,
  };
}
