import { useState } from "react";
import { AppointmentType } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import useController from "./controller";

export default function useViewController() {
  const [syncing, setSyncing] = useState(false);
  const { syncAppointmentTypes } = useController();

  const onSyncButtonClick = async (setSyncedAppointments: (appointmentTypes: AppointmentType[]) => void) => {
    if (syncing) return;
    try {
      setSyncing(true);
      await syncAppointmentTypes(setSyncedAppointments);
    } finally {
      setSyncing(false);
    }
  };

  return {
    syncing,
    onSyncButtonClick,
  };
}
