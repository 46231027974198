import React from "react";
import { Review } from "@scrile/api-provider/dist/api/ReviewProvider";
import useBreakPoints from "hooks/useBreakPoints";
import { getDate } from "lib/timeHelper";
import BaseRating from "components/BaseRating";
import TextClamp from "components/TextClamp";
import "./style.scss";

interface Props {
  review: Review;
}

export default function ReviewItem({ review }: Props) {
  const { year, month, dayOfMonth } = getDate(review.time);
  const { desktopUp } = useBreakPoints();

  return (
    <div className="review-item">
      <div className="review-item__comment body">
        <TextClamp lines={desktopUp ? 6 : 8}>{review.comment}</TextClamp>
      </div>
      <div className="review-item__info">
        <BaseRating className="review-item__rating mb-3" value={review.rating} />
        <h3 className="review-item__name mb-2">{review.reviewerUser.screenName}</h3>
        <span className="review-item__time small">{`${month} ${dayOfMonth}, ${year}`}</span>
      </div>
    </div>
  );
}
