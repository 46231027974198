import React from "react";
import config from "config";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseForm from "components/BaseForm";
import TagInput from "components/TagInput";
import UploadMedia from "components/UploadMedia";
import useViewController from "./viewController";
import "./style.scss";

function ProfileSettingsPage() {
  const {
    role,
    fields,
    values,
    onChange,
    onSave,
    errors,
    setErrors,
    onInputAvatar,
    src,
    loadingForm,
    userTags,
    allTags,
    onAddingTag,
    onDeleteTag,
  } = useViewController();
  const { t } = useTranslation();

  return (
    <div className="profile-settings">
      <div className="profile-settings__container-avatar">
        <UploadMedia
          photoClassName="profile-settings__avatar"
          aspectRatio={0}
          accept={["image/*"]}
          onInput={onInputAvatar}
          src={src}
        />
      </div>
      <BaseForm
        className="profile-settings__form"
        fields={fields}
        values={values}
        onChange={onChange}
        onSubmitEnter={onSave}
        errors={errors}
        setErrors={setErrors}
      >
        {role === config.userPerformerRole && (
          <TagInput
            className="mt-4"
            name={"tagInfo"}
            value={userTags}
            suggestions={allTags}
            onDelete={onDeleteTag}
            onAddition={onAddingTag}
            label={t("Practice area tags")}
            tooltipText={t("Start typing tags. Hit tab, comma, or return to complete. Hit backspace/delete to remove.")}
          />
        )}

        <BaseButton
          loading={loadingForm}
          className="profile-settings__button"
          onClick={onSave}
          large={true}
          name="submit"
        >
          {t("Save Changes")}
        </BaseButton>
      </BaseForm>
    </div>
  );
}

export default ProfileSettingsPage;
