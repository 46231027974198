import React from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import questionnaireFieldsConfig from "questionnaireConfig";
import { useTranslation } from "locales";
import useBreakPoints from "hooks/useBreakPoints";
import useCustomDomain from "hooks/useCustomDomain";
import AddToFavorite from "components/AddToFavorite";
import BaseCard from "components/BaseCard";
import BaseIcon from "components/BaseIcon";
import BaseTags from "components/BaseTags";
import CardAvatar from "components/CardAvatar";
import TextClamp from "components/TextClamp";
import "./style.scss";

interface Props {
  user: UserPublicData;
  className?: string;
}

function PerformerProfileCard({ user, className }: Props) {
  const { t } = useTranslation();
  const { tabletUp: isTablet } = useBreakPoints();
  const { isCustomDomain, user: userFromDomain } = useCustomDomain();

  const cls = ["performer-profile"];
  if (className) cls.push(className);

  return (
    <BaseCard className={cls.join(" ")}>
      <div className="performer-profile__inner">
        <div className="performer-profile__top">
          {!isCustomDomain && <h3 className="performer-profile__name headline-2">{user.screenName}</h3>}
          {isTablet && !isCustomDomain && (
            <div className="performer-profile__status-line">
              <AddToFavorite className="performer-profile__favorite" withText user={user} />
            </div>
          )}
        </div>
        {!isCustomDomain && user.questionary?.fields?.location && (
          <div className="performer-profile__location">
            <BaseIcon className="performer-profile__location-icon">location</BaseIcon>
            <span className="performer-profile__location-text body">
              {user.questionary?.fields?.location as string}
            </span>
          </div>
        )}
        <div className="performer-profile__container">
          <CardAvatar
            to={
              user.publicLivechatUser?.broadcasting ? `${user.id === userFromDomain?.id ? "" : user.username}/live` : ""
            }
            className="performer-profile__avatar-container"
            user={user}
            classNameAvatar={`performer-profile__avatar${isCustomDomain ? " __custom" : ""}`}
          />
          {user.questionary?.fields.about && (
            <div className="performer-profile__about">
              <h4 className="performer-profile__about-header body-big">{t("About me")}</h4>
              {isCustomDomain && user.questionary?.fields?.location && (
                <div className="performer-profile__location  __custom">
                  <BaseIcon className="performer-profile__location-icon">location</BaseIcon>
                  <span className="performer-profile__location-text body">
                    {user.questionary?.fields?.location as string}
                  </span>
                </div>
              )}
              <TextClamp lines={5} className="performer-profile__about-text body">
                {user.questionary?.fields?.about as string}
              </TextClamp>
            </div>
          )}
        </div>
        {!!user.questionary?.tags?.length && (
          <div className="performer-profile__practice-area">
            <h4 className="body-headline">{t("Practice area")}</h4>
            <BaseTags className="performer-profile__practice-area-tags" user={user} />
          </div>
        )}
        <div className="performer-profile__questionnaire">
          {questionnaireFieldsConfig.map((item) => {
            const content = user.questionary?.fields[item.name];

            if (content) {
              return (
                <div key={item.name} className="performer-profile__questionnaire-item">
                  <h4 className="body-headline mb-3">{item.label}</h4>
                  <pre className="performer-profile__questionnaire-text body">{content as string}</pre>
                </div>
              );
            } else return null;
          })}
        </div>
      </div>
    </BaseCard>
  );
}

export default PerformerProfileCard;
