import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseInput from "components/BaseInput";
import PageLoading from "components/ElementLoading";
import useViewController from "./viewController";
import SyncButton from "../SyncButton";
import "./style.scss";

export default function AppointmentsPricing() {
  const {
    fields,
    values,
    onChange,
    onSave,
    appointments,
    processing,
    loading,
    setSyncedAppointments,
  } = useViewController();
  const { t } = useTranslation();

  const textTitle = fields.length > 0 ? "Set costs for your service" : "No meeting types";
  const textContent =
    fields.length > 0
      ? `We found these meeting types in your Calendly account settings. 
         Please set their cost and note that the new service cost will only apply to new appointments.`
      : "We did not found meeting types. You can set them in your Calendly account settings.";

  return (
    <div className="appointments-pricing">
      {loading ? (
        <PageLoading loading={loading} />
      ) : (
        <>
          <h2 className="body-big mb-3">{t(textTitle)}</h2>
          <p className="body appointments-pricing__text mb-6">{t(textContent)}</p>
          <div className="appointments-pricing__prices">
            {fields.map((f, i) => (
              <div className="appointments-pricing__prices-item divider-bottom" key={f.name}>
                <div title={f.label as string} className="body fw-semibold appointments-pricing__prices-label">
                  {f.label}
                </div>
                <BaseInput
                  {...f}
                  label=""
                  type="text"
                  value={values[f.name]}
                  onChange={(v) => onChange(v, f.name)}
                  name={f.name}
                />
                <div className="appointments-pricing__prices-duration">
                  /{appointments[i].duration} {t("min")}
                </div>
              </div>
            ))}
          </div>
          <div className="appointments-pricing__buttons">
            <SyncButton setSyncedAppointments={setSyncedAppointments} />
            {fields.length > 0 ? (
              <BaseButton className="appointments-pricing__save-btn" onClick={onSave} loading={processing} large>
                {t("Save Changes")}
              </BaseButton>
            ) : (
              <NavLink to={{ pathname: "https://calendly.com/event_types/user/me/" }} target="_blank">
                <BaseButton large className="appointments-availability__btn">
                  {t("Go to Calendly")}
                </BaseButton>
              </NavLink>
            )}
          </div>
        </>
      )}
    </div>
  );
}
