import { useRef } from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { isAndroid } from "@scrile/tools/dist/lib/browserUtils";
import useBreakPoints from "hooks/useBreakPoints";
import useKeepScrollPosition from "hooks/useKeepScrollPosition";
import useController from "./controller";

function useViewController(token: string, users: LiveChatUser[]) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { shouldScrollToBottom } = useKeepScrollPosition(containerRef);
  const { desktopUp, mobileLandscape } = useBreakPoints();
  const userNames: Record<string, string> = users.reduce((acc, i) => {
    acc[i.userId] = i.screenName || i.user?.screenName || "";
    return acc;
  }, {} as Record<string, string>);

  const onFocus = () => {
    if (isAndroid()) {
      shouldScrollToBottom.current = true;
    }
  };

  const { messages, user, guestUser, setGuestUser } = useController(token, containerRef, shouldScrollToBottom);

  return {
    containerRef,
    messages,
    onFocus,
    desktopUp,
    mobileLandscape,
    userNames,
    guestUser,
    user,
    setGuestUser,
  };
}

export default useViewController;
