import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "locales";
import useMounted from "hooks/useMounted";
import emitter, { EVENTS } from "lib/emitter";
import { BaseMenuItemInterface } from "types";
import useController from "./controller";

const defaultState = { show: false, step: 1, success: false };

export default function useViewController() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const [state, setState] = useState(defaultState);
  const { sendVerificationRequest } = useController();

  const steps: BaseMenuItemInterface[] = [
    {
      name: "personalInformation",
      title: t("Personal Information"),
    },
    {
      name: "photoId",
      title: t("Photo ID"),
    },
    {
      name: "payeeInformation",
      title: t("Payee information"),
    },
  ];

  useEffect(() => {
    const listener = () => {
      if (!mounted.current) return;
      setState((s) => ({ ...s, show: true }));
    };
    emitter.on(EVENTS.START_PERFORMER_VERIFICATION, listener);
    return () => {
      emitter.off(EVENTS.START_PERFORMER_VERIFICATION, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => setState(defaultState);

  const onNext = useCallback(async () => {
    setState((s) => ({ ...s, step: Math.min(s.step + 1, steps.length) }));
    if (state.step === steps.length) {
      const success = await sendVerificationRequest();
      setState((s) => ({ ...s, success }));
    }
  }, [sendVerificationRequest, state.step, steps.length]);
  const onPrev = useCallback(() => {
    setState((s) => ({ ...state, step: Math.max(s.step - 1, 1) }));
  }, [state]);
  const onTabClick = (item: BaseMenuItemInterface) => {
    const index = steps.findIndex((i) => i.name === item.name);
    if (index > -1) setState(() => ({ ...state, step: Math.min(Math.max(index + 1, 1), steps.length) }));
  };
  return {
    ...state,
    steps,
    onNext,
    onPrev,
    onTabClick,
    onClose,
  };
}
