import React from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";
import { useTranslation } from "locales";
import { DefaultStreamViewProps } from "types";
import BaseAvatar from "components/BaseAvatar";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import ModalWindow from "components/ModalWindow";
import PanelControlButtons from "./components/PanelControlButtons";
import PlayStreamButton from "./components/PlayStreamButton";
import RightPanel, { RightPanelView } from "./components/RightPanel";
import RippleAvatarAnimation from "./components/RippleAvatarAnimation";
import TransitionControl from "./components/TransitionControl";
import useViewController from "./viewController";
import "./style.scss";

interface Props extends DefaultStreamViewProps {
  slotContentConfirmationExit?: JSX.Element;
  disableUserList?: boolean;
  slotTimer?: JSX.Element;
  disabledVideo?: boolean;
  slotVideoScreen?: JSX.Element;
  slotHeaderButtons?: JSX.Element;
  slotControlButtons?: JSX.Element;
  slotAdditionalControlButtons?: JSX.Element;
  forceHideControlButtons?: boolean;
  hideVideoElement?: boolean;
  forceShowStreamPlayButton?: boolean;
  fullLeftSide?: boolean;
  userListIcon?: string;
  onItemIconClick?: () => void;
  enableUserSearch?: boolean;
  rightPanelModalView?: boolean;
  includeGuestsList?: boolean;
  allowGuestMessage?: boolean;
  me?: LiveChatUser;
}

function StreamView({
  produceUser,
  isProducerUser,
  consumeUser,
  produce,
  consume,
  stream,
  managedStream,
  loading,
  threadId,
  userList,
  streamStartedDate,
  onClose,
  token,
  constraints,
  managedConstraints,
  audioInputs,
  videoInputs,
  onChangeConstraints,
  disableAttachments,
  disableUserList,
  showConfirmationExit,
  forceHideControlButtons,
  forceShowStreamPlayButton,
  slotTimer,
  disabledVideo,
  slotVideoScreen,
  hideVideoElement,
  slotHeaderButtons,
  slotContentConfirmationExit,
  slotControlButtons,
  slotAdditionalControlButtons,
  fullLeftSide,
  userListIcon,
  onItemIconClick,
  enableUserSearch,
  rightPanelModalView,
  allowGuestMessage,
  className,
  includeGuestsList,
  me,
}: Props) {
  const { t } = useTranslation();
  const {
    player,
    changeControls,
    changeRightPanel,
    onClickEndCall,
    desktopUp,
    mobileLandscape,
    showControls,
    showControlsButtons,
    showStreamPlayButton,
    onShowControls,
    onToggleCam,
    onToggleMic,
    onToggleBlur,
    onCloseEvent,
    onSwitchCam,
    facingMode,
    displayControls,
    hasVideo,
    hasShare,
    hasAudio,
    videoWrapper,
    streamView,
    leftSide,
    rightSide,
    usersCount,
    onOpenChat,
  } = useViewController({
    isProducerUser,
    produce,
    stream,
    managedStream,
    constraints,
    managedConstraints,
    onChangeConstraints,
    userList,
    onClose,
    showConfirmationExit,
    hideControlsOnChat: rightPanelModalView,
  });

  const mobileChatMode = displayControls.rightPanel === RightPanelView.CHAT && !desktopUp;
  const mobileRightPanelModalMode = rightPanelModalView && !desktopUp;
  const settingsMode = displayControls.rightPanel === RightPanelView.SETTINGS;
  const usersListMode = displayControls.rightPanel === RightPanelView.USERS;

  const cls = ["stream-view"];
  if (mobileChatMode) cls.push("__chat-mode");
  if (mobileLandscape) cls.push("__landscape-mode");
  if (fullLeftSide) cls.push("__full-left-side");
  if (mobileRightPanelModalMode) cls.push("__modal-chat");
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")} ref={streamView} onClick={onShowControls}>
      <div className="stream-view__left-side" ref={leftSide}>
        <div
          className={
            "stream-view__header" + (usersListMode && !desktopUp && !mobileRightPanelModalMode ? " __users-mode" : "")
          }
        >
          <TransitionControl
            className="stream-view__header-inner"
            showControl={showControls || (usersListMode && !mobileRightPanelModalMode)}
          >
            {slotTimer ? slotTimer : null}
            <div className="stream-view__header-buttons">
              {!disableUserList && (
                <>
                  <div className="stream-view__header-bubble mr-2">
                    <span>{usersCount}</span>
                  </div>
                  <BaseButton
                    className={
                      "stream-view__header-btn" + (produce ? "" : " __disabled") + (usersListMode ? " __active" : "")
                    }
                    name={"users"}
                    disabled={!produce}
                    onClick={() => changeRightPanel(RightPanelView.USERS)}
                  >
                    <BaseIcon className="stream-view__header-icon">consultants</BaseIcon>
                  </BaseButton>
                </>
              )}
              {slotHeaderButtons && slotHeaderButtons}
              {produce && desktopUp && !isMobile() && (
                <BaseButton
                  className={"stream-view__header-btn" + (settingsMode ? " __active" : "")}
                  name={"settings"}
                  onClick={() => changeRightPanel(RightPanelView.SETTINGS)}
                >
                  <BaseIcon className="stream-view__header-icon">settings</BaseIcon>
                </BaseButton>
              )}
            </div>
          </TransitionControl>
        </div>

        <div
          className={
            "stream-view__video-wrapper" + ((!hasVideo && !hasShare) || disabledVideo ? " __video-disabled" : "")
          }
          ref={videoWrapper}
        >
          {streamStartedDate && !hasVideo && !hasShare && (
            <RippleAvatarAnimation
              className="stream-view__animation"
              size={60}
              user={isProducerUser && consume ? consumeUser : produceUser}
            />
          )}
          <TransitionControl
            className="stream-view__video-wrapper-header"
            showControl={(desktopUp || !showControls) && !usersListMode}
          >
            {produceUser && (
              <div className="stream-view__live-user">
                <BaseAvatar className="stream-view__live-user-avatar" size={30} user={produceUser} />
                <p className="stream-view__live-user-name label fw-semibold">{produceUser.screenName}</p>
              </div>
            )}
            {consume && !hasAudio && streamStartedDate && (
              <div className="stream-view__muted-notify">
                <BaseIcon className="stream-view__muted-ico" size={20}>
                  mic_off
                </BaseIcon>
                <span>{t("Muted")}</span>
              </div>
            )}
          </TransitionControl>
          {slotVideoScreen && slotVideoScreen}
          {!hideVideoElement && (
            <video
              className={
                "stream-view__video" +
                (isProducerUser && !consume && facingMode !== "environment" && !hasShare ? " __mirror-reflection" : "")
              }
              ref={player}
              muted={isProducerUser && produce}
              playsInline
            />
          )}
          {(showStreamPlayButton || forceShowStreamPlayButton) && <PlayStreamButton />}
          <PanelControlButtons
            className="stream-view__control-buttons"
            produce={produce}
            consume={isProducerUser && !!managedConstraints?.shareEnabled ? false : consume}
            loading={loading}
            showControlsButtons={!forceHideControlButtons && showControlsButtons}
            constraints={managedConstraints}
            onClickEndCall={onClickEndCall}
            onSwitchCam={onSwitchCam}
            onToggleMic={onToggleMic}
            onToggleCam={onToggleCam}
            onToggleBlur={onToggleBlur}
            onToggleChat={onOpenChat}
            showChatButton={mobileRightPanelModalMode}
            slotAdditionalControlButtons={slotAdditionalControlButtons}
            player={player.current}
            slotControlButtons={slotControlButtons}
          />
          <div className="stream-view__shadow" />
        </div>

        {displayControls.confirmationExit && (
          <ModalWindow
            show
            onClose={() => changeControls("confirmationExit", false)}
            className="stream-view__confirm-exit"
          >
            <>
              {slotContentConfirmationExit ? (
                slotContentConfirmationExit
              ) : (
                <h1 className="headline-1 text-center">{t("Do you want to end the chat?")}</h1>
              )}
              <div className="stream-view__confirm-exit-buttons">
                <BaseButton
                  large
                  outline
                  onClick={() => changeControls("confirmationExit", false)}
                  className="stream-view__confirm-exit-btn"
                >
                  {t("Cancel")}
                </BaseButton>
                <BaseButton
                  name="confirm-exit"
                  large
                  negative
                  onClick={onCloseEvent}
                  className="stream-view__confirm-exit-btn"
                >
                  <BaseIcon className="stream-view__control-icon mr-1">call</BaseIcon>
                  {t("End Now")}
                </BaseButton>
              </div>
            </>
          </ModalWindow>
        )}
      </div>
      <div
        className={
          "stream-view__right-side" +
          (mobileChatMode ? " __chat-mode" : "") +
          (usersListMode ? " __users-mode" : "") +
          (mobileLandscape ? " __landscape-mode" : "") +
          (mobileRightPanelModalMode ? " __modal-mode" : "")
        }
        ref={rightSide}
      >
        <RightPanel
          loading={loading}
          disableAttachments={disableAttachments}
          token={token}
          threadId={threadId}
          devices={audioInputs && videoInputs && { audioInputs, videoInputs }}
          onChangeConstraints={onChangeConstraints}
          constraints={managedConstraints}
          userList={userList}
          userListIcon={userListIcon}
          modalChatView={mobileRightPanelModalMode}
          enableUserSearch={enableUserSearch}
          allowGuestMessage={allowGuestMessage}
          me={me}
          onItemIconClick={onItemIconClick}
          showSection={displayControls.rightPanel}
          includeGuestsList={includeGuestsList}
          changeControls={changeRightPanel}
        />
      </div>
    </div>
  );
}

export default StreamView;
