import emitter, { EVENTS } from "lib/emitter";
import providers from "lib/providers";

export default function useController() {
  const onRefillBalance = () => {
    emitter.emit(EVENTS.BALANCE_REFILL_SHOW);
  };

  const payForAppointment = async (data: { id: string }) => {
    return await providers.AppointmentProvider.payForAppointment(data);
  };

  return {
    onRefillBalance,
    payForAppointment,
  };
}
