import React, { useMemo, useState } from "react";
import { useTranslation } from "locales";
import useController from "./controller";
import BaseFilterContainer, { Item } from "../BaseFilterContainer";

interface Props {
  initialValue?: number[];
  onFilterTags: (items: number[] | undefined) => void;
}

export default function FilterByTags({ initialValue = [], onFilterTags }: Props) {
  const { t } = useTranslation();
  const [values, setValues] = useState<string[]>(initialValue.map((v) => v.toString()));
  const { tags } = useController();

  const items: Item[] = useMemo(() => tags.map((i) => ({ label: i.title, id: i.id })), [tags]);

  const onInput = (values: string[]) => {
    setValues(values);
    if (values.length === 0) onFilterTags(undefined);
    else onFilterTags(values.map((id) => parseInt(id)));
  };

  return (
    <BaseFilterContainer
      title={t("Practice area")}
      items={items}
      values={values}
      onInput={onInput}
      filterPlaceholder={t("Search practice area")}
      className="filter-by-tags"
    />
  );
}
