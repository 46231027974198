import { useCallback, useState } from "react";
import { PageableSearchResult, SearchResult, SortableSearch } from "@scrile/api-provider/dist/api/types";

interface PageableState<T> extends PageableSearchResult, SearchResult<T>, SortableSearch {}

export default function usePager<T, D>(loader: (page: number, requestData?: D) => Promise<PageableState<T>>) {
  const [data, setData] = useState<PageableState<T>>();

  const getData = useCallback(
    async (page = 0, requestData?: D) => {
      const response = await loader(page, requestData);
      setData((prevData) => {
        return {
          ...response,
          result: !prevData ? response.result : prevData.result.concat(response.result),
        };
      });
    },
    [loader]
  );

  const getNextPage = useCallback(
    async (cb?: () => void, requestData?: D) => {
      if (!data?.hasNextPage) {
        return;
      }
      const nextPage = (data?.page ?? 0) + 1;
      await getData(nextPage, requestData);
      cb && cb();
    },
    [data, getData]
  );

  const getFirstPage = useCallback(
    (requestData?: D) => {
      return getData(0, requestData);
    },
    [getData]
  );

  return {
    enableScroll: data?.hasNextPage ?? false,
    data,
    setData,
    items: data?.result ?? [],
    getFirstPage,
    getNextPage,
  };
}
