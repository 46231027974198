import React from "react";
import { NavLink } from "react-router-dom";
import { MessageThread } from "@scrile/api-provider/dist/api/MessageThreadsProvider";
import { User } from "@scrile/api-provider/dist/api/UserProvider";
import BaseAvatar from "components/BaseAvatar";
import BaseCounter from "components/BaseCounter";
import useController from "./useController";
import "./style.scss";

interface Props {
  messageThread: MessageThread;
  authUser: User;
}

function MessageThreadItem({ messageThread, authUser }: Props) {
  const { senderUser } = useController(messageThread, authUser);
  return (
    <NavLink to={`/messages/${senderUser?.id || 0}`} className="message-thread-item">
      <BaseAvatar user={senderUser} className="mr-2" size={40} />
      <h3 className="message-thread-item__name body fw-semibold">{senderUser?.screenName}</h3>
      <BaseCounter className="message-thread-item__counter ml-1">{messageThread.unreadMessageCount}</BaseCounter>
    </NavLink>
  );
}

export default MessageThreadItem;
