import { InputPasswordResetChange } from "@scrile/api-provider/dist/api/PasswordResetProvider";
import providers from "lib/providers";

export function useController() {
  function onSetPassword(data: InputPasswordResetChange) {
    providers.PasswordResetProvider.setReceiveErrors(false);
    return providers.PasswordResetProvider.change({ data });
  }

  return { onSetPassword };
}
