import React, { useEffect, useRef } from "react";
import { lockBodyScroll } from "@scrile/tools/dist/lib/Scrollhelpers";
import ReactDOM from "react-dom";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import "./style.scss";

interface Props {
  show: boolean;
  modal?: boolean;
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
  mobileFullScreen?: boolean;
}

function ModalWindow({ show, modal, children, onClose, className, mobileFullScreen }: Props) {
  const refElement = useRef(document.getElementById("portal") || document.createElement("div"));
  const parentElement = refElement.current.parentElement;
  if (parentElement === null) {
    refElement.current.id = "portal";
    document.body.appendChild(refElement.current);
  }

  const cls = ["modal-window"];
  if (className) cls.push(className);
  if (mobileFullScreen) cls.push("__mobile-full-screen");

  let content;
  if (show) {
    content = (
      <div className={cls.join(" ")} onClick={(e) => e.stopPropagation()}>
        <div
          className="modal-window__backdrop"
          onClick={() => {
            if (modal) return;
            onClose();
          }}
        />
        <div className="modal-window__content">
          {!modal && (
            <BaseButton className="modal-window__close" opacity={true} onClick={onClose} icon={true}>
              <BaseIcon>close</BaseIcon>
            </BaseButton>
          )}
          {children}
        </div>
      </div>
    );
  } else {
    content = null;
  }

  useEffect(() => {
    let locked = false;
    if (!show) return;
    locked = true;
    lockBodyScroll();
    return () => {
      locked && lockBodyScroll(false);
    };
  }, [parentElement, show]);
  return ReactDOM.createPortal(content, refElement.current);
}

export default ModalWindow;
