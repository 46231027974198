import providers from "lib/providers";

export default function useController() {
  const assignToken = async (token: string, userIds: string[]) => {
    const isAssignToken = await providers.CalendlyProvider.assignToken(token);
    if (isAssignToken) {
      const response = await providers.CalendlyProvider.assignmentSearch({ userIds });
      if (response.length > 0) return true;
    }
    return false;
  };

  return { assignToken };
}
