import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "locales";
import useBaseForm from "hooks/useBaseForm";
import { parseErrors } from "lib/requestHelpers";
import { validateFieldsWithException } from "lib/validation";
import { BaseSelectField } from "types";
import { PayoutSystemType } from "./config";
import useController from "./controller";

export default function useViewController(onNext: () => Promise<void>) {
  const { loading, payoutSystems, currentPayeeInfo, configuredPayoutSystem, sendData } = useController();
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const { fields, values, errors, setValues, setErrors } = useBaseForm([
    {
      name: "payoutSystem",
      type: "select" as const,
      label: t("Payout system"),
      className: "performer-verification__short-input mb-4",
      options: payoutSystems,
    },
    {
      name: "currency",
      type: "select" as const,
      label: t("Currency"),
      className: "performer-verification__short-input mb-4",
      options: [],
    },
    {
      name: "tcAgree",
      type: "checkbox" as const,
      label: t("You agree to our Terms of Service"),
      style: { justifyContent: "flex-start" },
      validation: {
        required: true,
        customMessage: t("You must agree to our Terms of Service"),
      },
    },
  ]);

  const onSendData = async () => {
    setProcessing(true);
    try {
      validateFieldsWithException(fields, values);
      await sendData(values);
      await onNext();
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      setProcessing(false);
    }
  };

  // set current info from backend
  useEffect(() => {
    if (!currentPayeeInfo) return;

    setValues({
      currency: currentPayeeInfo.currency,
      payoutSystem: currentPayeeInfo.payoutSystem,
      otherDetails: currentPayeeInfo?.other?.details ?? "",
      paypalAccount: currentPayeeInfo?.paypal?.account ?? "",
    });
  }, [currentPayeeInfo, setValues]);

  // get currency list for current selected payout system
  const currencyList = useMemo(() => {
    const selectedPayoutSystem = configuredPayoutSystem.find((i) => i.systemName === values.payoutSystem);
    return selectedPayoutSystem ? selectedPayoutSystem.currencies.map((i) => ({ value: i, text: i })) : [];
  }, [values, configuredPayoutSystem]);

  // apply currency list for fields
  const field = fields.find((i) => i.name === "currency") as BaseSelectField;
  field.options = currencyList;
  field.disabled = currencyList.length < 2;

  // reset currency if it not exist on new payment system
  useEffect(() => {
    const selectedCurrency = values.currency;
    if (selectedCurrency && !currencyList.find((i) => i.value === selectedCurrency)) {
      setValues((v) => ({
        ...v,
        currency: currencyList[0]?.value ?? "0",
      }));
    }
  }, [currencyList, setValues, values.currency]);

  // set default value for currency and system
  useEffect(() => {
    if (payoutSystems.length === 0 || (values.payoutSystem && values.currency)) return;
    setValues((v) => ({
      ...v,
      payoutSystem: payoutSystems[0]?.value ?? v.payoutSystem,
      currency: currencyList[0]?.value ?? v.currency,
    }));
  }, [payoutSystems, currencyList, values.payoutSystem, values.currency, setValues]);

  // apply payout system related fields based on selected payout system.
  values.payoutSystem &&
    fields.splice(
      fields.length - 1,
      0,
      values.payoutSystem === PayoutSystemType.PAYPAL
        ? {
            name: `${PayoutSystemType.PAYPAL}Account`,
            type: "text",
            label: t("PayPal email address"),
            validation: {
              required: true,
            },
          }
        : {
            name: `${PayoutSystemType.OTHER}Details`,
            type: "text",
            label: t("Details"),
            validation: {
              required: true,
            },
          }
    );

  return {
    loading,
    processing,
    fields,
    values,
    errors,
    setValues,
    setErrors,
    onSendData,
  };
}
