import React from "react";
import { NavLink } from "react-router-dom";
import { I18n, t } from "locales";
import BaseButton from "components/BaseButton";
import BaseForm from "components/BaseForm";
import BaseIcon from "components/BaseIcon";
import PageLoading from "components/ElementLoading";
import useViewController from "./viewController";
import "./style.scss";

const AppointmentsAvailability = () => {
  const {
    userVerified,
    token,
    onChange,
    onSubmit,
    processing,
    availability,
    loading,
    fields,
    values,
    errors,
    setErrors,
  } = useViewController();

  return (
    <div className="appointments-availability ml-auto mr-auto">
      {loading ? (
        <PageLoading loading={loading} />
      ) : userVerified ? (
        availability ? (
          <p className="appointments-availability__text body">
            {t(
              `Thank you! Everything is set up. Customers can now book a meeting with you using the 
              «Book now» button on your profile page.`
            )}
          </p>
        ) : (
          <div className={`appointments-availability__body ${token && " __with-token"}`}>
            <h2 className="headline-2 mb-6">{t("Set up your availability")}</h2>
            <span className="d-block body-headline mb-2">{t("Sign up in Calendly")}</span>
            <p className="appointments-availability__text body mb-5">
              {t(
                `With Calendly, customers can book appointments with you on their own with a couple of clicks of the mouse. 
                  Please register with Calendly by following the instructions on the website. It's really simple and fast.`
              )}
            </p>
            <NavLink to={{ pathname: "https://calendly.com/" }} target="_blank" className="d-block">
              <BaseButton large className="appointments-availability__btn mb-8">
                {t("Go to Calendly")}
              </BaseButton>
            </NavLink>
            <span className="d-block body-headline mb-2">{t("Get your Calendly Token")}</span>
            <p className="appointments-availability__text body mb-5">
              <I18n
                path={`Please go to this link <a>{{ url }}</a> and generate your Personal Access Token. 
                  Then copy and paste it in the box below.`}
                components={{
                  a: (
                    <a
                      className="appointments-availability__link"
                      href="https://calendly.com/integrations/api_webhooks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://calendly.com/integrations/api_webhooks
                    </a>
                  ),
                }}
                values={{
                  url: "https://calendly.com/integrations/api_webhooks",
                }}
              />
            </p>
            <BaseForm
              className="calendly-form"
              fields={fields}
              values={values}
              onChange={onChange}
              errors={errors}
              setErrors={setErrors}
            >
              {token && (
                <BaseButton
                  large
                  className="appointments-availability__btn  ml-auto"
                  onClick={onSubmit}
                  loading={processing}
                >
                  {t("Submit")}
                </BaseButton>
              )}
            </BaseForm>
          </div>
        )
      ) : (
        <div className="appointments-availability__not-verified d-center">
          <BaseIcon className="appointments-availability__time mb-8">time</BaseIcon>
          <p className="appointments-availability__text appointments-availability__text-headline text-center">
            {t("You may set up dates available for booking after the account is verified")}
          </p>
        </div>
      )}
    </div>
  );
};

export default AppointmentsAvailability;
