import { useRef } from "react";
import { useUnmount } from "react-use";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
import * as bodySegmentation from "@tensorflow-models/body-segmentation";
import { BodySegmenter } from "@tensorflow-models/body-segmentation";
import createDeferred from "lib/deferredPromise";
import { removeTracksFromStream } from "lib/mediaStreamHelpers";

export default function useBlurController() {
  const foregroundThreshold = 0.7;
  const backgroundBlurAmount = 15;
  const edgeBlurAmount = 3;
  const flipHorizontal = false;
  const controller = useRef({
    segmenter: null as BodySegmenter | null,
    canvas: document.createElement("canvas"),
    video: document.createElement("video"),
    drawing: null as Promise<void> | null,
    localStream: null as MediaStream | null,
  });

  const initController = async () => {
    const model = bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation;
    const segmenter = await bodySegmentation.createSegmenter(model, {
      runtime: "mediapipe", // or 'tfjs'
      solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation",
      modelType: "general",
    });

    // @ts-ignore
    controller.current.video.playsInline = true;
    controller.current.segmenter = segmenter;
  };

  const videoPlay = async () => {
    const deferred = createDeferred();
    const onCanPlay = () => {
      deferred.resolve();
      controller.current.video.removeEventListener("canplay", onCanPlay);
    };
    controller.current.video.addEventListener("canplay", onCanPlay);
    const timer = setTimeout(async () => {
      await clear();
      deferred.reject();
    }, 1000);
    await deferred.promise;
    clearTimeout(timer);
    await controller.current.video.play();
  };

  const drawFrame = async () => {
    if (!controller.current.segmenter || !controller.current.video.srcObject) return;
    const segmentation = await controller.current.segmenter.segmentPeople(controller.current.video, { flipHorizontal });
    return bodySegmentation.drawBokehEffect(
      controller.current.canvas,
      controller.current.video,
      segmentation,
      foregroundThreshold,
      backgroundBlurAmount,
      edgeBlurAmount,
      flipHorizontal
    );
  };

  const drawFrames = async () => {
    if (!controller.current.drawing) {
      return;
    }

    while (controller.current.drawing) {
      const now = Date.now();
      controller.current.drawing = drawFrame();
      await controller.current.drawing;
      const frameRenderTime = Date.now() - now;
      await new Promise<void>((r) => setTimeout(() => r(), 40 - frameRenderTime));
    }
  };

  const clear = async () => {
    await controller.current.drawing;

    controller.current.video.pause();
    controller.current.drawing = null;
    controller.current.video.srcObject = null;
    if (controller.current.localStream) {
      removeTracksFromStream(controller.current.localStream, controller.current.localStream.getTracks());
    }
    controller.current.localStream = null;
  };

  const blurStart = async (videoTrack: MediaStreamTrack): Promise<MediaStream> => {
    if (!controller.current.segmenter) await initController();

    controller.current.localStream = new MediaStream([videoTrack.clone()]);
    controller.current.video.srcObject = controller.current.localStream;

    const { width = 640, height = 480 } = videoTrack.getSettings();

    controller.current.canvas.width = width;
    controller.current.canvas.height = height;

    await videoPlay().then(() => {
      controller.current.drawing = Promise.resolve();
      drawFrames();
    });
    videoTrack.stop();
    // @ts-ignore
    return controller.current.canvas.captureStream(30);
  };

  const blurStop = async (): Promise<MediaStream> => {
    const newStream = new MediaStream(controller.current.localStream?.getTracks().map((t) => t.clone()) ?? []);
    await clear();

    return newStream;
  };

  useUnmount(() => {
    clear().then();
  });

  return {
    blurStart,
    blurStop,
  };
}
