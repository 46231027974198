import React from "react";
import { useHistory } from "react-router-dom";
import { t } from "locales";
import useAuthUser from "hooks/useAuthUser";
import useBaseForm from "hooks/useBaseForm";
import useLiveChatName from "hooks/useLiveChatName";
import { validateFields } from "lib/validation";
import BaseForm from "components/BaseForm";
import JoinLiveChatModal from "components/JoinLiveChatModal";

function GoConferenceModal({ onClose, conferenceId }: { onClose: () => void; conferenceId: string }) {
  const { user } = useAuthUser();
  const { liveChatName, updateLiveChatName } = useLiveChatName(user?.screenName ?? "");
  const history = useHistory();

  const { fields, values, errors, setValues, setErrors } = useBaseForm(
    [
      {
        name: "chat-name",
        type: "text",
        label: t("Your name"),
        validation: {
          required: true,
        },
      },
    ],
    {
      "chat-name": liveChatName,
    }
  );

  const onSubmit = () => {
    const validation = validateFields(fields, values);
    if (!validation.valid) {
      setErrors(validation.errors);
      return;
    }
    updateLiveChatName(values["chat-name"]);
    history.push(`/conference/${conferenceId}`);
  };

  if (!conferenceId) onClose();

  return (
    <JoinLiveChatModal
      className={"go-conference-chat-modal"}
      onClose={onClose}
      onSubmit={onSubmit}
      screenName={values["chat-name"]}
    >
      <BaseForm
        className="divider-bottom__solid pb-4 mb-4"
        fields={fields}
        values={values}
        onChange={setValues}
        setErrors={setErrors}
        errors={errors}
      />
    </JoinLiveChatModal>
  );
}

export default GoConferenceModal;
