import { useState } from "react";
import { Review } from "@scrile/api-provider/dist/api/ReviewProvider";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { t } from "locales";
import useBaseForm from "hooks/useBaseForm";
import useBreakPoints from "hooks/useBreakPoints";
import { parseErrors } from "lib/requestHelpers";
import { validateFieldsWithException } from "lib/validation";
import { FormValues } from "types";
import useController from "./controller";

export default function useViewController(
  recipientUser: UserPublicData,
  onClose: () => void,
  onReviewAdded?: (review: Review) => void
) {
  const [rating, setRating] = useState(0);
  const { desktopUp } = useBreakPoints();
  const [showSuccessStatus, setShowSuccessStatus] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [ratingErrorMessage, setRatingErrorMessage] = useState("");
  const { fields, values, errors, setValues, setErrors } = useBaseForm([
    {
      name: "comment",
      type: "textarea",
      labelClassName: "d-block mb-2",
      className: "leave-review-modal__textarea",
      label: `*${t("Write your review")}`,
      autoGrow: true,
      maxRows: desktopUp ? 6 : 8,
      maxLength: 1500,
      showLengthCounter: true,
      validation: {
        required: true,
      },
    },
  ]);
  const { publishReview } = useController(recipientUser);
  const onChange = (values: FormValues) => setValues(values);
  const onSetRating = (value: number) => {
    setRatingErrorMessage("");
    setRating(value);
  };

  const onPublishReview = async () => {
    try {
      if (rating === 0) setRatingErrorMessage(t("Please select a rating"));
      validateFieldsWithException(fields, values);
      if (rating === 0 || ratingErrorMessage) return;

      setProcessing(true);
      const review = await publishReview(values["comment"], rating);
      onReviewAdded && onReviewAdded(review);
      setShowSuccessStatus(true);
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      setProcessing(false);
    }
  };

  const onCloseModal = () => {
    setShowSuccessStatus(false);
    onChange({});
    setRatingErrorMessage("");
    setErrors({});
    setRating(0);
    onClose();
  };

  return {
    fields,
    values,
    errors,
    onChange,
    setErrors,
    rating,
    onSetRating,
    ratingErrorMessage,
    onPublishReview,
    processing,
    showSuccessStatus,
    onCloseModal,
  };
}
