import React, { useState } from "react";
import InputSlider from "react-input-slider";
import BaseIcon from "components/BaseIcon";
import VideoControlButton from "components/VideoControlButton";
import useController from "./controller";
import "./style.scss";

interface Props {
  player: HTMLVideoElement;
  className: string;
}

const VolumeControl = ({ player, className }: Props) => {
  const [dragged, setDragged] = useState(false);
  const { styles, volumeSettings, onVolumeClick, onVolumeChange } = useController(player);
  const { muted, level } = volumeSettings;
  const cls = ["volume-control"];
  cls.push(className);
  if (dragged) cls.push("__dragged");

  return (
    <div className={cls.join(" ")}>
      <VideoControlButton
        className="volume-control__button"
        name="volume"
        toggle={muted}
        borderless={true}
        onClick={onVolumeClick}
      >
        <BaseIcon>{!muted ? "sound_on" : "sound_off"}</BaseIcon>
      </VideoControlButton>
      <div className="volume-control__slider">
        <InputSlider
          axis="y"
          y={level}
          yreverse={true}
          ymin={0}
          ymax={1}
          ystep={0.01}
          onChange={onVolumeChange}
          styles={styles}
          onDragStart={() => setDragged(true)}
          onDragEnd={() => setDragged(false)}
        />
      </div>
    </div>
  );
};

export default VolumeControl;
