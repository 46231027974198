import React, { useMemo } from "react";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";
import { ConferenceUser } from "types";
import CustomScrollBar from "components/CustomScrollBar";
import ConferenceUserItem from "pages/PageConferenceChat/components/ConferenceUserItem";
import "./style.scss";

interface Props {
  users: ConferenceUser[];
  me: ConferenceUser | null;
  selectedUserId: string | null;
  onSelectUser: (userId: string | null) => void;
}

export default function ConferenceStreamView({ users, me, selectedUserId, onSelectUser }: Props) {
  const cls = ["conference-stream-view d-center"];
  if (selectedUserId) cls.push("__focused");
  if (users.length < 2) cls.push("__big");
  else if (users.length < 9) cls.push("__medium");
  else cls.push("__small");

  const selectedUser = useMemo(() => {
    let user = users.find((user) => user.user.id === selectedUserId);
    if (!user) me && me.user.id === selectedUserId && (user = me);
    return user;
  }, [me, selectedUserId, users]);

  return (
    <div className={cls.join(" ")}>
      {selectedUser && (
        <ConferenceUserItem
          className="conference-stream-view__selected-user"
          user={selectedUser}
          key={selectedUser.user.id}
          forceMute
          onClick={() => !isMobile() && onSelectUser(null)}
        />
      )}
      <CustomScrollBar className="conference-stream-view__users-wrapper">
        <div className="conference-stream-view__users">
          {me && (
            <ConferenceUserItem
              className="conference-stream-view__user"
              user={me}
              key={me.user.id}
              forceMute
              hide={me.user.id === selectedUserId}
              onClick={() => onSelectUser(me.user.id)}
            />
          )}
          {users.map((user) => (
            <ConferenceUserItem
              className="conference-stream-view__user"
              user={user}
              key={user.user.id}
              hide={user.user.id === selectedUserId}
              onClick={() => onSelectUser(user.user.id)}
            />
          ))}
        </div>
      </CustomScrollBar>
    </div>
  );
}
