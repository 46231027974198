import React from "react";
import { useTranslation } from "locales";
import useBreakPoints from "hooks/useBreakPoints";
import BaseCard from "components/BaseCard";
import BaseForm from "components/BaseForm";
import ContentHeader from "components/ContentHeader";
import useViewController from "./viewController";
import "./style.scss";

export interface Props {
  initialValue?: boolean;
  onInput: (value: undefined | boolean) => void;
}

function StatusFilter({ initialValue, onInput }: Props) {
  const { t } = useTranslation();
  const { desktopUp } = useBreakPoints();
  const { onChange, values, fields } = useViewController({ initialValue, onInput });

  const form = (
    <BaseForm
      headline={<ContentHeader className="status-filter__header">{t("Status")}</ContentHeader>}
      fields={fields}
      values={values}
      onChange={onChange}
    />
  );

  if (desktopUp) {
    return <BaseCard>{form}</BaseCard>;
  } else {
    return form;
  }
}

export default StatusFilter;
