import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { useTranslation } from "locales";
import useCustomDomain from "hooks/useCustomDomain";
import BasePage from "components/BasePage";
import ContentHeader from "components/ContentHeader";
import AppointmentsPage from "./components/AppointmentsPage";
import MainInfoProfile from "./components/MainInfoProfile";
import NavigationMenu from "./components/NavigationMenu";
import NavigationMenuMobile from "./components/NavigationMenuMobile";
import PerformerVerification from "./components/PerformerVerification";
import PromotePerformerVerification from "./components/PromotePerformerVerification";
import useViewController from "./viewController";
import PageBalanceHistory from "../PageBalanceHistory";
import PageSettingsChangePassword from "../PageSettingsChangePassword";
import "./style.scss";

export default function PageUserArea() {
  const match = useRouteMatch();
  const { user, itemsList } = useViewController();
  const { t } = useTranslation();
  const { isCustomDomain } = useCustomDomain();

  if (!user) {
    return <Redirect to="/home" />;
  }
  const side = (
    <>
      <NavigationMenuMobile itemsList={itemsList} exact={false} />
      <NavigationMenu itemsList={itemsList} />
      {!isCustomDomain && <PromotePerformerVerification />}
    </>
  );
  return (
    <BasePage
      className="page-user-area"
      side={side}
      prepend={<ContentHeader className="page-user-area__header">{t("Profile")}</ContentHeader>}
      append={<PerformerVerification />}
    >
      <Switch>
        <Route exact={true} path={`${match.url}`}>
          <Redirect to={itemsList[0].to} />
        </Route>
        <Route path={`${match.url}/settings`}>
          <MainInfoProfile />
        </Route>
        <Route path={`${match.url}/appointments`}>
          <AppointmentsPage />
        </Route>
        <Route path={`${match.url}/transactions`}>
          <PageBalanceHistory />
        </Route>
        <Route path={`${match.url}/change-password`}>
          <PageSettingsChangePassword />
        </Route>
        <Route path={`*`}>
          <Redirect to={itemsList[0].to} />
        </Route>
      </Switch>
    </BasePage>
  );
}
