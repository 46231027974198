import { InputJoinData } from "@scrile/api-provider/dist/api/LoginProvider";
import { gtagTrackEvent } from "lib/analytics";
import providers from "lib/providers";
import { parseErrors } from "lib/requestHelpers";
import { SetErrors } from "types";

export function useController(setErrors: SetErrors) {
  async function onJoin(data: InputJoinData, cb?: () => void) {
    try {
      await providers.LoginProvider.join({ data: data });
      if (cb) cb();

      gtagTrackEvent("Meet Sign Up", {
        event_category: "meetSignUpBtn",
        event_action: "meetSignUpClick",
      });
    } catch (e) {
      setErrors(parseErrors(e));
    }
  }

  return {
    onJoin,
  };
}
