import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FragmentFields } from "@scrile/api-provider/dist/api/types";
import { InputPerformerListSearch } from "@scrile/api-provider/dist/projects/webvideo/PerformerListProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { HISTORY_ACTIONS } from "const";
import { openPopupWidget } from "react-calendly";
import { Prefill } from "react-calendly/typings/calendly";
import useAuthUser from "hooks/useAuthUser";
import usePerformers from "hooks/usePerformers";
import emitter, { EVENTS } from "lib/emitter";
import providers from "lib/providers";

interface Props {
  onlyFollowings?: boolean;
}

const USER_FIELDS: FragmentFields<UserPublicData> = [
  "id",
  "username",
  "screenName",
  "disabled",
  "calendlyScheduleUrl",
  { onlineStatus: ["isOnline"] },
  { publicLivechatUser: ["broadcasting"] },
  { avatar: ["urlPart"] },
  { questionary: ["fields", { tags: ["id", "slug", "title"] }] },
];

function useController({ onlyFollowings }: Props) {
  const {
    filtersDTO: savedFiltersDTO,
    performers,
    userFavoriteIds,
    prevOnlyFollowings,
    setFiltersDTO: setSavedFiltersDTO,
    setPerformers,
    setUserFavoriteIds,
    setPrevOnlyFollowings,
  } = usePerformers();
  const [loading, setLoading] = useState<boolean>(false);
  const { user: isAuth } = useAuthUser();
  const history = useHistory();
  const defaultFilters = { sort: ["ID,DESC"], page: 0 };
  const keepWithoutReload =
    history.action === HISTORY_ACTIONS.POP && !!performers?.result.length && prevOnlyFollowings === onlyFollowings;
  const [filtersDTO, setFiltersDTO] = useState<InputPerformerListSearch>(
    keepWithoutReload ? savedFiltersDTO : defaultFilters
  );

  const setFilters = async (data: InputPerformerListSearch, reload = true, cb?: () => void) => {
    return await getData(data, reload, cb);
  };

  const loadMore = async (cb?: () => void) => {
    if (performers && !performers.hasNextPage) return;
    return getData({ ...filtersDTO, page: (performers?.page ?? -1) + 1 }, false, cb);
  };
  const findOnlyOneUserByUserName = async (username: string) => {
    const response = await providers.PerformerListProvider.performerListSearch(
      { data: { ...filtersDTO, name: username } },
      USER_FIELDS
    );
    return response.result.find((user) => user.username === username);
  };
  const getData = async (data: InputPerformerListSearch, reload = true, cb?: () => void) => {
    try {
      const response = await providers.PerformerListProvider.performerListSearch(
        { data: { ...data, onlyFollowings } },
        USER_FIELDS
      );

      setPerformers(
        reload ? response : { ...response, result: performers?.result.concat(response.result) || response.result }
      );
      setFiltersDTO(data);
      setSavedFiltersDTO(data);

      if (isAuth) {
        if (onlyFollowings) {
          setUserFavoriteIds(response.result.map((i) => i.id));
          return;
        }

        const ids = response.result.map((i) => i.id);
        if (ids.length > 0) {
          const favoritesId = await providers.FollowingsProvider.searchFollowings({ ids });
          setUserFavoriteIds(favoritesId.map((i) => i.userId));
        }
      } else {
        setUserFavoriteIds([]);
      }
      return response.result;
    } finally {
      cb && cb();
    }
  };
  const calendlyShowQR = async (users: Array<UserPublicData> | undefined) => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    if (!isAuth) {
      if (urlSearchParams.has("bookRoom")) {
        sessionStorage.setItem("bookRoom", urlSearchParams.get("bookRoom") as string);
        history.push("/login");
      }
      return;
    }
    const userName = urlSearchParams.get("bookRoom") ?? sessionStorage.getItem("bookRoom");
    if (userName) {
      let user: UserPublicData | undefined = users?.find((user: UserPublicData) => user.username === userName);
      if (!user) {
        user = await findOnlyOneUserByUserName(userName as string);
      }
      sessionStorage.removeItem("bookRoom");
      const prefill: Prefill = {
        email: isAuth?.email || "",
        name: isAuth?.screenName || "",
      };
      openPopupWidget({ url: user?.calendlyScheduleUrl || "", prefill });
      emitter.emit(EVENTS.SET_BOOKING_USER, user);
    }
  };

  useEffect(() => {
    setPrevOnlyFollowings(onlyFollowings);
    if (keepWithoutReload) return;
    setLoading(true);
    getData(filtersDTO)
      .then((users) => calendlyShowQR(users))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, onlyFollowings]);

  return {
    userFavoriteIds,
    loading,
    filtersDTO,
    users: performers?.result || [],
    scroll: !loading && (performers?.hasNextPage ?? false),
    setFilters,
    loadMore,
    totalRecords: performers?.totalRecords ?? 0,
  };
}

export default useController;
