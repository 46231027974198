import { useEffect, useState } from "react";
import { LOCAL_STORAGE_NAMES } from "const";
import { MediaConstraints } from "types";

export default function useMediaConstraints(defaultConstraints: MediaConstraints) {
  const [mediaConstraints, setMediaConstraints] = useState<MediaConstraints>(() => {
    const storageConstraints = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAMES.MEDIA_CONSTRAINTS) ?? "{}");
    return {
      video: storageConstraints.video ?? defaultConstraints.video ?? false,
      audio: storageConstraints.audio ?? defaultConstraints.audio ?? false,
      videoEnabled: storageConstraints.videoEnabled ?? defaultConstraints.videoEnabled ?? false,
      audioEnabled: storageConstraints.audioEnabled ?? defaultConstraints.audioEnabled ?? false,
      shareEnabled: defaultConstraints.shareEnabled ?? false,
      blurEnabled: storageConstraints.blurEnabled ?? defaultConstraints.blurEnabled ?? false,
    };
  });

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_NAMES.MEDIA_CONSTRAINTS, JSON.stringify(mediaConstraints));
  }, [mediaConstraints]);

  return {
    mediaConstraints,
    setMediaConstraints,
  };
}
