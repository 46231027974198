import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import config from "config";
import { useTranslation } from "locales";
import useConference from "hooks/useConference";
import useModalLinkPrinter from "hooks/useModalLinkPrinter";
import ConferenceModal from "modals/ConferenceModal";
import GoLiveModal from "modals/GoLiveModal";
import { BaseMenuItemInterface } from "types";
import BaseAvatar from "components/BaseAvatar";
import BaseBalance from "components/BaseBalance";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import BaseMenu from "components/BaseMenu";
import Navigation from "./components/Navigation";
import useViewController from "./viewController";
import "./style.scss";

function PageHeader() {
  const {
    logoSrc,
    setDefaultLogo,
    itemsList,
    isMobileSM,
    user,
    showGoLiveButton,
    showGoLiveModal,
    openGoLiveModal,
    closeGoLiveModal,
    onClickMemberBalance,
    unreadThreadsCount,
  } = useViewController();

  const { getLocation } = useModalLinkPrinter();
  const { t } = useTranslation();
  const isCustomer = user?.role === config.userJoinRole;

  const { showConferenceModal, setShowConferenceModal, createConference, conferenceId } = useConference();

  const closeConferenceModal = () => {
    setShowConferenceModal(false);
  };

  const onClickItem = (itemName: string) => {
    if (itemName === "live") {
      openGoLiveModal();
      return;
    }
    if (itemName === "conference") {
      createConference().then(() => setShowConferenceModal(true));
      return;
    }
  };

  const streamingMenuItems: BaseMenuItemInterface[] = [
    {
      name: "live",
      icon: "circle-plus",
      title: t("Start now"),
    },
    {
      name: "conference",
      icon: "calendar",
      title: t("Schedule"),
    },
  ];
  const buttonContentGoLive = useMemo(
    () => (
      <>
        <BaseIcon className="header__go-live-icon mr-1">camera</BaseIcon>
        {t("Go live")}
      </>
    ),
    [t]
  );

  return (
    <header className="header">
      <div className="header__container __row container">
        <div className="header__inner">
          <Link to="/home" className="header__logo">
            <img className="header__logo-img" src={logoSrc} alt="logo" onError={setDefaultLogo} />
          </Link>
          {user && <Navigation unreadThreadsCount={unreadThreadsCount} className="header__desktop-nav" />}
          {showGoLiveButton && (
            <BaseMenu itemsList={streamingMenuItems} onClick={onClickItem}>
              <div className="header__stream-menu">
                <BaseButton className="header__go-live-button" live>
                  {buttonContentGoLive}
                </BaseButton>
              </div>
            </BaseMenu>
          )}
          {isCustomer && !isMobileSM && (
            <BaseBalance className="header__balance-button mr-n2" icon onClick={onClickMemberBalance} />
          )}
          {user ? (
            <div className="header__logged-user">
              <BaseMenu
                className="header__base-menu"
                itemsList={itemsList}
                title={user.screenName ?? ""}
                prepend={
                  isCustomer && isMobileSM ? (
                    <BaseBalance
                      className="header__base-menu-prepend-item __mobile"
                      icon
                      onClick={onClickMemberBalance}
                    />
                  ) : (
                    !isCustomer && (
                      <BaseBalance className="header__base-menu-prepend-item __mobile" to="/user/transactions" />
                    )
                  )
                }
                spaced
              >
                <div className="header__logged-user-menu">
                  <BaseAvatar user={user} className="header__logged-user-avatar" size={40} />
                  <BaseIcon size={12.5}>fas-angle-down</BaseIcon>
                </div>
              </BaseMenu>
            </div>
          ) : (
            <div className="header__buttons">
              <BaseButton className="header__buttons-button" borderless to={getLocation("/login")}>
                <BaseIcon>signin</BaseIcon>
                <span className="text">{t("Log In")}</span>
              </BaseButton>
              <BaseButton className="header__buttons-button __signup" to={getLocation("/signup")}>
                <BaseIcon>signup</BaseIcon>
                <span className="text">{t("Sign Up")}</span>
              </BaseButton>
            </div>
          )}
        </div>
        {user && <Navigation unreadThreadsCount={unreadThreadsCount} className="header__mobile-nav" />}
      </div>
      {showGoLiveButton && (
        <>
          <GoLiveModal show={showGoLiveModal} onClose={closeGoLiveModal} />
          <ConferenceModal conferenceId={conferenceId} show={showConferenceModal} onClose={closeConferenceModal} />
        </>
      )}
    </header>
  );
}

export default PageHeader;
