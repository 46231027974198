import React, { useEffect, useRef } from "react";
import emitter, { EVENTS } from "lib/emitter";
import { ConferenceUser } from "types";
import BaseAvatar from "components/BaseAvatar";
import BaseIcon from "components/BaseIcon";
import "./style.scss";

interface Props {
  user: ConferenceUser;
  className?: string;
  forceMute?: boolean;
  onClick: () => void;
  hide?: boolean;
}

export default function ConferenceUserItem({ user, className, forceMute, hide, onClick }: Props) {
  const player = useRef<HTMLVideoElement>(null);
  const hasAudio = !!user.constrains?.audioEnabled;
  const hasVideo = !!user.constrains?.videoEnabled;

  useEffect(() => {
    if (player.current) {
      player.current.pause();
      player.current.srcObject = user.stream;
      if (user.stream.getTracks().length && (user.constrains?.audioEnabled || user.constrains?.videoEnabled)) {
        player.current.play().catch((e) => {
          if (!player.current) throw e;
          setTimeout(
            () =>
              player.current?.play().catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e);
                emitter.emit(EVENTS.USER_DID_NOT_INTERACT_DOC_FIRST, () => player.current?.play());
              }),
            500
          );
        });
      }
    }
  }, [user.constrains, user.stream]);

  const cls = ["conference-user-item d-center"];
  if (!hasVideo) cls.push("__disabled-video");
  if (hide) cls.push("hidden");
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")} onClick={onClick}>
      <video className="conference-user-item__video" ref={player} muted={forceMute} playsInline autoPlay />
      {!hasVideo && <BaseAvatar size={50} user={user.user.user} />}
      {user.user.screenName && (
        <div className="conference-user-item__screen-name pa-1 truncate-ellipsis">{user.user.screenName}</div>
      )}
      {!hasAudio && (
        <div className="conference-user-item__muted">
          <BaseIcon className="conference-user-item__muted-icon" size={18}>
            mic_off
          </BaseIcon>
        </div>
      )}
    </div>
  );
}
