import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Appointment } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import { differenceInMilliseconds } from "@scrile/tools/dist/lib/TimeHelpers";
import { useHideAppointmentBanner } from "hooks/useHideAppointmentBanner";
import { useHideFooter } from "hooks/useHideFooter";
import useController from "./controller";

export default function useViewController(appointmentId: string) {
  const { getAppointmentById, authUser } = useController();
  const [key, setKey] = useState(1);
  useHideAppointmentBanner();
  useHideFooter();

  const [appointment, setAppointment] = useState<Appointment | null>(null);
  const history = useHistory();
  const onClose = useCallback(() => {
    history.replace("/");
  }, [history]);

  const forceRerender = () => {
    setKey((key) => key + 1);
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    (async function () {
      const response = await getAppointmentById(appointmentId);
      if (!response) {
        onClose();
        return;
      }
      setAppointment(response);
      const ms = differenceInMilliseconds(new Date(response.startTime), new Date());
      if (ms > 0) {
        timeout = setTimeout(() => {
          forceRerender();
        }, ms);
      }
    })();

    return () => {
      clearTimeout(timeout);
    };
  }, [appointmentId, getAppointmentById, onClose]);

  return {
    authUser,
    onClose,
    appointment,
    produce: authUser?.id === appointment?.providerId,
    key,
  };
}
