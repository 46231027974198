import React from "react";
import { AppointmentType } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  setSyncedAppointments: (appointmentTypes: AppointmentType[]) => void;
}

export default function SyncButton({ setSyncedAppointments }: Props) {
  const { t } = useTranslation();
  const { syncing, onSyncButtonClick } = useViewController();

  return (
    <div className="d-flex __align-center sync-button">
      <BaseButton className={`sync-button__btn`} onClick={() => onSyncButtonClick(setSyncedAppointments)} borderless>
        <BaseIcon className={"sync-button__icon" + (syncing ? " __syncing" : "")}>synch</BaseIcon>
      </BaseButton>
      <span className="sync-button__caption">
        {t("Сlick here if your Calendly Schedule is different from this one")}
      </span>
    </div>
  );
}
