import React from "react";
import { useCallback, useEffect, useRef } from "react";
import { Notification } from "@scrile/api-provider/dist/api/NotificationsProvider";
import integrations from "./integrations";
import { SystemNotificationFactory } from "./types";
import useAuthUser from "../../../../hooks/useAuthUser";
import providers from "../../../../lib/providers";
import { NotificationFactory } from "../../index";

export default function SystemNotifications({ sendNotification }: { sendNotification: NotificationFactory }) {
  const { user } = useAuthUser();
  const notificationHandlers = useRef(new Map<string, SystemNotificationFactory>());

  const addHandler = useCallback((key: string, handler: SystemNotificationFactory) => {
    notificationHandlers.current.set(key, handler);
  }, []);

  const removeHandler = useCallback((key: string) => {
    notificationHandlers.current.delete(key);
  }, []);

  const onNotification = useCallback(
    (notificationData: Notification) => {
      for (const [, integration] of notificationHandlers.current) {
        const notification = integration(notificationData);
        if (notification) {
          sendNotification(notification);
          break;
        }
      }
    },
    [sendNotification]
  );

  useEffect(() => {
    let unsubscribe: () => void;
    if (!user) {
      return;
    }
    providers.NotificationsProvider.subscribeNotification(onNotification).then(
      (s) => (unsubscribe = () => s.unsubscribe())
    );
    return () => unsubscribe && unsubscribe();
  }, [onNotification, user]);

  return (
    <>
      {integrations.map((Integration, index) => (
        <Integration addHandler={addHandler} removeHandler={removeHandler} key={index} />
      ))}
    </>
  );
}
