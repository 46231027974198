import React from "react";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseForm from "components/BaseForm";
import ElementLoading from "components/ElementLoading";
import { questionnaireFields } from "./questionnaireFields";
import useViewController from "./viewController";
import "./style.scss";

function QuestionnairePage() {
  const { values, setErrors, processing, loading, errors, onChange, onSendQuestionnaire } = useViewController();
  const { t } = useTranslation();
  return (
    <div className="questionnaire-page">
      <BaseForm
        className="questionnaire-page-form"
        fields={questionnaireFields}
        values={values}
        onChange={onChange}
        errors={errors}
        setErrors={setErrors}
      >
        <BaseButton
          name="submit"
          className="questionnaire-page-form__button"
          loading={processing}
          large={true}
          onClick={onSendQuestionnaire}
        >
          {t("Save Changes")}
        </BaseButton>
      </BaseForm>
      <ElementLoading loading={loading} />
    </div>
  );
}

export default QuestionnairePage;
