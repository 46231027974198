import { useState } from "react";
import { MediaConstraints } from "types";

const useController = (
  player: HTMLVideoElement | null,
  stream: MediaStream | null,
  constraints?: MediaConstraints | null,
  onChangeConstraints?: (constraints: MediaConstraints) => void
) => {
  const [facingMode, setFacingMode] = useState<"user" | "environment" | string>(
    constraints && typeof constraints.video === "object" && typeof constraints.video.facingMode === "string"
      ? constraints.video.facingMode
      : "user" ?? "user"
  );

  const onSwitchCam = async () => {
    if (!onChangeConstraints) return;
    const mode = facingMode === "user" ? "environment" : "user";
    stream?.getVideoTracks().forEach((t) => {
      t.stop();
      stream.removeTrack(t);
    });
    const constraints: MediaConstraints = {
      video: {
        facingMode: mode,
      },
    };
    await onChangeConstraints(constraints);
    setFacingMode(mode);
  };

  return {
    stopLocalTracks: () => {
      if (stream && stream.getTracks().length > 0) {
        stream.getTracks().forEach((t) => t.stop());
      }
    },
    onSwitchCam,
    facingMode,
  };
};

export default useController;
