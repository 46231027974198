import React from "react";
import { PaymentSystemPublic } from "@scrile/api-provider/dist/api/PaymentSystemsProvider";
import useViewController from "./viewController";
import BaseForm from "../../../../components/BaseForm";
import { submitPaymentCB } from "../../types";

interface Props {
  setOnSubmitPaymentCB: (cb: submitPaymentCB) => void;
  pricePackageId: string;
  currentSystem: PaymentSystemPublic | null;
}

export default function Stripe({ setOnSubmitPaymentCB, pricePackageId, currentSystem }: Props) {
  const { fields, values, errors, setValues, setErrors } = useViewController(
    setOnSubmitPaymentCB,
    pricePackageId,
    currentSystem
  );

  return <BaseForm fields={fields} values={values} onChange={setValues} setErrors={setErrors} errors={errors} />;
}
