import { useMemo, useState } from "react";
import { useMount } from "react-use";
import { PaymentPackage } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import { PaymentSystemPublic } from "@scrile/api-provider/dist/api/PaymentSystemsProvider";
import useAppState from "hooks/useAppState";
import providers from "lib/providers";

function useController(paymentPackage: PaymentPackage | null) {
  const { systemSettings } = useAppState();

  const [currentSystem, setCurrentSystem] = useState<PaymentSystemPublic | null>(null);
  const [loading, setLoading] = useState(false);

  const price = useMemo(() => paymentPackage?.costs.find((c) => c.currency === systemSettings?.mainCurrency), [
    paymentPackage,
    systemSettings,
  ]);

  useMount(() => {
    setLoading(true);
    providers.PaymentSystemsProvider.findForCreditCardAssignment()
      .then((r) => setCurrentSystem(r))
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    currentSystem,
    loading,
    price,
  };
}

export default useController;
