import React, { CSSProperties, useMemo } from "react";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseForm from "components/BaseForm";
import BaseIcon from "components/BaseIcon";
import ElementLoading from "components/ElementLoading";
import useViewController from "./viewController";

interface Props {
  onPrev: () => void;
  onNext: () => Promise<void>;
  className?: string;
  style?: CSSProperties;
}

export default function PayeeInformation({ onPrev, onNext, className, style }: Props) {
  const { t } = useTranslation();
  const { loading, processing, fields, values, errors, setValues, setErrors, onSendData } = useViewController(onNext);
  const buttonContentPrev = useMemo(
    () => (
      <>
        <span className="performer-verification__desktop-only">{t("Previous Step")}</span>
        <BaseIcon className="performer-verification__mobile-only" size={14}>
          back
        </BaseIcon>
      </>
    ),
    [t]
  );

  return (
    <div className={className} style={style}>
      <BaseForm fields={fields} values={values} onChange={setValues} errors={errors} setErrors={setErrors}>
        <div className="d-flex mt-8">
          <BaseButton className="" outline large onClick={onPrev}>
            {buttonContentPrev}
          </BaseButton>
          <BaseButton className={"ml-auto"} loading={processing} large onClick={onSendData}>
            <span className="performer-verification__desktop-only">{t("Start consulting")}</span>
            <span className="performer-verification__mobile-only">{t("Done")}</span>
          </BaseButton>
        </div>
      </BaseForm>
      <ElementLoading loading={loading} />
    </div>
  );
}
