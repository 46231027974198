import React from "react";
import { useTranslation } from "locales";
import useModalLinkPrinter from "hooks/useModalLinkPrinter";
import BaseButton from "components/BaseButton";
import BaseForm from "components/BaseForm";
import BaseIcon from "components/BaseIcon";
import ContentHeader from "components/ContentHeader";
import SocialLogin from "components/SocialLogin";
import { useViewController } from "./viewController";
import "./style.scss";

interface Props {
  onClose: () => void;
}

function ClientLogin({ onClose }: Props) {
  const { fields, values, errors, onLogin, onChange, setErrors } = useViewController();
  const { t } = useTranslation();
  const { getLocation, notification } = useModalLinkPrinter();

  return (
    <>
      <BaseForm
        className="client-login"
        autofocus={true}
        fields={fields}
        values={values}
        errors={errors}
        notification={notification}
        onChange={onChange}
        setErrors={setErrors}
        headline={<ContentHeader>{t("Log In")}</ContentHeader>}
        onSubmitEnter={() => onLogin(onClose)}
      >
        <>
          <div className="client-login__control-wrapper __buttons">
            <BaseButton
              name="resend"
              className="client-login__forgot-password fw-normal px-0"
              opacity={true}
              to={getLocation("/login/forgot")}
            >
              {t("Forgot password?")}
            </BaseButton>
            <BaseButton className="client-login__login" name="submit" large={true} onClick={() => onLogin(onClose)}>
              {t("Log In")}
            </BaseButton>
          </div>
          <SocialLogin className="client-login__social" onClose={onClose} title={t("or Log In with")} />
          <div className="client-login__control-wrapper __switch">
            <span className="caption">{t("Not a registered user?")}</span>
            <BaseButton
              className="client-login__switch-button fw-normal"
              opacity={true}
              to={getLocation("/signup", { notification })}
            >
              <BaseIcon className="mr-1">signup</BaseIcon>
              <span>{t("Sign Up")}</span>
            </BaseButton>
          </div>
        </>
      </BaseForm>
    </>
  );
}

export default ClientLogin;
