import { useEffect, useMemo, useState } from "react";
import { UserVerificationStatus } from "@scrile/api-provider/dist/api/UserVerificationProvider";
import { useTranslation } from "locales";
import useAuthUserVerification from "hooks/useAuthUserVerificationStatus";
import useBaseForm from "hooks/useBaseForm";
import providers from "lib/providers";
import { parseErrors } from "lib/requestHelpers";
import { FormValues } from "types";
import useController from "./controller";

const useViewController = () => {
  const { t } = useTranslation();
  const { assignToken } = useController();
  const { fields, values, errors, setErrors, setValues } = useBaseForm([
    {
      name: "token",
      label: t("Your Personal Access Token"),
      type: "password",
      className: "mb-8",
      autocomplete: "off",
    },
  ]);
  const { userVerification } = useAuthUserVerification();
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [availability, setAvailability] = useState(false);
  const userVerified = useMemo(() => userVerification?.status === UserVerificationStatus.VERIFIED, [userVerification]);
  const onChange = (values: FormValues) => setValues(values);

  const onSubmit = async () => {
    setProcessing(true);
    try {
      const res = await assignToken(values["token"], [userVerification!.userId]);
      if (res) {
        setAvailability(true);
      }
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    const assignmentSearch = async () => {
      if (userVerification) {
        setLoading(true);
        providers.CalendlyProvider.assignmentSearch({ userIds: [userVerification.userId] })
          .then((res) => {
            if (res.length > 0) setAvailability(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    assignmentSearch();
  }, [userVerification]);

  return {
    fields,
    errors,
    values,
    setErrors,
    token: values["token"],
    onChange,
    onSubmit,
    processing,
    loading,
    userVerified,
    availability,
  };
};

export default useViewController;
