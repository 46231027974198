import { useEffect, useRef, useState } from "react";
import { Message } from "@scrile/api-provider/dist/api/MessagesProvider";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { isAndroid } from "@scrile/tools/dist/lib/browserUtils";
import useAuthUser from "hooks/useAuthUser";
import useKeepScrollPosition from "hooks/useKeepScrollPosition";
import emitter, { EVENTS } from "lib/emitter";
import useController from "./controller";

export default function useViewController(userId: string, setSenderUser: (senderUser: UserPublicData | null) => void) {
  const [showUnread, setShowUnread] = useState(true);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { shouldKeepScroll, shouldScrollToBottom } = useKeepScrollPosition(contentRef);
  const { userRef } = useAuthUser();

  const {
    thread,
    messages,
    senderUser,
    loadMore,
    scrollMessages,
    loadingMessages,
    setObserve,
    minUnreadMessageId,
  } = useController(userId, shouldScrollToBottom);

  const wrappedLoadMore = (...args: any[]) => {
    shouldKeepScroll.current = true;
    return loadMore(...args);
  };

  const onFocus = () => {
    if (isAndroid()) {
      shouldScrollToBottom.current = true;
    }
  };

  useEffect(() => {
    const onSentMessage = async (message: Message | undefined) => {
      if (!message || !thread || thread.id !== message.threadId || message.senderId !== userRef.current?.id) {
        return;
      }
      shouldScrollToBottom.current = true;
      setShowUnread(false);
    };
    emitter.on<Message>(EVENTS.MESSAGES_NEW_MESSAGE, onSentMessage);
    return () => {
      emitter.off<Message>(EVENTS.MESSAGES_NEW_MESSAGE, onSentMessage);
    };
  }, [shouldScrollToBottom, thread, userRef]);

  const [cachedUnreadMessageID, setCachedUnreadMessageID] = useState(0);
  useEffect(() => {
    const minUnreadId = (minUnreadMessageId && Number(minUnreadMessageId)) || 0;
    if (minUnreadId && minUnreadId > 0 && cachedUnreadMessageID === 0) {
      setCachedUnreadMessageID(minUnreadId);
    }
  }, [minUnreadMessageId, cachedUnreadMessageID]);

  useEffect(() => {
    setSenderUser(senderUser);

    return () => {
      setSenderUser(null);
      setCachedUnreadMessageID(0);
      setShowUnread(true);
    };
  }, [senderUser, setSenderUser]);

  return {
    showUnread,
    messages,
    senderUser,
    loadMore: wrappedLoadMore,
    scrollMessages,
    loadingMessages,
    setObserve,
    onFocus,
    minUnreadMessageId: cachedUnreadMessageID.toString(),
    reverseContentRef: contentRef,
    thread,
  };
}
