import React, { forwardRef, MouseEvent, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useLocation } from "react-use";
import { t } from "locales";
import useAuthUser from "hooks/useAuthUser";
import BaseIcon from "components/BaseIcon";
import BaseInput from "components/BaseInput";
import ContentHeader from "components/ContentHeader";
import "./style.scss";

interface Props {
  className?: string;
  customCaption?: string;
  customText?: string;
  conferenceId: string;
  hideText?: boolean;
}

export interface CopyHandle {
  onCopyText: (e: MouseEvent) => void;
}

const ConferenceShare = forwardRef<CopyHandle, Props>(
  ({ className, customCaption, customText, hideText, conferenceId }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { user } = useAuthUser();
    const location = useLocation();

    const [successfulCopy, setSuccessfulCopy] = useState(false);
    const chatLink = `${location.origin}/conference/join/${conferenceId}`;

    const onCopyText = (e: MouseEvent) => {
      e.stopPropagation();
      if (!inputRef.current) return;

      inputRef.current.select();
      inputRef.current.blur();
      const status = document.execCommand("copy");
      window.getSelection()?.removeAllRanges();
      if (status) setSuccessfulCopy(true);
    };

    useImperativeHandle(ref, () => {
      return {
        onCopyText,
      };
    });

    useEffect(() => {
      let timeout: ReturnType<typeof setTimeout>;
      if (successfulCopy) {
        timeout = setTimeout(() => {
          setSuccessfulCopy(false);
        }, 1000);
      }
      return () => timeout && clearTimeout(timeout);
    }, [successfulCopy]);

    const cls = ["share"];

    const mailSubject = t("Conference call invite from {{screenName}}", { screenName: user?.screenName || "" });
    const mailText = t("{{screenName}} has invited you to join a conference call. {{chatLink}}", {
      screenName: user?.screenName || "",
      chatLink,
    });

    if (className) cls.push(className);

    return (
      <div className={cls.join(" ")}>
        <ContentHeader className="share__heading">
          {customCaption ? customCaption : t(`Share conference call`)}
        </ContentHeader>
        {!hideText && (
          <p className="share__text text-center body caption">
            {customText ? customText : t("Copy link and send it to people you want to invite to conference.")}
          </p>
        )}
        <div>
          <BaseInput
            refInput={inputRef}
            readOnly
            iconRight={"copy"}
            iconRightTooltip={t("Copy to clipboard")}
            className={"share__input mb-4" + (successfulCopy ? " __success" : "")}
            value={chatLink}
            onClickIconRight={(e) => onCopyText(e)}
            type={"text"}
            name={"chatLink"}
            onChange={() => {}}
          />
        </div>
        <div className="d-center">
          {
            // @ts-ignore
            navigator.share ? (
              <span
                className="link"
                // @ts-ignore
                onClick={() => navigator.share({ title: mailSubject, text: mailText })}
              >
                <BaseIcon className="mr-1">fas-share</BaseIcon>
                {t("Share")}
              </span>
            ) : (
              <a href={`mailto:?subject=${mailSubject}&body=${mailText}`} target="_blank" rel="noopener noreferrer">
                <BaseIcon className="mr-1">mail</BaseIcon>
                {t("Send via email")}
              </a>
            )
          }
        </div>
      </div>
    );
  }
);

ConferenceShare.displayName = "ConferenceShare";

export default ConferenceShare;
