import { User } from "@scrile/api-provider/dist/api/UserProvider";
import emitter, { EVENTS } from "./emitter";
const LOGIN_DATA = "LOGIN_DATA";
const SOCIAL_LOGIN_STATUS = "SOCIAL_LOGIN_STATUS";

export type LoginData = {
  user: User | null;
  authorities: string[] | null;
};

export function setSocialLoginStatus(status: boolean) {
  if (status) {
    localStorage.setItem(SOCIAL_LOGIN_STATUS, JSON.stringify(status));
  } else {
    localStorage.removeItem(SOCIAL_LOGIN_STATUS);
  }
}

export function isSocialLogin(): boolean {
  const data = localStorage.getItem(SOCIAL_LOGIN_STATUS);
  return data ? JSON.parse(data) : false;
}

export function setLoginData(data: LoginData) {
  localStorage.setItem(LOGIN_DATA, JSON.stringify(data));
  emitter.emit(EVENTS.SET_LOGIN_DATA, data);
}

export function getLoginData(): LoginData {
  const data = localStorage.getItem(LOGIN_DATA);
  return data ? JSON.parse(data) : { user: null, authorities: null };
}

export function removeLoginData() {
  localStorage.removeItem(LOGIN_DATA);
  emitter.emit(EVENTS.SET_LOGIN_DATA, { user: null, authorities: null });
}
