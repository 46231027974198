import { useState } from "react";
import config from "config";
import { useTranslation } from "locales";
import useBreakPoints from "hooks/useBreakPoints";
import useCustomDomain from "hooks/useCustomDomain";
import { LOGO_URL } from "lib/urlHelper";
import { BaseMenuItemInterface } from "types";
import useController from "./controller";

function useViewController() {
  const {
    onClickMemberBalance,
    user,
    showGoLiveButton,
    showGoLiveModal,
    openGoLiveModal,
    closeGoLiveModal,
    unreadThreadsCount,
  } = useController();
  const { t } = useTranslation();
  const [logoSrc, setLogoSrc] = useState(LOGO_URL);

  const setDefaultLogo = () => {
    setLogoSrc(process.env.PUBLIC_URL + "/images/logo.svg");
  };

  const { mobileSMOnly: isMobileSM } = useBreakPoints();

  const { isCustomDomain } = useCustomDomain();

  const itemsList: BaseMenuItemInterface[] = [
    {
      name: "notification",
      icon: "notification",
      title: t("Notifications"),
    },
    {
      name: "settings",
      icon: "settings",
      title: t("Settings"),
      to: "/user/settings",
      className: "__divider",
      divider: true,
    },
    {
      name: "logout",
      icon: "logout",
      title: t("Log out"),
      to: "/logout",
    },
  ];

  if (user?.role === config.userPerformerRole)
    itemsList.splice(0, 0, {
      name: "profile",
      icon: "profile",
      title: t("Profile"),
      to: "/" + (isCustomDomain ? "" : user?.username),
    });

  return {
    logoSrc,
    setDefaultLogo,
    itemsList,
    isMobileSM,
    onClickMemberBalance,
    user,
    showGoLiveButton,
    showGoLiveModal,
    openGoLiveModal,
    closeGoLiveModal,
    unreadThreadsCount,
  };
}

export default useViewController;
