import React from "react";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import useProduceConsumeController from "./useProduceConsumeController";
import ConferenceView from "../ConferenceView";

interface Props {
  livechatSubject: Subject<LivechatSubjectType>;
  conferenceId: string;
  onClose: () => void;
}

export default function ConferenceStreamController({ livechatSubject, conferenceId, onClose }: Props) {
  const {
    threadId,
    userList,
    produceMediaConstraints,
    loading,
    token,
    audioInputs,
    videoInputs,
    users,
    me,
    streamStartedDate,
    onChangeConstraints,
    onProducerFinishedStream,
    onCloseStream,
  } = useProduceConsumeController(livechatSubject);

  return (
    <ConferenceView
      threadId={threadId}
      conferenceId={conferenceId}
      loading={loading}
      userList={userList}
      token={token}
      showConfirmationExit
      users={users}
      me={me}
      managedConstraints={produceMediaConstraints}
      audioInputs={audioInputs}
      videoInputs={videoInputs}
      streamStartedDate={streamStartedDate}
      onChangeConstraints={onChangeConstraints}
      onCloseConference={onClose}
      onCloseStream={onCloseStream}
      onProduceFinishedStream={onProducerFinishedStream}
    />
  );
}
