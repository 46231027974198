import { useMemo, useRef, useState } from "react";
import { GuestUser } from "@scrile/api-provider/dist/api/GuestUsersProvider";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { MessageLinkSubjectTypeEnum } from "@scrile/api-provider/dist/api/MessageLinksProvider";
import { InputExtendedMessageCreate } from "@scrile/api-provider/dist/api/MessagesProvider";
import { getFileMimeType } from "@scrile/tools/dist/lib/FileHelpers";
import fileSize from "filesize";
import config from "config";
import { useTranslation } from "locales";
import useAuthorizedOnly from "hooks/useAuthorizedOnly";
import useAuthUser from "hooks/useAuthUser";
import useFiles from "hooks/useFiles";
import { useOnRouteChange } from "hooks/useOnRouteChange";
import emitter, { EVENTS } from "lib/emitter";
import providers from "lib/providers";
import { AttachedFileData, AttachedFileError } from "types";

type error = string;

const useMessageAttachments = () => {
  const { maxFileSizeUpload } = config;
  const { t } = useTranslation();

  const [attachedFiles, setAttachedFiles] = useState<(AttachedFileData | AttachedFileError)[]>([]);
  const { fileAddToQueue, removeFromQueue } = useFiles();
  const addAttachment = async (files: File[]) => {
    attachedFiles.forEach((f) => "error" in f && removeAttachment(f.id));
    if (files.length === 0) return;
    const filesToUpload: (File | error)[] = [];
    files.forEach((file) => {
      if (file.size > maxFileSizeUpload) {
        filesToUpload.push(
          t("The file size may not be over {{size}}", { size: fileSize(maxFileSizeUpload, { exponent: 3 }) })
        );
        return;
      }
      filesToUpload.push(file);
    });
    const updateFile = (id: string, data: Partial<AttachedFileData>) => {
      setAttachedFiles((files) => {
        return files.map((f) => {
          if (f.id === id) {
            return {
              ...f,
              ...data,
            };
          }
          return f;
        });
      });
      return data.id ?? id;
    };
    const addAttachmentError = (attachedFileError: AttachedFileError) => {
      setAttachedFiles((files) => [...files, attachedFileError]);
    };
    for (const file of filesToUpload) {
      let fileIndex = Math.random().toString(36).substring(2, 15) + filesToUpload.indexOf(file);
      if (file instanceof File) {
        setAttachedFiles((files) => [
          ...files,
          {
            id: fileIndex,
            fileName: file.name,
            mediaType: getFileMimeType(file),
            size: file.size,
            uploadProgress: 0,
          },
        ]);
        const {
          uploadData: { uuid },
          uploadId,
        } = await fileAddToQueue({
          type: "message_attachment",
          file: file,
          onSuccess: async () => {
            fileIndex = updateFile(fileIndex, { id: uuid, uploadProgress: 100 });
          },
          onUploadProgress: (p) => {
            const uploadProgress = Math.floor(((p.loaded ?? 0) / (p.total ?? 1)) * 100);
            const id = uploadProgress < 100 ? uploadId : uuid;
            fileIndex = updateFile(fileIndex, { uploadProgress, id });
          },
          onError: (error) => {
            // eslint-disable-next-line no-console
            console.debug(`File uploading error: ${error.message}`);
            removeAttachment(fileIndex);
            addAttachmentError({
              id: fileIndex,
              error: t("Uploading error"),
            });
          },
        });
        fileIndex = updateFile(fileIndex, { id: uploadId });
      } else {
        addAttachmentError({
          id: fileIndex,
          error: file,
        });
      }
    }
  };

  const removeAttachment = (id: string) => {
    setAttachedFiles((files) => {
      const index = files.findIndex((f) => f.id === id);
      if (index < 0) {
        return files;
      }
      const [fileData] = files.splice(index, 1);
      if (fileData && "uploadProgress" in fileData && fileData.uploadProgress < 100) {
        removeFromQueue(fileData.id);
      }
      return [...files];
    });
  };

  const isUploading = useMemo(() => attachedFiles.some((i) => "uploadProgress" in i && i.uploadProgress < 100), [
    attachedFiles,
  ]);

  return {
    attachedFiles,
    addAttachment,
    removeAttachment,
    isUploading,
  };
};

function useController(
  recipientIds?: string[],
  threadIds?: string[],
  allowGuestMessage?: boolean,
  guestUser?: GuestUser | null,
  setGuestUser?: (guest: GuestUser) => void,
  me?: LiveChatUser
) {
  const { user } = useAuthUser();
  const [value, setValue] = useState("");
  const [textArea, setTextarea] = useState<HTMLTextAreaElement | null>(null);
  const sending = useRef(false);
  const { attachedFiles, addAttachment, removeAttachment, isUploading } = useMessageAttachments();
  const { t } = useTranslation();

  const removeAllAttachments = () => attachedFiles.forEach((f) => removeAttachment(f.id));
  const removeAllAttachmentsOnSend = () =>
    attachedFiles.forEach((f) =>
      "uploadProgress" in f ? f.uploadProgress === 100 && removeAttachment(f.id) : removeAttachment(f.id)
    );

  const sendMessage = async (data: InputExtendedMessageCreate) => {
    const allowEmptyMessage = attachedFiles.length > 0 && !isUploading;
    if (sending.current || (!value.trim() && !allowEmptyMessage)) return;
    textArea?.focus();
    sending.current = true;
    try {
      const message = await providers.MessagesProvider.sendExtendedMessages({ data });
      emitter.emit(EVENTS.MESSAGES_NEW_MESSAGE, message[0]);
      setValue("");
      removeAllAttachmentsOnSend();
    } finally {
      sending.current = false;
    }
  };

  const onSendMessageGuest = async () => {
    if (!me) return;
    const data: InputExtendedMessageCreate = {
      text: value.trim(),
      threadIds,
      recipientIds,
      files: attachedFiles
        .filter((f) => "uploadProgress" in f && f.uploadProgress === 100)
        .map((f) => ({
          publicAccess: true,
          uuid: f.id,
        })),
      pricePackageId: "0",
    };
    if (!guestUser) {
      const guest = await providers.GuestUsersProvider.create({ data: { screenName: me.screenName } });
      setGuestUser && setGuestUser(guest);
      data.linkSubject = { subjectId: guest.id, subjectType: MessageLinkSubjectTypeEnum.GUEST_USER };
    } else {
      data.linkSubject = { subjectId: guestUser.id, subjectType: MessageLinkSubjectTypeEnum.GUEST_USER };
    }
    await sendMessage(data);
  };

  const onSendMessageAuthorized = useAuthorizedOnly(
    async () => {
      const data: InputExtendedMessageCreate = {
        text: value.trim(),
        threadIds,
        recipientIds,
        files: attachedFiles
          .filter((f) => "uploadProgress" in f && f.uploadProgress === 100)
          .map((f) => ({
            publicAccess: true,
            uuid: f.id,
          })),
        pricePackageId: "0",
      };
      await sendMessage(data);
    },
    {
      notification: t("Log in or register to send a message"),
    }
  );

  useOnRouteChange(() => {
    removeAllAttachments();
    setValue("");
  });

  const addAttachmentWrapper = (files: File[]) => {
    textArea?.focus();
    return addAttachment(files);
  };

  return {
    user,
    value,
    setValue,
    onSendMessage: allowGuestMessage && !user ? onSendMessageGuest : onSendMessageAuthorized,
    setTextarea,
    attachedFiles,
    isUploading,
    addAttachment: addAttachmentWrapper,
    removeAllAttachments,
    removeAttachment,
  };
}

export default useController;
