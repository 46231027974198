import { useState } from "react";
import { useMount } from "react-use";
import { TagInfo, TagInfoType } from "@scrile/api-provider/dist/api/TagsProvider";
import useMounted from "hooks/useMounted";
import providers from "lib/providers";

export default function useController() {
  const mounted = useMounted();
  const [tags, setTags] = useState<TagInfo[]>([]);

  useMount(async () => {
    const tags = await providers.TagsProvider.findAll({ data: { type: TagInfoType.USERS } });
    mounted.current && setTags(tags);
  });
  return { tags };
}
