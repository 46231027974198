import { useEffect, useState } from "react";
import { UserVerification } from "@scrile/api-provider/dist/api/UserVerificationProvider";
import { getFileMimeType } from "@scrile/tools/dist/lib/FileHelpers";
import useAuthUser from "hooks/useAuthUser";
import useMounted from "hooks/useMounted";
import fileUploader from "lib/fileUploader";
import providers from "lib/providers";

export default function useController() {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<UserVerification | null>(null);
  const mounted = useMounted();
  const { user } = useAuthUser();

  const getData = async () => {
    if (!user) return;
    const response = await providers.UserVerificationProvider.search({ data: { userIds: [user.id] } });
    setStatus(response.result.find((i) => i.userId === user.id) ?? null);
  };

  useEffect(() => {
    setLoading(true);
    getData().finally(() => mounted.current && setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeFile = async (type: string) => {
    await providers.FileProvider.delete({
      data: {
        subjectId: user?.id ?? "",
        type,
      },
    });
    await getData();
  };

  const onSendFile = async (files: FileList | null, type: "id_photo" | "photo_with_id") => {
    if (!files) {
      await removeFile(type);
      return;
    }
    if (!user || files.length === 0) return;
    const file = files[0];
    const uploadData = await providers.FileProvider.getUploadUrl({ fileIds: [{ subjectId: user.id, type }] });
    if (uploadData.length === 0) return;
    const { fileUpload, fileId } = uploadData[0];
    fileUploader.addToQueue([
      {
        file,
        fileType: getFileMimeType(file),
        urlPart: fileUpload.urlPart,
        id: `${fileId.subjectId}_${fileUpload.uuid}`,
        onSuccess: async () => {
          await providers.FileProvider.update({
            data: {
              fileId,
              uuid: fileUpload.uuid,
            },
          });
          await getData();
        },
      },
    ]);
  };

  return {
    loading,
    status,
    onSendFile,
  };
}
