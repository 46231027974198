import React, { useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { UserVerificationStatus } from "@scrile/api-provider/dist/api/UserVerificationProvider";
import { checkRole } from "@scrile/tools/dist/lib/SecurityHelpers";
import { LocationDescriptor } from "history";
import config from "config";
import useAuthUser from "hooks/useAuthUser";
import useAuthUserVerificationStatus from "hooks/useAuthUserVerificationStatus";
import useModalLinkPrinter from "hooks/useModalLinkPrinter";
import { isSocialLogin, setSocialLoginStatus } from "lib/authHelper";

const PATH_SIGNUP_CONFIRM = "/signup/confirm";
const PATH_SIGNUP_EMAIL_CONFIRMATION = "/signup/email";
const PATH_HOME = "/home";

const QUERY_CONFIRM_LONG_CODE = "code";

export default function RouteController() {
  const location = useLocation();
  const { user } = useAuthUser();
  const { userVerification } = useAuthUserVerificationStatus();
  const { getLocation } = useModalLinkPrinter();
  const [pathResult, setPathResult] = useState<LocationDescriptor | null>(null);

  const currentPath = location.pathname;
  const search = new URLSearchParams(location.search);

  useEffect(() => {
    if (user && !user.emailVerified && currentPath !== PATH_SIGNUP_EMAIL_CONFIRMATION && isSocialLogin()) {
      setPathResult(getLocation(PATH_SIGNUP_EMAIL_CONFIRMATION));
    } else if (user && !user.emailVerified && currentPath !== PATH_SIGNUP_CONFIRM && !isSocialLogin()) {
      setPathResult(getLocation(PATH_SIGNUP_CONFIRM));
    } else if (
      (currentPath.startsWith("/login") || currentPath.startsWith("/signup")) &&
      user &&
      user.emailVerified &&
      ((checkRole(user?.role ?? "", config.userPerformerRole) &&
        userVerification?.status === UserVerificationStatus.VERIFIED) ||
        (checkRole(user?.role ?? "", config.userJoinRole) &&
          userVerification?.status === UserVerificationStatus.UNVERIFIED))
    ) {
      setPathResult(PATH_HOME);
    } else if (
      currentPath === PATH_SIGNUP_CONFIRM &&
      ((user && user.emailVerified) || (!user && !search.get(QUERY_CONFIRM_LONG_CODE)))
    ) {
      setPathResult(PATH_HOME);
    }
    if (isSocialLogin() && user?.emailVerified) setSocialLoginStatus(false);
    return () => {
      setPathResult(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath, search, user]);

  return pathResult && pathResult !== currentPath ? <Redirect to={pathResult} /> : null;
}
