import React from "react";
import { useTranslation } from "locales";
import { DefaultStreamViewProps } from "types";
import ChatTimer from "components/ChatTimer";
import DotFlashingAnimation from "components/DotFlashingAnimation";
import StreamView from "components/StreamView";
import VolumeControl from "components/StreamView/components/VolumeControl";
import useViewController from "./viewController";
import EndAppointmentScreen from "../EndAppointmentScreen";
import SecondStreamView from "../SecondStreamView";

interface Props extends DefaultStreamViewProps {
  streamEndedDate: string;
  onCloseStream: () => void;
  secondStream: MediaStream;
  slotControlButtons?: JSX.Element;
  hasSecondVideo: boolean;
  hasSecondAudio: boolean;
  isProducerFinishedStream: boolean;
  onProduceFinishedStream: () => Promise<void>;
}

export default function AppointmentStreamView(props: Props) {
  const { t } = useTranslation();
  const { isChatEnded, onCloseChat, isPerformer, secondPlayer, setSecondPlayer } = useViewController(
    props.streamEndedDate,
    props.userList,
    props.onCloseStream,
    props.onProduceFinishedStream
  );

  const disabledVideo = isChatEnded || props.userList.length < 2 || props.isProducerFinishedStream;
  const slotVideoScreen = (() => {
    const arr: JSX.Element[] = [];
    if ((isChatEnded || props.isProducerFinishedStream) && props.produceUser) {
      arr.push(<EndAppointmentScreen produceUser={props.produceUser} key="end-screen" />);
    } else if (props.userList.length < 2) {
      arr.push(<DotFlashingAnimation key="dot-animation" />);
    }
    if (props.hasSecondVideo || props.hasSecondAudio) {
      arr.push(
        <SecondStreamView
          key="second-stream"
          stream={props.secondStream}
          hasVideo={props.hasSecondVideo}
          hasAudio={props.hasSecondAudio}
          setPlayer={setSecondPlayer}
        />
      );
    }
    return <>{arr.map((el) => el)}</>;
  })();

  const secondVolumeControls =
    secondPlayer && props.isProducerUser && props.managedConstraints?.shareEnabled && props.consume ? (
      <VolumeControl player={secondPlayer} className="second-stream-buttons__btn" />
    ) : undefined;

  const slotControlButtons = (
    <>
      {props.slotControlButtons}
      {secondVolumeControls}
    </>
  );

  return (
    <StreamView
      {...props}
      slotControlButtons={slotControlButtons}
      forceHideControlButtons={isChatEnded}
      onClose={onCloseChat}
      disableUserList={true}
      slotTimer={
        <ChatTimer
          hideTimer={!isPerformer || isChatEnded}
          streamStartedDate={props.streamStartedDate}
          grayRecordIcon={isChatEnded}
        >
          {t("Private chat")}
        </ChatTimer>
      }
      disabledVideo={disabledVideo}
      slotVideoScreen={slotVideoScreen}
      slotContentConfirmationExit={
        <>
          <h1 className="headline-1 text-center mb-6">{t("Do you want to end the appointment?")}</h1>
          <p className="body text-center">{t("The appointment cannot be resumed after completion.")}</p>
        </>
      }
    />
  );
}
