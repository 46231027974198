import { t } from "locales";
import { FieldInterface } from "types";

interface QuestionnaireI {
  field: FieldInterface;
  role: string[];
}

export const questionnaireFields: QuestionnaireI[] = [
  {
    field: {
      name: "about",
      type: "textarea",
      label: t("About me"),
      autoGrow: true,
      maxRows: 10,
      maxLength: 500,
      showLengthCounter: true,
    },
    role: ["performer"],
  },
  {
    field: {
      name: "website",
      type: "text",
      label: t("Website"),
      iconLeft: "link",
    },
    role: ["performer"],
  },
];
