import { useCallback, useState } from "react";
import useBreakPoints from "hooks/useBreakPoints";
import useController from "./controller";

export default function useViewController(username: string) {
  const [showBook, setShowBook] = useState<boolean>(false);
  const [mobileReviewsMode, setMobileReviewsMode] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const { tabletUp: isTablet } = useBreakPoints();
  const {
    user,
    appointments,
    appointmentsLoading,
    video,
    videoLoading,
    reviewsLoading,
    onReviewAdded,
    reviewItems,
    getNextReviewsPage,
    enableReviewsScroll,
    totalReviewsRecords,
    showBackButton,
    onClickBackButton,
  } = useController(username);

  const onOpenBook = useCallback(() => setShowBook(true), []);
  const onCloseBook = useCallback(() => setShowBook(false), []);
  const onMobileReviewsModeOn = useCallback(() => setMobileReviewsMode(true), []);
  const onMobileReviewsModeOff = useCallback(() => setMobileReviewsMode(false), []);

  return {
    user,
    showBook,
    onOpenBook,
    onCloseBook,
    appointments,
    appointmentsLoading,
    video,
    videoLoading,
    showVideoModal,
    setShowVideoModal,
    reviewsLoading,
    mobileReviewsMode,
    onMobileReviewsModeOn,
    onMobileReviewsModeOff,
    isTablet,
    onReviewAdded,
    reviewItems,
    getNextReviewsPage,
    enableReviewsScroll,
    totalReviewsRecords,
    showBackButton,
    onClickBackButton,
  };
}
