import { useEffect } from "react";
import { isEqual } from "lodash";
import { useTranslation } from "locales";
import useBaseForm from "hooks/useBaseForm";
import { FormValues, MediaConstraints } from "types";

const getCurrentValue = (inputs: MediaDeviceInfo[], constraints: MediaTrackConstraints | boolean) => {
  if (typeof constraints === "boolean") return;
  return inputs.find(
    (i) =>
      typeof constraints.deviceId === "object" &&
      !Array.isArray(constraints.deviceId) &&
      typeof constraints.deviceId.exact === "string" &&
      i.deviceId === constraints.deviceId.exact
  );
};

function useViewController({
  audioInputs,
  videoInputs,
  onChangeConstraints,
  constraints,
  loading,
}: {
  onChangeConstraints?: (values: MediaConstraints) => void;
  constraints?: MediaConstraints | null;
  audioInputs: MediaDeviceInfo[];
  videoInputs: MediaDeviceInfo[];
  loading: boolean;
}) {
  const { t } = useTranslation();

  const { fields, values, errors, setValues, setErrors } = useBaseForm([
    {
      name: "audio-device",
      type: "select",
      label: t("Microphone"),
      placeholder: t("Default"),
      disabled: loading,
      options: audioInputs.map((device, i) => ({
        value: device,
        text: device.label || t("Microphone {{number}}", { number: i }),
      })),
    },
    {
      name: "video-device",
      type: "select",
      label: t("Camera"),
      placeholder: t("Default"),
      disabled: loading,
      options: videoInputs.map((device, i) => ({
        value: device,
        text: device.label || t("Camera {{number}}", { number: i }),
      })),
    },
  ]);

  useEffect(() => {
    setValues({
      "audio-device": getCurrentValue(audioInputs, constraints?.audio ?? false),
      "video-device": getCurrentValue(videoInputs, constraints?.video ?? false),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioInputs, videoInputs]);

  const onChange = (val: FormValues) => {
    const audioChanged = !isEqual(val["audio-device"], values["audio-device"]);

    const constraints: MediaConstraints = {};
    if (audioChanged) {
      constraints.audio = { deviceId: { exact: val["audio-device"].deviceId } };
    } else {
      constraints.video = { deviceId: { exact: val["video-device"].deviceId } };
    }
    setValues(val);
    onChangeConstraints && onChangeConstraints(constraints);
  };

  return {
    fields,
    values,
    setErrors,
    onChange,
    errors,
  };
}

export default useViewController;
