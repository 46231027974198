import React from "react";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import useViewController from "./viewController";
import "./style.scss";

export default function PromotePerformerVerification() {
  const { hide, waiting, showPerformerVerification } = useViewController();
  const { t } = useTranslation();
  let text = "Become a consultant";
  let icon = "rocket";
  let sizeIcon = 40;
  const cls = ["promote-performer-verification"];
  if (waiting) {
    text = "The account is being verified by the administrator";
    icon = "watch";
    sizeIcon = 23;
    cls.push("__waiting");
  }

  return (
    (!hide && (
      <BaseButton className={cls.join(" ")} onClick={showPerformerVerification}>
        <BaseIcon size={sizeIcon}>{icon}</BaseIcon>
        <span>{t(text)}</span>
      </BaseButton>
    )) ||
    null
  );
}
