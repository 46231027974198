import React from "react";
import { Transaction } from "@scrile/api-provider/dist/api/TransactionProvider";
import { useTranslation } from "locales";
import DefaultTransaction from "../DefaultTransaction";

interface Props {
  transaction: Transaction;
}

function TransactionRefill({ transaction }: Props) {
  const { t } = useTranslation();
  return (
    <DefaultTransaction
      transaction={transaction}
      type={t("Refill account")}
      description={
        <span className="caption">
          {t("ID")}&nbsp;<span className="caption__mark">{transaction.reference}</span>
        </span>
      }
    />
  );
}

export default TransactionRefill;
