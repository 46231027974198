import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMount } from "react-use";
import providers from "lib/providers";

export default function useController(token: string) {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const loginToken = async () => {
    await providers.LoginProvider.refreshTokenV2({ token, createNewLongLiveToken: true });
  };

  useMount(async () => {
    try {
      await loginToken();
      history.push("/");
      window.location.reload();
    } finally {
      setLoading(false);
    }
  });

  return {
    loading,
  };
}
