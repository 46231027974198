import React, { useEffect, useMemo } from "react";
import { InputPerformerListSearch } from "@scrile/api-provider/dist/projects/webvideo/PerformerListProvider";
import { lockBodyScroll } from "@scrile/tools/dist/lib/Scrollhelpers";
import { useTranslation } from "locales";
import useBreakPoints from "hooks/useBreakPoints";
import useComponentVisible from "hooks/useComponentVisible";
import BaseButton from "components/BaseButton";
import BaseCounter from "components/BaseCounter";
import BaseIcon from "components/BaseIcon";
import useViewController from "./viewController";
import FilterByName from "../FilterByName";
import FilterByTags from "../FilterByTags";
import StatusFilter from "../StatusFilter";
import "./style.scss";

export interface Props {
  filtersDTO: InputPerformerListSearch;
  setFilters: (filtersDTO: InputPerformerListSearch) => void;
  totalRecords: number;
}

function BaseFilters(props: Props) {
  const { ref, isComponentVisible, toggleComponentVisible } = useComponentVisible(false);
  const { desktopUp } = useBreakPoints();
  const { counter, onStatusInput, onFilterTags, onNameChange } = useViewController(props);
  const { t } = useTranslation();

  useEffect(() => {
    lockBodyScroll(!desktopUp && isComponentVisible);
  }, [isComponentVisible, desktopUp]);

  const filters = (
    <>
      <FilterByTags initialValue={props.filtersDTO.tags} onFilterTags={onFilterTags} />
      <StatusFilter initialValue={props.filtersDTO.online} onInput={onStatusInput} />
    </>
  );

  const buttonContentFilters = useMemo(
    () => (
      <>
        <BaseIcon className="filters__activator-icon">filter</BaseIcon>
        <BaseCounter className="filters__activator-counter">{counter}</BaseCounter>
      </>
    ),
    [counter]
  );
  const buttonContentCloseFilters = useMemo(() => <BaseIcon>close</BaseIcon>, []);

  return (
    <div className="filters">
      <FilterByName className="filters__by-name" initialValue={props.filtersDTO.name} onChange={onNameChange} />
      <div className="filters__wrapper-mobile" ref={ref}>
        {!desktopUp && (
          <BaseButton borderless className="filters__activator-button" onClick={toggleComponentVisible}>
            {buttonContentFilters}
          </BaseButton>
        )}

        <div className={"filters-mobile " + (isComponentVisible ? "__active" : "")}>
          {!desktopUp && (
            <div className="filters-mobile__header">
              <div className="filters-mobile__header-wrapper-icon">
                <BaseIcon>filter</BaseIcon>
                <BaseCounter className="filters-mobile__header-counter">{counter}</BaseCounter>
              </div>
              <div className="filters-mobile__header-result">
                <h2 className="filters-mobile__heading mr-4">{t("Filters")}</h2>
                <span className="filters-mobile__result-line">
                  {t("{{totalRecords}} results", { totalRecords: props.totalRecords })}
                </span>
              </div>

              <BaseButton className="filters-mobile__close" opacity onClick={toggleComponentVisible}>
                {buttonContentCloseFilters}
              </BaseButton>
            </div>
          )}
          <div className="filters-mobile__main">{filters}</div>
        </div>
      </div>
    </div>
  );
}

export default BaseFilters;
