import { useEffect, useState } from "react";
import { FragmentFields } from "@scrile/api-provider/dist/api/types";
import { QuestionaryInfo } from "@scrile/api-provider/dist/projects/webvideo/QuestionaryInfoProvider";
import useAuthUser from "hooks/useAuthUser";
import useMounted from "hooks/useMounted";
import providers from "lib/providers";
import { FormValues } from "types";
import { questionnaireFields } from "./questionnaireFields";

const performerSearchFields: FragmentFields<QuestionaryInfo> = ["fields"];

function useController() {
  const { user } = useAuthUser();

  const mounted = useMounted();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Record<string, any>>({});

  useEffect(() => {
    (async function () {
      mounted.current && setLoading(true);
      try {
        const questionnaire = await providers.QuestionaryInfoProvider.find(
          { id: user?.id || "" },
          performerSearchFields
        );
        mounted.current &&
          setData((prevState) => {
            const newData: Record<string, any> = {};
            questionnaireFields.forEach((item) => {
              newData[item.name] = questionnaire.fields[item.name];
            });
            return {
              ...prevState,
              ...newData,
            };
          });
      } finally {
        mounted.current && setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendQuestionnaire = async (values: FormValues) => {
    const data: QuestionaryInfo = {
      fields: {
        userId: user?.id || "",
      },
    };
    questionnaireFields.forEach((field) => {
      data.fields[field.name] = values[field.name];
    });
    await providers.QuestionaryInfoProvider.update({
      data,
    });
  };

  return { sendQuestionnaire, loading, data };
}

export default useController;
