import React, { createContext, Reducer, useContext, useEffect, useReducer, useState } from "react";
import { UserVerification } from "@scrile/api-provider/dist/api/UserVerificationProvider";
import providers from "lib/providers";
import useAuthUser from "./useAuthUser";
import useMounted from "./useMounted";

const LOCAL_STORAGE_KEY = "AuthUserVerificationStatus";

type UserVerificationState = (UserVerification & { role: string | null }) | null;
type Action = {
  type: "setUserVerification";
  payload: UserVerificationState;
};
const reducer: Reducer<UserVerificationState, Action> = (state, action) => {
  switch (action.type) {
    case "setUserVerification":
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(action.payload));
      return action.payload;
  }
};
const storageData = localStorage.getItem(LOCAL_STORAGE_KEY);
const defaultState = storageData ? JSON.parse(storageData) : null;

const UserVerificationContext = createContext<
  [UserVerificationState, React.Dispatch<Action>, boolean, () => Promise<void>]
>([
  defaultState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
  false,
  async () => {},
]);

export function AuthUserVerificationContextProvider(props: React.PropsWithChildren<Record<string, any>>) {
  const isMounted = useMounted();
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, defaultState);
  const { user } = useAuthUser();

  useEffect(() => {
    if (!user && state) {
      dispatch({ type: "setUserVerification", payload: null });
      return;
    }
    if ((state && user && state.userId === user.id && user.role === state.role) || !user || !isMounted.current) return;
    providers.UserVerificationProvider.search({ data: { userIds: [user.id] } }).then((data) => {
      if (!isMounted.current) return;
      const payload = data.result.find((i) => i.userId === user.id);
      dispatch({ type: "setUserVerification", payload: payload ? { ...payload, role: user.role } : null });
    });
  }, [user, state, isMounted]);

  const getVerificationStatus = async () => {
    if (!user || loading) {
      return;
    }
    try {
      setLoading(true);
      const response = await providers.UserVerificationProvider.search({ data: { userIds: [user.id] } });
      const payload = response.result.find((i) => i.userId === user.id);
      if (payload?.status !== state?.status && isMounted.current) {
        dispatch({ type: "setUserVerification", payload: payload ? { ...payload, role: user.role } : null });
      }
    } finally {
      isMounted.current && setLoading(false);
    }
  };

  return React.createElement(
    UserVerificationContext.Provider,
    {
      value: [state, dispatch, loading, getVerificationStatus],
    },
    props.children
  );
}

export default function useAuthUserVerification() {
  const [userVerification, dispatch, loading, getVerificationStatus] = useContext(UserVerificationContext);

  return {
    userVerification,
    dispatch,
    loading,
    getVerificationStatus,
  };
}
