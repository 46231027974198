import { useState } from "react";
import { useMount } from "react-use";
import { InputCCBillPurchaseDeposit } from "@scrile/api-provider/dist/api/CCBillPurchaseProvider";
import { CreditCardAssignment } from "@scrile/api-provider/dist/api/CreditCardAssignmentsProvider";
import { PaymentSystemPublic } from "@scrile/api-provider/dist/api/PaymentSystemsProvider";
import useAuthUser from "hooks/useAuthUser";
import providers from "lib/providers";

function useController(currentSystem: PaymentSystemPublic | null) {
  const { user } = useAuthUser();
  const [creditCardsAssignment, setCreditCardsAssignment] = useState<CreditCardAssignment[]>([]);
  const [loading, setLoading] = useState(false);

  const sendSubmit = async (data: InputCCBillPurchaseDeposit) => {
    return await providers.CCBillPurchaseProvider.purchaseDeposit({ data });
  };

  useMount(() => {
    setLoading(true);
    providers.CreditCardAssignmentsProvider.findAll({ userId: user?.id ?? "" })
      .then((r) => {
        const cards = r.filter((card) => card.publicPaymentSystem?.type === currentSystem?.type);
        setCreditCardsAssignment(cards);
      })
      .finally(() => setLoading(false));
  });
  return {
    sendSubmit,
    creditCardsAssignment,
    loading,
  };
}

export default useController;
