import React from "react";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { Appointment } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";
import { debounce } from "lodash-es";
import config from "config";
import useProduceConsumeController from "hooks/useProduceConsumeController";
import BaseIcon from "components/BaseIcon";
import VideoControlButton from "components/VideoControlButton";
import AppointmentStreamView from "../AppointmentStreamView";

interface Props {
  produceUser: UserPublicData;
  consumeUser: UserPublicData;
  onClose: () => void;
  livechatSubject: Subject<LivechatSubjectType>;
  appointment?: Appointment;
  disableAttachments?: boolean;
}

function ProduceController({ produceUser, consumeUser, livechatSubject, onClose, appointment }: Props) {
  const {
    threadId,
    produceStream,
    produceScreenStream,
    consumeStream,
    loading,
    users,
    token,
    consumeMediaConstraints,
    produceMediaConstraints,
    onCloseStream,
    onChangeConstraints,
    audioInputs,
    videoInputs,
    isShare,
    hasConsumeTracks,
    isProducerFinishedStream,
    onProducerFinishedStream,
  } = useProduceConsumeController(consumeUser.id, livechatSubject, true);

  const onToggleScreenSharing = debounce(async () => {
    if (!onChangeConstraints || !produceMediaConstraints) return;
    await onChangeConstraints({ shareEnabled: !produceMediaConstraints.shareEnabled });
  }, 200);

  const stream = isShare ? produceScreenStream : hasConsumeTracks ? consumeStream : produceStream;
  const secondStream = isShare ? consumeStream : produceStream;
  const hasSecondVideo = isShare
    ? hasConsumeTracks && !!consumeMediaConstraints?.videoEnabled
    : hasConsumeTracks && !!produceMediaConstraints?.videoEnabled;
  const hasSecondAudio = isShare ? hasConsumeTracks && !!consumeMediaConstraints?.audioEnabled : false;

  const controlButton =
    config.featureScreenSharing && !isMobile() ? (
      <VideoControlButton
        className="panel-control-buttons__btn"
        name="toggle-screen"
        toggle={isShare}
        borderless={true}
        onClick={onToggleScreenSharing}
        disabled={loading}
      >
        <BaseIcon>{!isShare ? "screen-share_on" : "screen-share_off"}</BaseIcon>
      </VideoControlButton>
    ) : undefined;
  return (
    <AppointmentStreamView
      produceUser={produceUser}
      isProducerUser={true}
      consumeUser={consumeUser}
      threadId={threadId}
      produce={true}
      consume={hasConsumeTracks}
      stream={stream}
      secondStream={secondStream}
      managedStream={produceStream}
      hasSecondVideo={hasSecondVideo}
      hasSecondAudio={hasSecondAudio}
      loading={loading}
      userList={users}
      streamStartedDate={appointment?.startTime ?? ""}
      streamEndedDate={appointment?.endTime ?? ""}
      onClose={onClose}
      token={token}
      constraints={hasConsumeTracks ? consumeMediaConstraints : produceMediaConstraints}
      managedConstraints={produceMediaConstraints}
      audioInputs={audioInputs}
      videoInputs={videoInputs}
      onCloseStream={onCloseStream}
      onChangeConstraints={onChangeConstraints}
      slotControlButtons={controlButton}
      showConfirmationExit={true}
      isProducerFinishedStream={isProducerFinishedStream}
      onProduceFinishedStream={onProducerFinishedStream}
    />
  );
}

export default ProduceController;
