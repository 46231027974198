import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "locales";
import useAuthUser from "hooks/useAuthUser";
import useCustomDomain from "hooks/useCustomDomain";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import LiveChatShare from "components/LiveChatShare";
import ModalWindow from "components/ModalWindow";
import "./style.scss";

function GoLiveModal({ show, onClose }: { show: boolean; onClose: () => void }) {
  const { user } = useAuthUser();
  const history = useHistory();
  const { t } = useTranslation();
  const { isCustomDomain, user: userFromDomain } = useCustomDomain();

  const link = useMemo(() => `${isCustomDomain && user?.id === userFromDomain?.id ? "" : `/${user?.username}`}/live`, [
    isCustomDomain,
    user,
    userFromDomain,
  ]);
  const onClickStart = () => {
    onClose();
    history.push(link);
  };

  return (
    <ModalWindow show={show} onClose={onClose} className={"go-live-modal"}>
      <LiveChatShare
        produce={true}
        produceUser={user!}
        customCaption={t("Share your new Live Chat")}
        customText={t(
          "By clicking Start you will start a new live chat. Members will see that you're online and will be able to join the live chat. You can also copy and share chat invite link or send it via email."
        )}
      />
      <div className="go-live-modal__buttons">
        <BaseButton className="go-live-modal__btn" outline={true} large={true} onClick={onClose}>
          {t("Cancel")}
        </BaseButton>
        <BaseButton className="go-live-modal__btn" large={true} onClick={onClickStart}>
          <BaseIcon className="mr-1">camera</BaseIcon>
          {t("Start")}
        </BaseButton>
      </div>
    </ModalWindow>
  );
}

export default GoLiveModal;
