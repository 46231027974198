import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import useAppState from "hooks/useAppState";
import { printCredits } from "lib/moneyHelper";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";

interface Props {
  className?: string;
  onClick?: () => void;
  icon?: boolean;
  to?: string;
}

function BaseBalance({ className, onClick, icon, to }: Props) {
  const { balance, balanceLoading } = useAppState();

  const cls = ["base-balance", "fw-normal"];
  if (className) cls.push(className);

  const content = useMemo(
    () => (
      <>
        <BaseIcon className="mr-1">wallet</BaseIcon>
        <span className={"base-balance__text"}>{printCredits(balance)}</span>
        {icon && <BaseIcon className="ml-1">circle-plus</BaseIcon>}
      </>
    ),
    [balance, icon]
  );

  return onClick ? (
    <BaseButton loading={balanceLoading} className={cls.join(" ")} borderless onClick={onClick}>
      {content}
    </BaseButton>
  ) : to ? (
    <Link className={cls.join(" ")} to={to}>
      {content}
    </Link>
  ) : (
    <div className={cls.join(" ")}>{content}</div>
  );
}

export default BaseBalance;
