import { useEffect, useState } from "react";
import useMounted from "hooks/useMounted";

export default function useBlurAnimation(animationDuration: number, focusClass: string) {
  const mounted = useMounted();
  const [animationState, setAnimationState] = useState<{ focus: boolean; timeout: number; className: string[] }>({
    focus: false,
    timeout: 0,
    className: [],
  });
  useEffect(() => {
    if (animationState.timeout) clearTimeout(animationState.timeout);
    if (animationState.focus) {
      mounted.current && setAnimationState((s) => ({ ...s, className: [focusClass] }));
    } else if (animationState.className) {
      const timeout = (setTimeout(() => {
        mounted.current && setAnimationState((s) => ({ ...s, className: [] }));
      }, animationDuration) as any) as number;
      mounted.current && setAnimationState((s) => ({ ...s, timeout }));
    }
    return () => clearTimeout(animationState.timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationDuration, focusClass, animationState.focus]);

  const onFocus = () => {
    mounted.current && setAnimationState((s) => ({ ...s, focus: true }));
  };
  const onBlur = () => mounted.current && setAnimationState((s) => ({ ...s, focus: false }));

  return {
    className: animationState.className.join(" "),
    onFocus,
    onBlur,
  };
}
