import { useMemo, useState } from "react";
import { Item, Props } from "./index";

export default function useViewController({ items, values, onInput }: Omit<Props, "title">) {
  const [state, setState] = useState({ showMore: false, filter: "", mobileHide: false });

  const filteredItems = useMemo(() => {
    const simpleNameArray = values.map((id) => id);
    let itemsToPrint = items.map((i) => ({ ...i, value: simpleNameArray.includes(i.id) }));
    if (state.filter)
      itemsToPrint = itemsToPrint.filter((i) =>
        i.label.toLowerCase().match(new RegExp(`${state.filter.trim().toLowerCase()}`))
      );
    return itemsToPrint;
  }, [items, values, state.filter]);

  const itemsToPrint = useMemo(() => (!state.showMore ? filteredItems.slice(0, 5) : filteredItems), [
    filteredItems,
    state.showMore,
  ]);

  const hasMore = useMemo(() => filteredItems.length > 5, [filteredItems]);

  const toggleMore = () => setState((s) => ({ ...s, showMore: !s.showMore }));
  const toggleMobileHide = () => setState((s) => ({ ...s, mobileHide: !s.mobileHide }));

  const onChange = (item: Item, v: boolean) => {
    const localValues = Array.from(values);
    const index = localValues.findIndex((id) => id === item.id);
    if (v && index < 0) {
      localValues.push(item.id);
    } else if (!v && index >= 0) {
      localValues.splice(index, 1);
    }
    onInput(localValues);
  };

  const onFilter = (value: string) => setState((s) => ({ ...s, filter: value }));

  return {
    ...state,
    hasMore,
    itemsToPrint,
    toggleMore,
    toggleMobileHide,
    onChange,
    onFilter,
  };
}
