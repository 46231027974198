import { useState } from "react";
import providers from "lib/providers";

export default function useConference() {
  const [showConferenceModal, setShowConferenceModal] = useState(false);
  const [conferenceId, setConferenceId] = useState("");

  const createConference = async () => {
    const result = await providers.ConferenceProvider.create();
    setConferenceId(result.id);
  };

  return {
    showConferenceModal,
    conferenceId,
    setShowConferenceModal,
    createConference,
  };
}
