import { useEffect, useMemo, useState } from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { differenceInMilliseconds } from "@scrile/tools/dist/lib/TimeHelpers";
import useAuthUser from "hooks/useAuthUser";
import { isPerformer } from "lib/usersHelper";

export default function useViewController(
  streamEndedDate: string,
  userList: LiveChatUser[],
  onCloseStream: () => void,
  onProduceFinishedStream: () => Promise<void>
) {
  const [isChatEnded, setIsChatEnded] = useState(false);
  const numberOfUsers = useMemo(() => {
    return userList.length;
  }, [userList]);
  const [secondPlayer, setSecondPlayer] = useState<HTMLVideoElement | null>(null);

  const { user } = useAuthUser();

  const onCloseChat = async () => {
    await onProduceFinishedStream();
    setIsChatEnded(true);
    onCloseStream();
  };

  useEffect(() => {
    const min = differenceInMilliseconds(new Date(streamEndedDate), new Date(new Date().toISOString())) / 1000 / 60;
    if (min < -15 && numberOfUsers < 2) {
      onCloseChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamEndedDate, numberOfUsers]);

  return {
    isChatEnded,
    onCloseChat,
    isPerformer: isPerformer(user),
    secondPlayer,
    setSecondPlayer,
  };
}
