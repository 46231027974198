import React, { useMemo, useState } from "react";
import { debounce } from "lodash-es";
import { useTranslation } from "locales";
import BaseInput from "components/BaseInput";

interface Props {
  initialValue?: string;
  className?: string;
  debounceTime?: number;
  onChange: (v: string) => void;
}

function SearchByName({ initialValue = "", className, debounceTime = 500, onChange }: Props) {
  const [value, setValue] = useState(initialValue);
  const { t } = useTranslation();

  const debouncedOnChange = useMemo(() => debounce(onChange, debounceTime), [debounceTime, onChange]);

  const onInput = (v: string) => {
    setValue(v);
    debouncedOnChange(v);
  };

  const cls = ["search-by-name"];
  className && cls.push(className);

  return (
    <BaseInput
      className={cls.join(" ")}
      value={value}
      name="searchByName"
      iconRight={value ? "close" : "search"}
      iconRightClassName="search-by-name__icon-right"
      onClickIconRight={() => onInput("")}
      type="text"
      onChange={onInput}
      placeholder={t("Search by the name")}
      blurBy={"Enter"}
    />
  );
}

export default SearchByName;
