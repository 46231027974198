import { useTranslation } from "locales";
import useBaseForm from "hooks/useBaseForm";
import { FormValues } from "types";
import { Props } from "./index";

export default function useViewController({ initialValue, onInput }: Props) {
  const { t } = useTranslation();
  const { fields, values, setValues } = useBaseForm(
    [
      {
        name: "online",
        type: "checkbox",
        label: t("Online"),
        className: "status-filter__checkbox",
      },
      {
        name: "offline",
        type: "checkbox",
        label: t("Offline"),
        className: "status-filter__checkbox",
      },
    ],
    initialValue === undefined ? {} : { online: initialValue, offline: !initialValue }
  );

  const onChange = ({ online, offline }: FormValues) => {
    onInput(!online === !offline ? undefined : !!online);
    setValues({ online, offline });
  };

  return {
    values,
    fields,
    onChange,
  };
}
