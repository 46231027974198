import React from "react";
import ChangePassword from "components/ChangePassword";
import useController from "./controller";

interface Props {
  email?: string;
  token?: string;
  onCancel: () => void;
}

export default function ForgotPassword({ token, email, onCancel }: Props) {
  const { onResetStart, onCodeVerified } = useController();

  return (
    <ChangePassword
      email={email}
      token={token}
      onResetStart={onResetStart}
      onCodeVerified={onCodeVerified}
      onCancel={onCancel}
    />
  );
}
