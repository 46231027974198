import React from "react";
import BasePaginator from "components/BasePaginator";
import PageLoading from "components/ElementLoading";
import useController, { AppointmentsTimeRange } from "./controller";
import AppointmentList from "../AppointmentList";
import "./style.scss";

interface AppointmentsRangeProps extends AppointmentsTimeRange {
  hideStatus?: boolean;
}

export default function AppointmentsRange({
  hideStatus,
  startTimeFrom,
  startTimeTo,
  endTimeFrom,
  endTimeTo,
  sort = "START_TIME",
}: AppointmentsRangeProps) {
  const { totalPages, appointments, processing, onPageChanged, isConsultant } = useController({
    startTimeTo,
    startTimeFrom,
    endTimeFrom,
    endTimeTo,
    sort,
  });

  return (
    <div className="appointments-range">
      <div className="appointments-range__content">
        {processing ? (
          <PageLoading loading={processing} />
        ) : (
          <AppointmentList
            className={`appointments-range__list ${totalPages > 1 && "divider-bottom__single"}`}
            appointments={appointments}
            isConsultant={isConsultant}
            hideStatus={hideStatus}
          />
        )}
      </div>

      <BasePaginator
        className="appointments-range__paginator mt-6"
        totalPages={totalPages}
        currentPageChanged={onPageChanged}
      />
    </div>
  );
}
