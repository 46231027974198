import { getFileMimeType } from "@scrile/tools/dist/lib/FileHelpers";
import { UploadFileData } from "@scrile/tools/dist/lib/FileUploader";
import FileUploader from "lib/fileUploader";
import providers from "lib/providers";

interface UploadFilesToS3Attrs {
  subjectId?: string;
  file: File;
  type: string;
  onSuccess: UploadFileData["onSuccess"];
  onUploadProgress?: UploadFileData["onUploadProgress"];
  onError?: UploadFileData["onError"];
}

export default function useFiles() {
  const fileAddToQueue = async ({
    type,
    file,
    subjectId = "1",
    onError,
    onSuccess,
    onUploadProgress,
  }: UploadFilesToS3Attrs) => {
    const response = await providers.FileProvider.getUploadUrl({ fileIds: [{ subjectId, type }] });
    const id = `${response[0].fileId.subjectId}_${response[0].fileUpload.uuid}`;
    FileUploader.addToQueue([
      {
        file,
        fileType: getFileMimeType(file),
        urlPart: response[0].fileUpload.urlPart,
        id,
        onSuccess,
        onError,
        onUploadProgress,
      },
    ]);
    return {
      uploadId: id,
      uploadData: response[0].fileUpload,
      uploadFileId: response[0].fileId,
    };
  };

  const removeFromQueue = (id: string) => FileUploader.removeFromQueue(id);

  return {
    fileAddToQueue,
    removeFromQueue,
  };
}
