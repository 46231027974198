export const addTracksToStream = (stream: MediaStream, tracks: MediaStreamTrack[], closeOutTracks = false) => {
  tracks.forEach((t) => {
    stream.addTrack(t.clone());
    if (closeOutTracks) t.stop();
  });
};

export const removeTracksFromStream = (stream: MediaStream, tracks: MediaStreamTrack[]) => {
  tracks.forEach((t) => {
    t.stop();
    stream.removeTrack(t);
  });
};

export const getVideoTracks = (stream: MediaStream): MediaStreamTrack[] => {
  // @ts-ignore
  return stream.getVideoTracks().filter((t) => t.kindType !== "screen");
};

export const getScreenTracks = (stream: MediaStream): MediaStreamTrack[] => {
  // @ts-ignore
  return stream.getVideoTracks().filter((t) => t.kindType === "screen");
};

export const cloneScreenTracks = (tracks: MediaStreamTrack[]) => {
  const tempScreenTracks = tracks.map((t) => t.clone());
  // @ts-ignore
  tempScreenTracks.forEach((t) => (t.kindType = "screen"));
  return tempScreenTracks;
};
