import React, { PropsWithChildren, ReactNode } from "react";
import ElementLoading from "components/ElementLoading";
import "./style.scss";

interface Props {
  loading?: boolean;
  side?: React.FC | ReactNode;
  append?: React.FC | ReactNode;
  prepend?: React.FC | ReactNode;
  revers?: boolean;
  className?: string;
}

export default function BasePage({
  loading = false,
  className,
  revers,
  side,
  append,
  prepend,
  children,
}: PropsWithChildren<Props>) {
  const cls = ["base-page"];
  if (revers) cls.push("__reverse");
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")}>
      <>
        {loading && <ElementLoading className="base-page__loading" loading={loading} />}
        {prepend}
        <div className="base-page__inner">
          {side && <div className="base-page__side">{side}</div>}
          {children && <div className="base-page__content">{children}</div>}
        </div>
        {append}
      </>
    </div>
  );
}
