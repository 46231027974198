import React from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { MediaConstraints } from "types";
import MediaSettings from "./components/MediaSettings";
import TextChat from "./components/TextChat";
import UserList from "./components/UserList";
import "./style.scss";

export enum RightPanelView {
  HIDE_ALL,
  CHAT,
  USERS,
  SETTINGS,
}

interface Props {
  loading: boolean;
  token: string;
  threadId?: string;
  userList: LiveChatUser[];
  devices?: { audioInputs: MediaDeviceInfo[]; videoInputs: MediaDeviceInfo[] };
  onChangeConstraints?: (constraints: MediaConstraints) => void;
  constraints?: MediaConstraints | null;
  changeControls: (rightPanelView: RightPanelView) => void;
  showSection: RightPanelView;
  disableAttachments?: boolean;
  userListIcon?: string;
  onItemIconClick?: () => void;
  enableUserSearch?: boolean;
  modalChatView?: boolean;
  includeGuestsList?: boolean;
  allowGuestMessage?: boolean;
  me?: LiveChatUser;
}

const RightPanel = ({
  loading,
  token,
  threadId,
  userList,
  devices,
  onChangeConstraints,
  constraints,
  changeControls,
  showSection,
  disableAttachments,
  userListIcon,
  onItemIconClick,
  enableUserSearch,
  modalChatView,
  includeGuestsList,
  allowGuestMessage,
  me,
}: Props) => {
  const cls = ["right-panel"];
  if (showSection === RightPanelView.CHAT) cls.push("__chat-mode");

  return (
    <div className={cls.join(" ")}>
      <TextChat
        threadId={threadId}
        token={token}
        disableAttachments={disableAttachments}
        modalChatView={modalChatView}
        allowGuestMessage={allowGuestMessage}
        me={me}
        className={
          "right-panel__section __text-chat" +
          (showSection === RightPanelView.SETTINGS || showSection === RightPanelView.USERS ? " __hide" : "")
        }
        onClose={() => changeControls(RightPanelView.HIDE_ALL)}
      />
      {devices && (
        <MediaSettings
          loading={loading}
          audioInputs={devices.audioInputs}
          videoInputs={devices.videoInputs}
          className={"right-panel__section px-3 py-4" + (showSection === RightPanelView.SETTINGS ? "" : " __hide")}
          onChangeConstraints={onChangeConstraints}
          constraints={constraints}
          onClose={() => changeControls(RightPanelView.HIDE_ALL)}
        />
      )}
      {userList && (
        <UserList
          className={"right-panel__section" + (showSection === RightPanelView.USERS ? "" : " __hide")}
          userList={userList}
          userListIcon={userListIcon}
          enableSearch={enableUserSearch}
          includeGuests={includeGuestsList}
          onItemIconClick={onItemIconClick}
          onClose={() => changeControls(RightPanelView.HIDE_ALL)}
        />
      )}
    </div>
  );
};

export default RightPanel;
