import React from "react";
import BaseIcon from "components/BaseIcon";
import "./style.scss";

interface Props {
  className?: string;
  title?: string;
  src?: string;
  alt?: string;
  photoWidth?: number | string;
  photoHeight?: number | string;
}

function BasePhoto({ className, title = "", src, alt, photoWidth, photoHeight }: Props) {
  const cls = ["base-photo"];
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")} style={{ width: photoWidth, height: photoHeight }}>
      {src ? (
        <img className="base-photo__image" src={src} alt={alt} />
      ) : (
        <BaseIcon className={"base-photo__icon pt-1"}>photo</BaseIcon>
      )}
      {title && !src && <div className="base-photo__title mt-3">{title}</div>}
    </div>
  );
}

export default BasePhoto;
