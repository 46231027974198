import { useMemo } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import useAuthUser from "hooks/useAuthUser";
import useMessages from "hooks/useMessages";
import { isPerformer } from "lib/usersHelper";

const useViewController = (user: UserPublicData) => {
  const authUser = useAuthUser();
  const { getMessageUrl } = useMessages();

  const askLink = useMemo(() => getMessageUrl(user), [user, getMessageUrl]);
  const showBooking = useMemo(() => user.calendlyScheduleUrl && !isPerformer(authUser.user), [user, authUser]);

  return {
    askLink,
    showBooking,
  };
};

export default useViewController;
