import { useEffect, useState } from "react";
import useBaseForm from "hooks/useBaseForm";
import { parseErrors } from "lib/requestHelpers";
import { validateFieldsWithException } from "lib/validation";
import { FormValues } from "types";
import useController from "./controller";
import { questionnaireFields } from "./questionnaireFields";

function useViewController() {
  const [processing, setProcessing] = useState(false);
  const { fields, values, errors, setErrors, setValues } = useBaseForm(questionnaireFields);
  const onChange = (values: FormValues) => setValues(values);

  const { sendQuestionnaire, loading, data } = useController();

  useEffect(() => {
    setValues(data);
  }, [data, setValues]);

  const onSendQuestionnaire = async () => {
    try {
      validateFieldsWithException(fields, values);
      setProcessing(true);
      await sendQuestionnaire(values);
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      setProcessing(false);
    }
  };

  return {
    errors,
    setErrors,
    values,
    onChange,
    onSendQuestionnaire,
    processing,
    loading,
  };
}

export default useViewController;
