import React from "react";
import { PaymentSystemPublic } from "@scrile/api-provider/dist/api/PaymentSystemsProvider";
import { useTranslation } from "locales";
import BaseForm from "components/BaseForm";
import BaseSelect from "components/BaseSelect";
import useViewController from "./viewController";
import { submitPaymentCB } from "../../types";
import "./style.scss";

function CCBill({
  setOnSubmitPaymentCB,
  pricePackageId,
  currentSystem,
}: {
  setOnSubmitPaymentCB: (cb: submitPaymentCB) => void;
  pricePackageId: string;
  currentSystem: PaymentSystemPublic | null;
  isNewCard: boolean;
}) {
  const {
    fields,
    values,
    setValues,
    setErrors,
    errors,
    selectValue,
    setSelectValue,
    selectCardsOption,
    isNewCard,
    haveCardsSaved,
  } = useViewController(setOnSubmitPaymentCB, pricePackageId, currentSystem);
  const { t } = useTranslation();

  return (
    <>
      {haveCardsSaved && (
        <BaseSelect
          value={selectValue}
          placeholder={t("Choose")}
          onChange={setSelectValue}
          className="mb-4"
          options={selectCardsOption}
          name="cards"
        />
      )}
      {isNewCard && (
        <BaseForm fields={fields} values={values} onChange={setValues} setErrors={setErrors} errors={errors} />
      )}
    </>
  );
}

export default CCBill;
