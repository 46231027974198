import config from "config";

declare global {
  interface Window {
    gtag: (data: string, id: string) => void;
  }
}

const isGtagScriptExistsInHead = (): boolean => {
  const $scripts = document.head.querySelectorAll("script");
  return [...$scripts].some((item) => item.src.endsWith(config.gtagId));
};

export const appendGtagScriptToHead = () => {
  if (config.gtagId && !window.gtag && !isGtagScriptExistsInHead()) {
    const $scriptWithSrc = document.createElement("script");
    $scriptWithSrc.src = `https://www.googletagmanager.com/gtag/js?id=${config.gtagId}`;
    $scriptWithSrc.async = true;

    const $script = document.createElement("script");
    $script.textContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());        
      gtag("config", "${config.gtagId}");
    `;
    document.head.append($scriptWithSrc, $script);
  }
};
