import React, { useMemo } from "react";
import { PaymentPackage } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import { formatMoney } from "@scrile/tools/dist/lib/MoneyHelpers";
import { useTranslation } from "locales";
import useAppState from "hooks/useAppState";
import { formatMoneyToString } from "lib/moneyHelper";
import "./style.scss";

interface Props {
  className?: string;
  paymentPackage: PaymentPackage;
  onClick: () => void;
  active?: boolean;
}

function PaymentPackageCard({ className, paymentPackage, onClick, active }: Props) {
  const { t } = useTranslation();
  const { systemSettings } = useAppState();
  const cls = ["payment-package-card", "text-center"];

  if (className) cls.push(className);
  if (active) cls.push("__active");

  const price = useMemo(() => paymentPackage.costs.find((c) => c.currency === systemSettings?.mainCurrency), [
    paymentPackage,
    systemSettings,
  ]);
  const amountParts = formatMoney(paymentPackage.amount);

  return (
    <div className={cls.join(" ")} onClick={onClick}>
      <p className="payment-package-card__name">{t(paymentPackage.name)}</p>
      <div className="payment-package-card__amount-container">
        <h2 className="payment-package-card__amount headline-1 lh-flat">
          {amountParts.wholePart + amountParts.separator + amountParts.fractionalPart}
        </h2>
        <p className="payment-package-card__currency">{t("Credit", { count: paymentPackage.amount })}</p>
      </div>
      {price && <div className="payment-package-card__cost">{formatMoneyToString(price.cost, price.currency)}</div>}
    </div>
  );
}

export default PaymentPackageCard;
