import { t } from "locales";
import { NavigationMenuItemInterface } from "types";
import useController from "./controller";

const useViewController = () => {
  const { availability, isConsultant, processing } = useController();

  const topNavigationMenu: NavigationMenuItemInterface[] = [
    {
      title: t("Upcoming"),
      name: "upcoming",
      to: "/user/appointments/upcoming",
    },
    {
      title: t("Past"),
      name: "past",
      to: "/user/appointments/past",
    },
    ...(isConsultant
      ? [
          {
            title: t("Pricing"),
            name: "pricing",
            to: "/user/appointments/pricing",
          },
          {
            title: t("Availability"),
            name: "availability",
            to: "/user/appointments/availability",
          },
        ]
      : []),
  ];

  return { topNavigationMenu, availability, isConsultant, processing };
};

export default useViewController;
