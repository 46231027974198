import React, { useMemo } from "react";
import { Review } from "@scrile/api-provider/dist/api/ReviewProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { useTranslation } from "locales";
import LeaveReviewModal from "modals/LeaveReviewModal";
import BaseButton from "components/BaseButton";
import BaseCard from "components/BaseCard";
import BaseIcon from "components/BaseIcon";
import BaseSpinner from "components/BaseSpinner";
import InfiniteScroll from "components/InfiniteScroll";
import ReviewItem from "./components/ReviewItem";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  user: UserPublicData;
  loading: boolean;
  className?: string;
  items: Review[];
  enableScroll: boolean;
  getNextPage: (cb?: () => void, requestData?: any) => void;
  onReviewAdded: (review: Review) => void;
  totalRecords?: string;
}

export default function PerformerReviewsCard({
  user,
  loading,
  className,
  items,
  enableScroll,
  getNextPage,
  onReviewAdded,
  totalRecords,
}: Props) {
  const { t } = useTranslation();
  const { showReviewModal, onShowReviewModal, onCloseReviewModal, isHavePastAppointments } = useViewController(user);

  const cls = ["performer-reviews-card"];
  if (className) cls.push(className);

  const buttonContent = useMemo(
    () => (
      <>
        <BaseIcon className="mr-2">write</BaseIcon>
        {t("Leave a review")}
      </>
    ),
    [t]
  );

  return (
    <BaseCard className={cls.join(" ")}>
      <div className={"performer-reviews-card__header d-flex" + (isHavePastAppointments ? " __border-bottom" : "")}>
        <div className="d-flex __align-center">
          <h2 className="performer-reviews-card__heading headline-2">{t("Reviews")}</h2>
          <span className="performer-reviews-card__total-records body ml-1">{totalRecords}</span>
        </div>

        {isHavePastAppointments && (
          <BaseButton className="performer-reviews-card__review-btn" onClick={onShowReviewModal} borderless>
            {buttonContent}
          </BaseButton>
        )}
      </div>
      {loading ? (
        <div className="pa-8">
          <BaseSpinner className="ma-auto" />
        </div>
      ) : items.length > 0 ? (
        <InfiniteScroll enable={enableScroll} onNext={getNextPage}>
          {items.map((item) => {
            return <ReviewItem key={item.id} review={item} />;
          })}
        </InfiniteScroll>
      ) : (
        <div className="performer-reviews-card__empty-content body text-center">{t("There are no reviews yet")}</div>
      )}

      <LeaveReviewModal
        show={showReviewModal}
        onReviewAdded={onReviewAdded}
        onClose={onCloseReviewModal}
        recipientUser={user}
      />
    </BaseCard>
  );
}
