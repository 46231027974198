import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { I18n, useTranslation } from "locales";
import useAuthUser from "hooks/useAuthUser";
import emitter, { EVENTS } from "lib/emitter";
import providers from "lib/providers";
import { NotificationFactory } from "../../index";

function MessageManager({ sendNotification }: { sendNotification: NotificationFactory }) {
  const { user } = useAuthUser();
  const { t } = useTranslation();
  const userRef = useRef(user);
  const pathName = useRef("");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    userRef.current = user;
    pathName.current = location.pathname;
  }, [user, location]);

  useEffect(() => {
    let unsubscribe: () => void;
    if (!user) {
      return;
    }
    providers.MessagesProvider.subscribeToAllThreads((message) => {
      emitter.emit(EVENTS.MESSAGES_NEW_MESSAGE, message);
      const url = `/messages/${message.senderId}`;
      if (
        parseInt(message.senderId) < 1 ||
        !userRef.current ||
        message.senderId === userRef.current.id ||
        url === pathName.current
      ) {
        return;
      }
      sendNotification({
        text: (
          <I18n
            path={"You've got new message from <author>{{screenName}}</author>"}
            components={{
              author: <strong style={{ whiteSpace: "nowrap" }} />,
            }}
            values={{ screenName: message.senderUser?.screenName ?? "" }}
          />
        ),
        image: {
          icon: "messages",
        },
        onClick: () => history.push(url),
      });
    }).then((u) => (unsubscribe = () => u.unsubscribe()));
    return () => unsubscribe && unsubscribe();
  }, [history, sendNotification, t, user]);

  return null;
}

export default MessageManager;
