import React from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { t } from "locales";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import MessageInput from "components/MessageInput";
import MessagesListView from "components/MessagesListView";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  threadId: string | undefined;
  className?: string;
  token: string;
  disableAttachments?: boolean;
  users?: LiveChatUser[];
  modalChatView?: boolean;
  onClose?: () => void;
  me?: LiveChatUser;
  allowGuestMessage?: boolean;
}

function TextChat({
  threadId,
  className,
  token,
  disableAttachments,
  users = [],
  modalChatView,
  onClose,
  me,
  allowGuestMessage,
}: Props) {
  const {
    containerRef,
    messages,
    user,
    onFocus,
    desktopUp,
    mobileLandscape,
    userNames,
    guestUser,
    setGuestUser,
  } = useViewController(token, users);

  const cls = ["text-chat"];
  if (className) cls.push(className);
  if (mobileLandscape) cls.push("__landscape-mode");
  if (modalChatView) cls.push("__opaque");

  return (
    <div className={cls.join(" ")}>
      {modalChatView && (
        <div className="text-chat__header d-flex __align-center __space-between py-2 pl-4 pr-2">
          <h2 className="headline-2">{t("Chat")}</h2>
          <BaseButton className="text-chat__close" icon={true} opacity={true} onClick={onClose}>
            <BaseIcon>close</BaseIcon>
          </BaseButton>
        </div>
      )}
      <MessagesListView
        className="text-chat__message-list"
        avatarSize={30}
        messageItemClass="text-chat__message"
        containerRef={containerRef}
        messages={messages}
        infiniteScrollEnabled={false}
        showScreenName
        isLiveChat={!modalChatView}
        userNames={userNames}
        guestUser={guestUser}
      />
      {threadId && !mobileLandscape && (
        <MessageInput
          disableAttachments={!!disableAttachments || !user}
          threadIds={[threadId]}
          onFocus={onFocus}
          className="text-chat__message-input"
          transparentOnBlur={!desktopUp && !modalChatView}
          maxRows={!desktopUp ? 2 : undefined}
          allowGuestMessage={allowGuestMessage}
          guestUser={guestUser}
          me={me}
          setGuestUser={setGuestUser}
        />
      )}
    </div>
  );
}

export default TextChat;
