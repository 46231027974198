import React from "react";
import { BaseTabsProps } from "components/BaseTabs";
import "./style.scss";

type Props = Omit<BaseTabsProps, "gradual">;

function StepsMobile({ className, itemList, selected, onClick }: Props) {
  const cls = ["steps-mobile"];
  if (className) cls.push(className);

  const elements = [];
  for (let i = 0; i < itemList.length; i++) {
    const itemCls = ["steps-mobile__item"];
    const selectedIndex = itemList.findIndex((i) => i.name === selected?.name);
    if (i === selectedIndex) itemCls.push("__active");
    const canClick = i < selectedIndex;
    if (canClick) itemCls.push("__passed");
    elements.push(
      <div className={itemCls.join(" ")} onClick={() => canClick && onClick && onClick(itemList[i])} key={i}>
        {i + 1}
      </div>
    );
    if (i !== itemList.length - 1) elements.push(<div className="steps-mobile__separator" key={`${i}_s`} />);
  }

  return <div className={cls.join(" ")}>{elements}</div>;
}

export default StepsMobile;
