import { useEffect, useState } from "react";
import providers from "lib/providers";
import useAuthUser from "./useAuthUser";

export default function useAppointments() {
  const [processing, setProcessing] = useState(true);
  const [availability, setAvailability] = useState(false);
  const { user } = useAuthUser();

  useEffect(() => {
    if (!user) return;
    try {
      providers.CalendlyProvider.assignmentSearch({ userIds: [user.id] }).then((res) => {
        if (res.length > 0) {
          setAvailability(true);
        }
      });
    } finally {
      setProcessing(false);
    }
  }, [user]);

  return { processing, availability };
}
