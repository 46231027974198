import { useState } from "react";
import { LOCAL_STORAGE_NAMES } from "const";

export default function useLiveChatName(screenName?: string) {
  const [liveChatName, setLiveChatName] = useState(() => {
    const storageName = localStorage.getItem(LOCAL_STORAGE_NAMES.LIVE_CHAT_NAME);
    return storageName || screenName || "";
  });

  const updateLiveChatName = (value: string) => {
    setLiveChatName(value);
    localStorage.setItem(LOCAL_STORAGE_NAMES.LIVE_CHAT_NAME, value);
  };

  return {
    liveChatName,
    updateLiveChatName,
  };
}
