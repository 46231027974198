export enum HISTORY_ACTIONS {
  POP = "POP",
  PUSH = "PUSH",
  REPLACE = "REPLACE",
}

export enum LOCAL_STORAGE_NAMES {
  LIVE_CHAT_NAME = "liveChatName",
  MEDIA_CONSTRAINTS = "mediaConstraints",
}
