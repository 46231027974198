import React from "react";
import BaseIcon from "components/BaseIcon";
import "./style.scss";

interface Props {
  children: string;
}

function BaseTooltip({ children }: Props) {
  return (
    <div title={children} className="base-tooltip">
      <div className="base-tooltip__activator">
        <BaseIcon className="base-tooltip__icon">info</BaseIcon>
      </div>
    </div>
  );
}

export default BaseTooltip;
