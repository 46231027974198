import React, { useRef } from "react";
import { User } from "@scrile/api-provider/dist/api/UserProvider";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { t } from "locales";
import BaseIcon from "components/BaseIcon";
import BaseInput from "components/BaseInput";
import ContentHeader from "components/ContentHeader";
import useController from "./controller";
import "./style.scss";

interface Props {
  className?: string;
  produce?: boolean;
  produceUser: User | UserPublicData;
  customCaption?: string;
  customText?: string;
}

const LiveChatShare = ({ className, produce, produceUser, customCaption, customText }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const { onFocusInput, onCopyText, successfulCopy, chatLink, mailSubject, mailText } = useController(produceUser);
  const cls = ["share"];
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")}>
      <ContentHeader className="share__heading">
        {customCaption ? customCaption : t(`Share ${produce ? "your" : ""} Live Chat`)}
      </ContentHeader>
      <p className="share__text text-center body caption">
        {customText
          ? customText
          : produce
          ? t("Copy link and send it to people you want to chat with.")
          : t("Copy link and share the Live chat with your friends.")}
      </p>
      <div onFocus={() => onFocusInput(ref)}>
        <BaseInput
          refInput={ref}
          readOnly={true}
          iconRight={"copy"}
          iconRightTooltip={t("Copy to clipboard")}
          className={"share__input mb-4" + (successfulCopy ? " __success" : "")}
          value={chatLink}
          onClickIconRight={(e) => onCopyText(e, ref)}
          type={"text"}
          name={"chatLink"}
          onChange={() => {
            return;
          }}
        />
      </div>
      <div className="d-center">
        {
          // @ts-ignore
          navigator.share ? (
            <span
              className="link"
              // @ts-ignore
              onClick={() => navigator.share({ title: mailSubject, text: mailText })}
            >
              <BaseIcon className="mr-1">fas-share</BaseIcon>
              {t("Share")}
            </span>
          ) : (
            <a href={`mailto:?subject=${mailSubject}&body=${mailText}`} target="_blank" rel="noopener noreferrer">
              <BaseIcon className="mr-1">mail</BaseIcon>
              {t("Send via email")}
            </a>
          )
        }
      </div>
    </div>
  );
};

export default LiveChatShare;
