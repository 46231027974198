import React, { useState } from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import useBreakPoints from "hooks/useBreakPoints";
import { useUserFirstDocInteract } from "hooks/useUserFirstDocInteract";
import { ConferenceUser, MediaConstraints } from "types";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import ChatTimer from "components/ChatTimer";
import DotFlashingAnimation from "components/DotFlashingAnimation";
import StreamView from "components/StreamView";
import VideoControlButton from "components/VideoControlButton";
import ConferenceShareModal from "../ConferenceShareModal";
import ConferenceStreamView from "../ConferenceStreamView";
import "./style.scss";

interface Props {
  threadId: string | undefined;
  conferenceId: string;
  loading: boolean;
  userList: LiveChatUser[];
  token: string;
  showConfirmationExit: boolean;
  audioInputs: MediaDeviceInfo[];
  videoInputs: MediaDeviceInfo[];
  users: ConferenceUser[];
  me: ConferenceUser | null;
  managedConstraints: MediaConstraints | null;
  streamStartedDate: string;
  onChangeConstraints: (constraints: MediaConstraints) => void;
  onCloseConference: () => void;
  onCloseStream: () => void | Promise<void>;
  onProduceFinishedStream: () => Promise<void>;
}

export default function ConferenceView({ users, me, ...props }: Props) {
  const [selectedUserId, setSelectedUserId] = React.useState<string | null>(null);
  const [showShare, setShowShare] = useState(false);

  const onSelectUser = (userId: string | null) => {
    setSelectedUserId(userId);
  };

  const onClose = async () => {
    await props.onCloseStream();
    props.onCloseConference();
  };

  const { desktopUp } = useBreakPoints();
  const { showStreamPlayButton } = useUserFirstDocInteract();
  const disabledVideo = users.length < 1;
  const slotVideoScreen = (() => {
    const arr: JSX.Element[] = [];
    if (users.length < 1) {
      arr.push(<DotFlashingAnimation key="dot-animation" className="conference-view__dot-animation" />);
    } else {
      arr.push(
        <ConferenceStreamView
          users={users}
          me={me}
          key="stream-view"
          selectedUserId={selectedUserId}
          onSelectUser={onSelectUser}
        />
      );
    }
    return <>{arr.map((el) => el)}</>;
  })();

  const slotHeaderButtons = (() => {
    return (
      <>
        <BaseButton className="stream-view__header-btn __share" name={"share"} onClick={() => setShowShare(true)}>
          <BaseIcon className="stream-view__header-icon">share-outline</BaseIcon>
        </BaseButton>
      </>
    );
  })();

  const slotControlButtons = (() => {
    const cls = ["panel-control-buttons__btn", "conference-view__grid"];
    const clsVoid = ["conference-view__void"];
    if (!selectedUserId) {
      cls.push("hidden");
      clsVoid.push("hidden");
    }
    return (
      <>
        <div className={clsVoid.join(" ")}></div>
        <VideoControlButton
          className={cls.join(" ")}
          name="toggle-grid"
          toggle={!!selectedUserId}
          borderless
          onClick={() => setSelectedUserId(null)}
          disabled={props.loading}
        >
          <BaseIcon>grid</BaseIcon>
        </VideoControlButton>
      </>
    );
  })();

  return (
    <>
      <ConferenceShareModal show={showShare} conferenceId={props.conferenceId} onClose={() => setShowShare(false)} />

      <StreamView
        {...props}
        onClose={onClose}
        className="conference-view"
        produce
        consume
        isProducerUser
        stream={new MediaStream([])}
        managedStream={null}
        produceUser={null}
        consumeUser={null}
        constraints={null}
        disabledVideo={disabledVideo}
        hideVideoElement
        fullLeftSide
        forceShowStreamPlayButton={showStreamPlayButton}
        enableUserSearch
        rightPanelModalView
        allowGuestMessage
        me={me?.user}
        slotControlButtons={desktopUp ? slotControlButtons : undefined}
        slotAdditionalControlButtons={desktopUp ? undefined : slotControlButtons}
        slotHeaderButtons={slotHeaderButtons}
        includeGuestsList
        slotTimer={
          <ChatTimer showTimeIfNegative streamStartedDate={props.streamStartedDate}>
            {"Live chat"}
          </ChatTimer>
        }
        slotVideoScreen={slotVideoScreen}
      />
    </>
  );
}
