import { useEffect, useState } from "react";
import {
  InputPersonalInformation,
  PersonalInformation,
} from "@scrile/api-provider/dist/api/PersonalInformationProvider";
import useAuthUser from "hooks/useAuthUser";
import providers from "lib/providers";
import { FormValues } from "types";

export default function useController() {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<PersonalInformation | null>(null);
  const { user } = useAuthUser();

  const sendData = async (values: FormValues) => {
    if (!user) return;
    const data: InputPersonalInformation = {
      userId: user.id,
      address: values["address"],
      firstName: values["firstName"],
      lastName: values["lastName"],
      phone: values["phone"],
      zip: values["zip"],
      country: values["country"],
      city: values["city"],
      state: values["state"],
    };
    const result = await providers.PersonalInformationProvider.update({ data });
    setInfo(result);
  };

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    providers.PersonalInformationProvider.find({ id: user.id })
      .then((r) => {
        setInfo(r);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    info,
    sendData,
  };
}
