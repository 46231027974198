import { InputFile } from "@scrile/api-provider/dist/api/FileProvider";
import { TagInfo, TagInfoType } from "@scrile/api-provider/dist/api/TagsProvider";
import { FragmentFields } from "@scrile/api-provider/dist/api/types";
import { InputUserUpdate } from "@scrile/api-provider/dist/api/UserProvider";
import { QuestionaryInfo } from "@scrile/api-provider/dist/projects/webvideo/QuestionaryInfoProvider";
import { getFileMimeType } from "@scrile/tools/dist/lib/FileHelpers";
import useAuthUser from "hooks/useAuthUser";
import { getLoginData, setLoginData } from "lib/authHelper";
import fileUploader from "lib/fileUploader";
import providers from "lib/providers";
import { validateFieldsWithException } from "lib/validation";
import { FieldInterface, FormValues } from "types";
import { questionnaireFields } from "./questionnaireConfig";

const performerSearchFields: FragmentFields<QuestionaryInfo> = ["fields", { tags: ["id", "slug", "title"] }];

function useController() {
  const { user } = useAuthUser();
  const loginData = getLoginData();

  async function getUserQuestionnaire(): Promise<QuestionaryInfo> {
    if (!user) throw new Error("");
    return await providers.QuestionaryInfoProvider.find({ id: user.id }, performerSearchFields);
  }
  async function getAllTags() {
    return await providers.TagsProvider.findAll({ data: { type: TagInfoType.USERS } });
  }
  async function onSaveFields(fields: FieldInterface[], values: FormValues, tags: TagInfo[]) {
    validateFieldsWithException(fields, values);

    const questionnaireInfo: QuestionaryInfo = {
      fields: {
        userId: user?.id || "",
      },
    };

    questionnaireFields.forEach((item) => {
      if (!item.role.includes(user?.role || "")) {
        return;
      }
      const name = item.field.name;
      if (name === "website" && values[name]) {
        let website: string = values.website;
        website = website.match(/^http(s)?:\/\//) ? website : "http://" + website;
        questionnaireInfo.fields[name] = website;
      } else {
        questionnaireInfo.fields[name] = values[name];
      }
    });

    questionnaireInfo.fields["tags"] = tags.map((tag) => tag.id);

    await providers.QuestionaryInfoProvider.update({
      data: questionnaireInfo,
    });

    const data: InputUserUpdate = {
      id: user?.id || "",
      screenName: values.screenName,
    };
    const userResponse = await providers.UserProvider.update({ data });
    setLoginData({ user: userResponse, authorities: loginData?.authorities || null });
  }

  async function onUploadAvatar(file: File) {
    if (!user) return;
    const response = await providers.FileProvider.getUploadUrl({
      fileIds: [{ subjectId: user.id, type: "avatar" }],
    });
    fileUploader.addToQueue([
      {
        file,
        fileType: getFileMimeType(file),
        urlPart: response[0].fileUpload.urlPart,
        id: `${response[0].fileId.subjectId}_${response[0].fileUpload.uuid}`,
        onSuccess: async () => {
          const data: InputFile = {
            fileId: response[0].fileId,
            uuid: response[0].fileUpload.uuid,
          };
          const avatar = await providers.FileProvider.update({ data });
          setLoginData({ user: { ...user, avatar: avatar }, authorities: loginData?.authorities || null });
        },
      },
    ]);
  }

  const onDeleteAvatar = async () => {
    if (!user) return;
    const isDeleted = await providers.FileProvider.delete({ data: { subjectId: user.id, type: "avatar" } });
    if (isDeleted) setLoginData({ user: { ...user, avatar: null }, authorities: loginData?.authorities || null });
  };

  return { onSaveFields, onUploadAvatar, onDeleteAvatar, getUserQuestionnaire, getAllTags };
}

export default useController;
