import React, { useRef } from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import CustomScrollBar from "components/CustomScrollBar";
import InfiniteScroll from "components/InfiniteScroll";
import useController from "./controller";
import SearchByName from "../SearchByName";
import UserListItem from "../UserListItem";
import "./style.scss";

export interface Props {
  className?: string;
  userList: LiveChatUser[];
  onClose?: () => void;
  userListIcon?: string;
  onItemIconClick?: () => void;
  enableSearch?: boolean;
  includeGuests?: boolean;
}

const UserList = ({
  className,
  userList,
  onClose,
  userListIcon,
  onItemIconClick,
  enableSearch,
  includeGuests,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const cls = ["users-list"];
  if (className) cls.push(className);

  const { guestCount, filteredUserList, setUserSearchTerm } = useController({ userList, includeGuests });

  return (
    <div className={cls.join(" ")}>
      <div className="users-list__header">
        <h2 className="body fw-semibold">{t("Member list")}</h2>

        <span className="users-list__guests small">({t("guests {{count}}", { count: guestCount })})</span>
        <BaseButton className="users-list__close" icon opacity onClick={onClose}>
          <BaseIcon>close</BaseIcon>
        </BaseButton>
      </div>
      {enableSearch && (
        <div className="users-list__search">
          <SearchByName onChange={setUserSearchTerm} />
        </div>
      )}
      <CustomScrollBar className="users-list__list" containerRef={containerRef}>
        <InfiniteScroll enable scrollableWrapperRef={containerRef} className="users-list__list-container">
          {filteredUserList.map((user: LiveChatUser) => (
            <UserListItem key={user.id} user={user} appendIcon={userListIcon} onAppendIconClick={onItemIconClick} />
          ))}
        </InfiniteScroll>
      </CustomScrollBar>
    </div>
  );
};

export default UserList;
