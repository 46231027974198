import React from "react";
import { useTranslation } from "locales";
import ActionStatus from "components/ActionStatus";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import BaseSpinner from "components/BaseSpinner";
import ContentHeader from "components/ContentHeader";
import ModalWindow from "components/ModalWindow";
import PaymentForm from "./components/PaymentForm";
import PaymentPackageList from "./components/PaymentPackageList";
import useController, { STEPS } from "./controller";
import "./style.scss";

function ModalBalanceRefill() {
  const { t } = useTranslation();
  const {
    show,
    packages,
    loading,
    loadingButton,
    onClose,
    onClickPackage,
    currentPackage,
    step,
    stepForward,
    stepBack,
    setOnSubmitPaymentCB,
  } = useController();

  let headingContent: React.ReactNode;
  let refillButtonText = "Refill";
  if (step === STEPS.PAYMENT_PACKAGES_LIST) {
    headingContent = (
      <div className="refill-account__header-container">
        <ContentHeader className="refill-account__header">{t("Refill Account")}</ContentHeader>
      </div>
    );
  } else if (step === STEPS.REFILL_BALANCE) {
    refillButtonText = "Refill now";
    headingContent = headingContent = (
      <div className="refill-account__header-container d-flex">
        <BaseButton
          className="refill-account__back-button"
          disabled={loadingButton}
          opacity={true}
          icon={true}
          onClick={stepBack}
        >
          <BaseIcon size={15}>fas-angle-left</BaseIcon>
        </BaseButton>

        <ContentHeader className="refill-account__header __center d-center">
          {t(currentPackage?.name ?? "")}
        </ContentHeader>
      </div>
    );
  } else {
    headingContent = null;
  }

  return (
    <ModalWindow
      className="refill-account"
      show={show}
      onClose={onClose}
      modal={true}
      mobileFullScreen={step !== STEPS.SUCCESS_MESSAGE}
    >
      {headingContent}
      {loading ? (
        <BaseSpinner className="refill-account__loading" />
      ) : (
        <>
          {step === STEPS.PAYMENT_PACKAGES_LIST && (
            <PaymentPackageList packages={packages} currentPackage={currentPackage} onClick={onClickPackage} />
          )}
          {step === STEPS.REFILL_BALANCE && (
            <PaymentForm paymentPackage={currentPackage} setOnSubmitPaymentCB={setOnSubmitPaymentCB} />
          )}
          {step === STEPS.SUCCESS_MESSAGE && (
            <ActionStatus
              className="mb-6"
              status={"success"}
              title={t("Success")}
              description={t("Your account has been refilled")}
            />
          )}
        </>
      )}
      {!loading && step !== STEPS.SUCCESS_MESSAGE ? (
        <div
          className={
            "refill-account__buttons" + (step > STEPS.PAYMENT_PACKAGES_LIST ? " __wide-buttons __unfixed-buttons" : "")
          }
        >
          <BaseButton
            disabled={loadingButton}
            className="refill-account__button"
            onClick={onClose}
            outline={true}
            large={true}
          >
            {t("Cancel")}
          </BaseButton>
          <BaseButton
            className="refill-account__button"
            disabled={!currentPackage}
            onClick={stepForward}
            large={true}
            loading={loadingButton}
            name="refill"
          >
            {t(refillButtonText)}
          </BaseButton>
        </div>
      ) : STEPS.SUCCESS_MESSAGE ? (
        <BaseButton className="mx-auto" name="success" large={true} onClick={onClose}>
          {t("Awesome")}
        </BaseButton>
      ) : null}
    </ModalWindow>
  );
}

export default ModalBalanceRefill;
