import React, { createContext, Reducer, useContext, useReducer } from "react";
import { UserPublicDataSearchResponse } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { InputPerformerListSearch } from "@scrile/api-provider/dist/projects/webvideo/PerformerListProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";

export interface PerformersState {
  filtersDTO: InputPerformerListSearch;
  performers: UserPublicDataSearchResponse<UserPublicData> | null;
  userFavoriteIds: string[];
  prevOnlyFollowings: boolean | undefined;
}

const defaultState: PerformersState = {
  filtersDTO: { sort: ["ID,DESC"], page: 0 },
  performers: null,
  userFavoriteIds: [],
  prevOnlyFollowings: false,
};

type Actions =
  | {
      type: "setFilters";
      payload: InputPerformerListSearch;
    }
  | {
      type: "setPerformers";
      payload: UserPublicDataSearchResponse<UserPublicData> | null;
    }
  | {
      type: "setUserFavoriteIds";
      payload: string[];
    }
  | {
      type: "setPrevOnlyFollowings";
      payload: boolean | undefined;
    };

const reducer: Reducer<PerformersState, Actions> = (state, action) => {
  switch (action.type) {
    case "setFilters":
      return { ...state, filtersDTO: action.payload };
    case "setPerformers":
      return { ...state, performers: action.payload };
    case "setUserFavoriteIds":
      return { ...state, userFavoriteIds: action.payload };
    case "setPrevOnlyFollowings":
      return { ...state, prevOnlyFollowings: action.payload };
  }
};
const PerformersContext = createContext<[PerformersState, React.Dispatch<Actions>]>([defaultState, () => {}]);

export function PerformersContextProvider(props: React.PropsWithChildren<Record<string, any>>) {
  const [state, dispatch] = useReducer(reducer, defaultState);

  return React.createElement(PerformersContext.Provider, { value: [state, dispatch] }, props.children);
}

export default function usePerformers() {
  const [{ filtersDTO, performers, userFavoriteIds, prevOnlyFollowings }, dispatch] = useContext(PerformersContext);

  const setFiltersDTO = (data: InputPerformerListSearch) => {
    dispatch({ type: "setFilters", payload: data });
  };
  const setPerformers = (data: UserPublicDataSearchResponse<UserPublicData> | null) => {
    dispatch({ type: "setPerformers", payload: data });
  };
  const setUserFavoriteIds = (data: string[]) => {
    dispatch({ type: "setUserFavoriteIds", payload: data });
  };
  const setPrevOnlyFollowings = (data: boolean | undefined) => {
    dispatch({ type: "setPrevOnlyFollowings", payload: data });
  };

  return {
    filtersDTO,
    performers,
    userFavoriteIds,
    prevOnlyFollowings,
    setFiltersDTO,
    setPerformers,
    setUserFavoriteIds,
    setPrevOnlyFollowings,
  };
}
