import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import BaseCard from "components/BaseCard";
import AppointmentsAvailability from "./components/AppointmentsAvailability";
import AppointmentsPricing from "./components/AppointmentsPricing";
import AppointmentsRange from "./components/AppointmentsRange";
import useViewController from "./viewController";
import NavigationTabs from "../NavigationTabs";
import "./style.scss";

const AppointmentsPage = () => {
  const match = useRouteMatch();
  const { topNavigationMenu, processing, availability, isConsultant } = useViewController();

  return (
    <BaseCard className="appointments-page">
      <NavigationTabs itemsList={topNavigationMenu} />
      <div className="appointments-page__wrapper">
        {!processing && (
          <Switch>
            <Route path={`${match.url}/upcoming`}>
              <AppointmentsRange startTimeFrom={new Date().toISOString()} />
            </Route>
            <Route path={`${match.url}/past`}>
              <AppointmentsRange endTimeTo={new Date().toISOString()} hideStatus={true} sort="START_TIME,DESC" />
            </Route>
            {isConsultant && (
              <Route path={`${match.url}/availability`}>
                <AppointmentsAvailability />
              </Route>
            )}
            {isConsultant && (
              <Route path={`${match.url}/pricing`}>
                <AppointmentsPricing />
              </Route>
            )}

            <Route path={`*`}>
              <Redirect to={`${match.url}${availability || !isConsultant ? "/upcoming" : "/availability"}`} />
            </Route>
          </Switch>
        )}
      </div>
    </BaseCard>
  );
};

export default AppointmentsPage;
