import React, { CSSProperties, useMemo } from "react";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import ElementLoading from "components/ElementLoading";
import UploadMedia from "components/UploadMedia";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  onNext: () => Promise<void>;
  onPrev: () => void;
  className?: string;
  style?: CSSProperties;
}

export default function PhotoId({ onNext, onPrev, className, style }: Props) {
  const { t } = useTranslation();
  const { idPhoto, photoWithId, loading, onSendFile } = useViewController();
  const cls = ["performer-verification-documents"];
  className && cls.push(className);

  const buttonContentPrev = useMemo(
    () => (
      <>
        <span className="performer-verification__desktop-only">{t("Previous Step")}</span>
        <BaseIcon className="performer-verification__mobile-only" size={14}>
          back
        </BaseIcon>
      </>
    ),
    [t]
  );
  const buttonContentNext = useMemo(
    () => (
      <>
        <span className="performer-verification__desktop-only">{t("Next step")}</span>
        <span className="performer-verification__mobile-only">{t("Next")}</span>
      </>
    ),
    [t]
  );
  return (
    <div className={cls.join(" ")} style={style}>
      <div className="performer-verification-documents__photo-list">
        <UploadMedia
          name={"id_photo"}
          aspectRatio={0.818}
          className="performer-verification-documents__photo-list-item"
          src={idPhoto}
          title={t("Photo scan of ID")}
          onInput={(f) => onSendFile(f, "id_photo")}
        />
        <UploadMedia
          name={"photo_with_id"}
          aspectRatio={0.818}
          className="performer-verification-documents__photo-list-item"
          title={t("Photo with ID")}
          src={photoWithId}
          onInput={(f) => onSendFile(f, "photo_with_id")}
        />
      </div>
      <div className="d-flex mt-8">
        <BaseButton large outline onClick={onPrev}>
          {buttonContentPrev}
        </BaseButton>
        <BaseButton className="ml-auto" large onClick={onNext}>
          {buttonContentNext}
        </BaseButton>
      </div>
      <ElementLoading loading={loading} />
    </div>
  );
}
