import { useMemo, useState } from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";

interface Props {
  userList: LiveChatUser[];
  includeGuests?: boolean;
}

const useController = ({ userList, includeGuests }: Props) => {
  const guests = useMemo(() => userList.filter((u: LiveChatUser) => u.user === null), [userList]);
  const users = useMemo(() => userList.filter((u: LiveChatUser) => u.user), [userList]);

  const [userSearchTerm, setUserSearchTerm] = useState("");
  const filteredUserList = useMemo(() => {
    return (includeGuests ? userList : users).filter(
      (user) =>
        user.screenName?.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
        user.user?.screenName.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
        false
    );
  }, [includeGuests, userList, userSearchTerm, users]);

  return {
    guestCount: guests.length,
    filteredUserList,
    setUserSearchTerm,
  };
};

export default useController;
