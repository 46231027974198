import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "locales";
import useModalLinkPrinter from "hooks/useModalLinkPrinter";
import BaseButton from "components/BaseButton";
import ConferenceShare from "components/ConferenceShare";
import ModalWindow from "components/ModalWindow";
import "./style.scss";

function ConferenceModal({
  show = false,
  onClose,
  conferenceId,
}: {
  show?: boolean;
  onClose: () => void;
  conferenceId: string;
}) {
  const { getLocation } = useModalLinkPrinter();
  const history = useHistory();
  const { t } = useTranslation();

  const onClickStart = () => {
    history.push(getLocation(`/conference/join/${conferenceId}`));
    onClose();
  };

  return (
    <ModalWindow show={show} onClose={onClose} className={"conference-modal"}>
      <ConferenceShare
        conferenceId={conferenceId}
        customCaption={t("Share link")}
        customText={t("By clicking Start you will start a new conference.")}
      />
      <div className="conference-modal__buttons">
        <BaseButton className="conference-modal__btn" outline large onClick={onClose}>
          {t("Close")}
        </BaseButton>
        <BaseButton className="conference-modal__btn" large onClick={onClickStart}>
          {t("Start")}
        </BaseButton>
      </div>
    </ModalWindow>
  );
}

export default ConferenceModal;
