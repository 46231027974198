import React from "react";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import useAuthUser from "hooks/useAuthUser";
import BasePage from "components/BasePage";
import PageLoading from "components/ElementLoading";
import ConsumeController from "./components/ConsumeController";
import ProduceController from "./components/ProduceController";
import useController from "./controller";
import "./style.scss";

interface Props {
  username: string;
}

function PageLiveChat({ username }: Props) {
  const { user, onClose } = useController(username);
  const { user: authUser } = useAuthUser();

  if (!user) return <PageLoading loading={!user} />;

  const produce = authUser && user.id === authUser.id;

  return (
    <BasePage className="page-live-chat">
      {produce ? (
        <ProduceController
          key={user.id}
          produceUser={user}
          livechatSubject={{ subjectId: user.id, subjectType: LivechatSubjectType.PUBLIC_LIVECHAT }}
          onClose={onClose}
        />
      ) : (
        <ConsumeController
          key={user.id}
          produceUser={user}
          livechatSubject={{ subjectId: user.id, subjectType: LivechatSubjectType.PUBLIC_LIVECHAT }}
          onClose={onClose}
          disableAttachments={true}
        />
      )}
    </BasePage>
  );
}

export default PageLiveChat;
