import config from "../../../../config";
import useAuthUser from "../../../../hooks/useAuthUser";
import { NavigationMenuItemInterface } from "../../../../types";

function useViewController() {
  const { user } = useAuthUser();

  const topNavigationMenu: NavigationMenuItemInterface[] = [
    {
      title: "About information",
      name: "about",
      to: "/user/settings",
    },
    {
      title: "Questionnaire",
      name: "questionnaire",
      to: "/user/settings/questionnaire",
    },
    {
      title: "Video",
      name: "video",
      to: "/user/settings/video",
    },
  ];

  return { topNavigationMenu, showNavMenu: user?.role === config.userPerformerRole };
}

export default useViewController;
