import { useHistory } from "react-router-dom";
import useModalLinkPrinter from "hooks/useModalLinkPrinter";

export default function useController() {
  const history = useHistory();
  const { getLocation } = useModalLinkPrinter();

  const onResetStart = (data: { email: string; status: boolean }) => {
    history.push(getLocation(`/login/forgot/${data.email}`));
  };

  const onCodeVerified = (data: { email: string; token: string }) => {
    history.push(getLocation(`/login/forgot/${data.email}/${data.token}`));
  };

  return {
    onResetStart,
    onCodeVerified,
  };
}
