import useAppointments from "hooks/useAppointments";
import useAuthUser from "hooks/useAuthUser";
import { isPerformer } from "lib/usersHelper";

const useController = () => {
  const { user } = useAuthUser();
  const { processing, availability } = useAppointments();

  const isConsultant = isPerformer(user);

  return { processing, availability, isConsultant };
};

export default useController;
