import React from "react";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import BaseCard from "components/BaseCard";
import useViewController from "./viewController";
import ProfileSettingsPage from "../../../ProfileSettingsPage";
import NavigationTabs from "../NavigationTabs";
import ProfileVideoPage from "../ProfileVideoPage";
import QuestionnairePage from "../QuestionnairePage";
import "./style.scss";

function MainInfoProfile() {
  const match = useRouteMatch();
  const { topNavigationMenu, showNavMenu } = useViewController();

  return (
    <BaseCard className="main-info-profile">
      <div className="main-info-profile__inner">
        {showNavMenu && <NavigationTabs itemsList={topNavigationMenu} />}
        <Switch>
          <Route exact path={`${match.url}`}>
            <ProfileSettingsPage />
          </Route>
          {showNavMenu && (
            <Route path={`${match.url}/questionnaire`}>
              <QuestionnairePage />
            </Route>
          )}
          {showNavMenu && (
            <Route path={`${match.url}/video`}>
              <ProfileVideoPage />
            </Route>
          )}
          <Route path={`*`}>
            <Redirect to={`${match.url}`} />
          </Route>
        </Switch>
      </div>
    </BaseCard>
  );
}

export default MainInfoProfile;
