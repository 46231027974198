import React, { PropsWithChildren } from "react";
import BaseIcon from "components/BaseIcon";
import ContentHeader from "components/ContentHeader";
import "./style.scss";

interface Props {
  title?: string;
  description?: React.ReactNode;
  status: "pending" | "success" | "failed";
  className?: string;
}

export default function ActionStatus({ status, children, title, description, className }: PropsWithChildren<Props>) {
  const iconName = status === "success" ? "done" : status === "pending" ? "watch" : "close";
  const cls = ["action-result"];
  if (status === "failed") cls.push("__failed");
  if (status === "pending") cls.push("__pending");
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")}>
      <div className="action-result__icon-container">
        <BaseIcon className="action-result__icon">{iconName}</BaseIcon>
      </div>
      {title && <ContentHeader className="mb-4">{title}</ContentHeader>}
      {description && <p className="body text-center mt-4">{description}</p>}
      {children}
    </div>
  );
}
