import { useCallback, useMemo, useState } from "react";
import {
  AppointmentSearchResult,
  InputAppointmentSearch,
} from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import useAuthUser from "hooks/useAuthUser";
import providers from "lib/providers";
import { isPerformer } from "lib/usersHelper";

export interface AppointmentsTimeRange {
  startTimeFrom?: string;
  startTimeTo?: string;
  endTimeFrom?: string;
  endTimeTo?: string;
  sort?: string;
}

export default function useController({
  startTimeFrom,
  startTimeTo,
  endTimeFrom,
  endTimeTo,
  sort,
}: AppointmentsTimeRange) {
  const { user } = useAuthUser();
  const isConsultant = useMemo(() => isPerformer(user), [user]);

  const [processing, setProcessing] = useState(true);
  const [appointmentSearchResult, setAppointmentSearchResult] = useState<AppointmentSearchResult>();

  const onPageChanged = useCallback(
    async (page: number) => {
      setProcessing(true);
      const data: InputAppointmentSearch = {
        ...(isConsultant ? { providerId: user?.id } : { consumerId: user?.id }),
        page,
        size: 6,
        startTimeFrom,
        startTimeTo,
        endTimeFrom,
        endTimeTo,
        sort,
      };

      try {
        const response = await providers.AppointmentProvider.search(data);
        setAppointmentSearchResult(response);
      } finally {
        setProcessing(false);
      }
    },
    [user, startTimeFrom, startTimeTo, endTimeFrom, endTimeTo, sort, isConsultant]
  );

  return {
    appointments: appointmentSearchResult?.result || [],
    totalPages: appointmentSearchResult?.totalPages || 0,
    processing,
    onPageChanged,
    isConsultant,
  };
}
