import { useState } from "react";
import { Appointment } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import { differenceInMilliseconds } from "@scrile/tools/dist/lib/TimeHelpers";
import useAppState from "hooks/useAppState";
import { getErrorMessage } from "lib/requestHelpers";
import { get12formatTime, getDate } from "lib/timeHelper";
import useController from "./controller";

export default function useViewController(
  appointment: Appointment | null,
  onClose: () => void,
  onAppointmentPaidSuccess?: () => void
) {
  const date = appointment ? getDate(appointment.startTime) : null;
  const time = appointment ? get12formatTime(appointment.startTime) : "";
  const min = appointment
    ? differenceInMilliseconds(new Date(appointment.endTime), new Date(appointment.startTime)) / 1000 / 60
    : "";

  const [paidProcessing, setPaidProcessing] = useState(false);
  const [showSuccessStatus, setShowSuccessStatus] = useState(false);
  const [error, setError] = useState("");

  const { payForAppointment, onRefillBalance } = useController();
  const { balance } = useAppState();

  const errorMessage = error
    ? error
    : appointment && appointment.cost > balance
    ? "Insufficient funds to pay for the appointment"
    : "";

  const onPayForAppointment = async () => {
    try {
      setError("");
      setPaidProcessing(true);
      await payForAppointment({ id: appointment?.id || "" });
      setShowSuccessStatus(true);
      if (onAppointmentPaidSuccess) onAppointmentPaidSuccess();
    } catch (e) {
      setError(getErrorMessage(e[0]));
    } finally {
      setPaidProcessing(false);
    }
  };

  const onCloseModal = () => {
    setShowSuccessStatus(false);
    onClose();
  };

  return {
    date,
    time,
    min,
    errorMessage,
    balance,
    paidProcessing,
    showSuccessStatus,
    onRefillBalance,
    onPayForAppointment,
    onCloseModal,
  };
}
