import React, { useState } from "react";
import "./style.scss";
import { I18n, useTranslation } from "../../locales";

export default function PageError({ error }: { error: Error }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="error-page">
      <div className="error-page__content-wrapper">
        <img className="error-page__image" src="/images/uh-oh-page-image.svg" alt="maintenance" />
        <div className="error-page__content">
          <h1 className="error-page__title caption">
            {t("This page is undergoing maintenance. Please bear with us, we'll be back shortly!")}
          </h1>
          <div className="error-page__admin-notice caption">
            <I18n
              path="If you are the Admin <contact>contact support team</contact> for more information."
              components={{
                contact: (
                  <a className="caption link-like" href="https://support.scrile.com" target="_blank" rel="noreferrer">
                    {t("contact support team")}
                  </a>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div className={"error-page__error-wrapper" + (expanded ? " __expanded" : "")}>
        <div className="error-page__button-wrapper">
          <button className="error-page__button caption link-like" onClick={() => setExpanded(!expanded)}>
            {expanded ? t("Hide details") : t("See details")}
            <span className={"error-page__icon" + (expanded ? " __expanded" : "")}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.707 11.8282L14.9998 15.1211L16.4141 13.7069L11.707 8.99977L6.99985 13.7069L8.41406 15.1211L11.707 11.8282Z"
                  fill="#D6008F"
                />
              </svg>
            </span>
          </button>
        </div>
        <div className="error-page__error-stack">{error.stack}</div>
      </div>
    </div>
  );
}
