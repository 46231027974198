import React, { useMemo } from "react";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { parseISO } from "@scrile/tools/dist/lib/TimeHelpers";
import useProduceController from "hooks/useProduceController";
import useLiveChat from "../../hooks/useLiveChat";
import PublicStreamView from "../PublicStreamView";

interface Props {
  produceUser: UserPublicData;
  onClose: () => void;
  livechatSubject: Subject<LivechatSubjectType>;
  disableAttachments?: boolean;
}

function ProduceController({ produceUser, livechatSubject, onClose, disableAttachments }: Props) {
  const { loading: liveChatLoading, joinData, token, userList, clientServerTimeDiff } = useLiveChat({
    livechatSubject,
    connectionDelay: 3000,
  });

  const streamStartedDate = useMemo(() => {
    if (!joinData) {
      return "";
    }
    return new Date(parseISO(joinData.me.joinTime).getTime() + (clientServerTimeDiff ?? 0)).toISOString();
  }, [clientServerTimeDiff, joinData]);

  const { stream, loading, mediaConstraints, audioInputs, videoInputs, onChangeConstraints } = useProduceController({
    token,
    joinData,
    autoProduceStream: true,
  });

  return (
    <PublicStreamView
      produceUser={produceUser}
      isProducerUser={true}
      consumeUser={null}
      threadId={joinData?.threadId}
      produce={true}
      consume={false}
      stream={stream}
      managedStream={stream}
      loading={loading || liveChatLoading}
      userList={userList}
      streamStartedDate={streamStartedDate}
      onClose={onClose}
      token={token}
      constraints={mediaConstraints}
      managedConstraints={mediaConstraints}
      audioInputs={audioInputs}
      videoInputs={videoInputs}
      onChangeConstraints={onChangeConstraints}
      disableAttachments={disableAttachments}
      showConfirmationExit={true}
    />
  );
}

export default ProduceController;
