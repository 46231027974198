import React from "react";
import ReactDOM from "react-dom";
import config, { setConfig } from "./config";
import { appendGtagScriptToHead } from "./lib/googleAnalytics";
import PageError from "./pages/PageError";
import * as serviceWorker from "./serviceWorker";

function createApp() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const App = require("./App").default;
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
}

async function loadConfig(url: string): Promise<any> {
  const limit = 3;
  for (let i = 0; i < limit; i++) {
    try {
      return await (await fetch(url, { cache: "no-store" })).json();
    } catch (e) {
      if (i === limit - 1) {
        throw new Error("Error loading config: " + e.message);
      }
      await new Promise((r) => setTimeout(r, 1000));
    }
  }
}

function setTitle() {
  const titleTag = document.createElement("TITLE");
  const siteName = document.createTextNode(config.siteName);
  titleTag.appendChild(siteName);
  document.head.appendChild(titleTag);
}

Promise.all([loadConfig("/project/js/config.json"), loadConfig(`${config.backendUrl}/system-settings/frontend-config`)])
  .then((configs) => configs.forEach((conf) => setConfig(conf)))
  .then(() => appendGtagScriptToHead())
  .then(() => setTitle())
  .then(() => createApp())
  .catch((e) => {
    ReactDOM.render(
      <React.StrictMode>
        <PageError error={e} />
      </React.StrictMode>,
      document.getElementById("root")
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
