import config from "./config";
import { t } from "./locales";

const questionnaireFieldsConfig = config.questionnaireFields?.slice() ?? [];

questionnaireFieldsConfig.forEach((item) => {
  item.label = t(item.label);
  item.placeholder = t(item.placeholder);
});

export default questionnaireFieldsConfig;
