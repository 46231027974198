import { useTranslation } from "locales";
import { NavigationMenuItemInterface } from "types";
import useController from "./controller";

function useViewController() {
  const { t } = useTranslation();
  const { user } = useController();
  const itemsList: NavigationMenuItemInterface[] = [
    {
      title: t("Main info"),
      name: "settings",
      icon: "signup",
      to: "/user/settings",
    },
    {
      title: t("Appointments"),
      name: "appointments",
      icon: "calendar",
      to: "/user/appointments",
    },
    {
      title: t("Balance history"),
      name: "history",
      icon: "history",
      to: "/user/transactions",
    },
    {
      title: t("Change password"),
      name: "password",
      icon: "show-off",
      to: "/user/change-password",
    },
    {
      title: t("Log out"),
      name: "logout",
      icon: "logout",
      to: "/logout",
    },
  ];

  return {
    user,
    itemsList,
  };
}

export default useViewController;
