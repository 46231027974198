import { useCallback } from "react";
import useAuthUser from "hooks/useAuthUser";
import providers from "lib/providers";
import { isPerformer } from "lib/usersHelper";

export default function useController() {
  const { user: authUser } = useAuthUser();

  const getAppointmentById = useCallback(
    async (appointmentId: string) => {
      try {
        const data = {
          ids: [appointmentId],
          providerId: isPerformer(authUser) ? authUser?.id : undefined,
          consumerId: !isPerformer(authUser) ? authUser?.id : undefined,
        };
        const response = await providers.AppointmentProvider.search(data);
        return response.result[0];
      } catch {
        return null;
      }
    },
    [authUser]
  );

  return {
    authUser,
    getAppointmentById,
  };
}
