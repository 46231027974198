import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HISTORY_ACTIONS } from "const";

export default function ScrollToTopOnMount() {
  const history = useHistory();

  useEffect(() => {
    if (history.action !== HISTORY_ACTIONS.POP) window.scrollTo({ top: 0 });
  }, [history.action]);

  return null;
}
