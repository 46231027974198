import React from "react";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { Appointment } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import useProduceConsumeController from "hooks/useProduceConsumeController";
import BaseIcon from "components/BaseIcon";
import VideoControlButton from "components/VideoControlButton";
import useViewController from "./viewController";
import AppointmentStreamView from "../AppointmentStreamView";

interface Props {
  produceUser: UserPublicData;
  onClose: () => void;
  livechatSubject: Subject<LivechatSubjectType>;
  appointment?: Appointment;
  disableAttachments?: boolean;
}

function ConsumeController({ produceUser, livechatSubject, onClose, appointment }: Props) {
  const {
    threadId,
    produceStream,
    consumeStream,
    consumeScreenStream,
    loading,
    users,
    token,
    consumeMediaConstraints,
    produceMediaConstraints,
    onCloseStream,
    onProduceStream,
    hasProduceTracks,
    hasConsumeTracks,
    hasConsumeScreenTracks,
    onChangeConstraints,
    produceConnection,
    isProducerFinishedStream,
    onProducerFinishedStream,
    audioInputs,
    videoInputs,
  } = useProduceConsumeController(produceUser.id, livechatSubject, false);

  const { produce, onStartProduce } = useViewController(
    onChangeConstraints,
    produceMediaConstraints,
    onProduceStream,
    produceConnection,
    !loading
  );

  const isScreenSharing = hasConsumeScreenTracks && !!consumeMediaConstraints?.shareEnabled;
  const stream = isScreenSharing ? consumeScreenStream : consumeStream;
  const secondStream = isScreenSharing ? consumeStream : produceStream;
  const hasSecondVideo = isScreenSharing
    ? hasConsumeTracks && !!consumeMediaConstraints?.videoEnabled
    : hasProduceTracks && !!produceMediaConstraints?.videoEnabled;
  const hasSecondAudio = isScreenSharing ? hasConsumeTracks && !!consumeMediaConstraints?.audioEnabled : false;

  const controlButton = produce ? undefined : (
    <VideoControlButton
      className="panel-control-buttons__btn"
      name="toggle-cam"
      toggle={produce}
      borderless
      onClick={onStartProduce}
      disabled={loading}
    >
      <BaseIcon>{"cam_off"}</BaseIcon>
    </VideoControlButton>
  );

  return (
    <AppointmentStreamView
      produceUser={produceUser}
      isProducerUser={false}
      consumeUser={null}
      threadId={threadId}
      produce={produce}
      consume={true}
      stream={stream}
      secondStream={secondStream}
      managedStream={produceStream}
      hasSecondVideo={hasSecondVideo}
      hasSecondAudio={hasSecondAudio}
      loading={loading}
      userList={users}
      streamStartedDate={appointment?.startTime ?? ""}
      streamEndedDate={appointment?.endTime ?? ""}
      onClose={onClose}
      token={token}
      audioInputs={audioInputs}
      videoInputs={videoInputs}
      onCloseStream={onCloseStream}
      constraints={consumeMediaConstraints}
      managedConstraints={produceMediaConstraints}
      onChangeConstraints={onChangeConstraints}
      showConfirmationExit={true}
      slotControlButtons={controlButton}
      isProducerFinishedStream={isProducerFinishedStream}
      onProduceFinishedStream={onProducerFinishedStream}
    />
  );
}

export default ConsumeController;
