import React from "react";
import BaseButton from "components/BaseButton";
import "./style.scss";

interface Props {
  children: React.ReactNode;
  name?: string;
  className?: string;
  negative?: boolean;
  borderless?: boolean;
  disabled?: boolean;
  toggle?: boolean;
  onClick?: () => void;
}

function VideoControlButton({ children, name, className, negative, borderless, toggle, onClick, disabled }: Props) {
  const cls = ["video-control-button"];
  if (className) cls.push(className);
  if (borderless) cls.push("__borderless");

  const onClickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick && onClick();
  };

  return (
    <div className={cls.join(" ") + (toggle !== undefined ? (toggle ? " __on" : " __off") : "")}>
      <BaseButton
        negative={negative}
        borderless={borderless}
        className="video-control-button__btn"
        name={name}
        round={true}
        disabled={disabled}
        onClick={onClickHandler}
      >
        {children}
      </BaseButton>
    </div>
  );
}

export default VideoControlButton;
