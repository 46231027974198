import React from "react";
import { PaymentPackage } from "@scrile/api-provider/dist/api/PaymentPackageProvider";
import { useTranslation } from "locales";
import { formatMoneyToString } from "lib/moneyHelper";
import BaseSpinner from "components/BaseSpinner";
import useViewController from "./viewController";
import { submitPaymentCB } from "../../types";
import "./style.scss";

interface Props {
  paymentPackage: PaymentPackage | null;
  setOnSubmitPaymentCB: (cb: submitPaymentCB) => void;
}

function PaymentForm({ paymentPackage, setOnSubmitPaymentCB }: Props) {
  const { t } = useTranslation();
  const { price, currentSystem, loading, amountParts, Component } = useViewController(paymentPackage);

  return (
    <div className="refill-balance">
      <div className="refill-balance__card">
        <h2 className="refill-balance__amount fw-semibold text-center">
          {amountParts.wholePart + amountParts.separator + amountParts.fractionalPart}
        </h2>
        <div className="d-center">
          <p className="refill-balance__currency">{t("Credit", { count: paymentPackage?.amount ?? 0 })}</p>
          {price && <div className="refill-balance__cost">{formatMoneyToString(price.cost, price.currency)}</div>}
        </div>
      </div>
      {loading && <BaseSpinner className="refill-account__loading" />}
      {Component && !loading && (
        <Component
          currentSystem={currentSystem}
          pricePackageId={paymentPackage?.id || ""}
          setOnSubmitPaymentCB={setOnSubmitPaymentCB}
        />
      )}
    </div>
  );
}

export default PaymentForm;
