import config from "config";
import useAuthUser from "hooks/useAuthUser";

const useController = () => {
  const { user } = useAuthUser();

  const isPerformer = user?.role === config.userPerformerRole;
  return {
    user,
    isPerformer,
  };
};

export default useController;
