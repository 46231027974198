import { useCallback, useEffect, useRef, useState } from "react";
import { useUnmount } from "react-use";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/selfie_segmentation";
import { LiveChatJoinResponse, LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { WebRTCConnection } from "@scrile/api-provider/dist/api/WebRTCProvider";
import { TrackOptions } from "@scrile/streaming-client/build/ConsumerClient";
import { getScreenTracks } from "lib/mediaStreamHelpers";
import providers from "lib/providers";
import { MediaConstraints } from "types";
import useLiveChat, { UserBroadcastingCB } from "pages/PageLiveChat/hooks/useLiveChat";
import useOnAuthorised from "./useOnAuthorised";
import useProduceController from "./useProduceController";
import useStreamHelper from "./useStreamHelper";
import useWebRTC from "./useWebRTC";

export enum SendDataEvent {
  PRODUCER_FINISHED_STREAM = "PRODUCER_FINISHED_STREAM",
}

interface ConsumeProps {
  producerUserId: string;
  token: string;
  joinData: LiveChatJoinResponse | null;
  setOnUserBroadcastingCB: (cb: UserBroadcastingCB) => UserBroadcastingCB;
}

function useConsumeController({ producerUserId, token, joinData, setOnUserBroadcastingCB }: ConsumeProps) {
  const WebRTC = useWebRTC();
  const [stream, setStream] = useState<MediaStream>(new MediaStream([]));
  const [screenStream, setScreenStream] = useState<MediaStream>(new MediaStream([]));
  const connection = useRef<WebRTCConnection>();
  const [mediaConstraints, setMediaConstraints] = useState<MediaConstraints | null>(null);
  const [isProducerFinishedStream, setIsProducerFinishedStream] = useState(false);

  const onData = (data: { event: SendDataEvent }) => {
    if (data.event === SendDataEvent.PRODUCER_FINISHED_STREAM) {
      setIsProducerFinishedStream(true);
    }
  };

  const setStreams = (stream: MediaStream, constraints: MediaConstraints | null) => {
    const screenTracks = getScreenTracks(stream);
    if (constraints?.shareEnabled) {
      const audioTracks = stream.getAudioTracks();
      audioTracks.forEach((t) => stream.removeTrack(t));
      screenTracks.push(...audioTracks);
    }
    setScreenStream(new MediaStream(screenTracks));
    setStream(new MediaStream(stream));
  };

  const onConsumeStream = useCallback(
    async (broadcasterChatUserId: string) => {
      setIsProducerFinishedStream(false);
      connection.current = await providers.LivechatsProvider.watch({
        token,
        broadcasterChatUserId,
      });

      const newStream = await WebRTC.consumeStream({
        connection: connection.current,
        tracks: {
          audio: !!mediaConstraints?.audioEnabled,
          video: !!mediaConstraints?.videoEnabled,
          screen: !!mediaConstraints?.shareEnabled,
        },
      });

      newStream && setStreams(newStream, mediaConstraints);
      setMediaConstraints(null);
    },
    [WebRTC, mediaConstraints, token]
  );

  setOnUserBroadcastingCB(({ chatUserId, broadcasting }) => {
    if (joinData?.me.id === chatUserId) return;
    broadcasting ? onConsumeStream(chatUserId).then() : closeStream();
  });

  const closeStream = () => {
    WebRTC.closeAllStreams();
    setStream(new MediaStream([]));
    setScreenStream(new MediaStream([]));
  };

  const shouldCallConsumeStream = useRef(true);
  useEffect(() => {
    if (joinData && shouldCallConsumeStream.current) {
      const producer = joinData.users.find((i) => i.userId === producerUserId);
      if (producer && producer.broadcasting) {
        shouldCallConsumeStream.current = false;
        onConsumeStream(producer.id).then();
      }
    }
  }, [joinData, producerUserId, onConsumeStream]);

  useOnAuthorised(() => {
    shouldCallConsumeStream.current = true;
  });

  useEffect(() => {
    const CB = async (data: Required<TrackOptions>) => {
      const newMediaConstraints = { videoEnabled: data.video, audioEnabled: data.audio, shareEnabled: data.screen };
      const newStream = await WebRTC.consumeStream({
        connection: connection.current!,
        tracks: { audio: data.audio, video: data.video, screen: data.screen },
      });
      setStreams(newStream, newMediaConstraints);
      setMediaConstraints(newMediaConstraints);
    };
    WebRTC.on("producers-updated", CB);
    return () => {
      WebRTC.off("producers-updated", CB);
    };
  }, [WebRTC]);

  useUnmount(closeStream);

  return {
    stream,
    screenStream,
    mediaConstraints,
    isProducerFinishedStream,
    onData,
    closeStream,
  };
}

export default function useProduceConsumeController(
  producerUserId: string,
  livechatSubject: Subject<LivechatSubjectType>,
  autoProduceStream: boolean
) {
  const { loading, token, joinData, setOnUserBroadcastingCB, endChat } = useLiveChat({
    livechatSubject,
  });

  const {
    stream: produceStream,
    screenStream: produceScreenStream,
    mediaConstraints: produceMediaConstraints,
    connection: produceConnection,
    closeStream: closeProduceStream,
    onChangeConstraints,
    onScreenShare,
    isShare,
    onProduceStream,
    audioInputs,
    videoInputs,
    processing,
    loading: loadingProduceData,
    onProducerFinishedStream,
  } = useProduceController({ token, joinData, autoProduceStream });

  const {
    stream: consumeStream,
    screenStream: consumeScreenStream,
    mediaConstraints: consumeMediaConstraints,
    isProducerFinishedStream,
    closeStream: closeConsumeStream,
  } = useConsumeController({
    producerUserId,
    joinData,
    token,
    setOnUserBroadcastingCB,
  });

  const { checkStreamForChanges } = useStreamHelper();

  const localProduceStream = useRef<MediaStream>(produceStream);
  const localProduceScreenStream = useRef<MediaStream>(produceScreenStream);
  const localConsumeStream = useRef<MediaStream>(consumeStream);
  const localConsumeScreenStream = useRef<MediaStream>(consumeScreenStream);
  const [hasProduceTracks, setHasProduceTracks] = useState(false);
  const [hasProduceScreenTracks, setHasProduceScreenTracks] = useState(false);
  const [hasConsumeTracks, setHasConsumeTracks] = useState(false);
  const [hasConsumeScreenTracks, setHasConsumeScreenTracks] = useState(false);

  useEffect(() => {
    if (!produceStream || !localProduceStream.current) return;
    checkStreamForChanges(produceStream, localProduceStream.current);
    setHasProduceTracks(localProduceStream.current.getTracks().length > 0);
  }, [produceStream, checkStreamForChanges]);

  useEffect(() => {
    if (!produceScreenStream || !localProduceScreenStream.current) return;
    checkStreamForChanges(produceScreenStream, localProduceScreenStream.current);
    setHasProduceScreenTracks(localProduceScreenStream.current.getTracks().length > 0);
  }, [produceScreenStream, checkStreamForChanges]);

  useEffect(() => {
    if (!consumeStream || !localConsumeStream.current) return;
    checkStreamForChanges(consumeStream, localConsumeStream.current);
    setHasConsumeTracks(localConsumeStream.current.getTracks().length > 0);
  }, [consumeStream, checkStreamForChanges]);

  useEffect(() => {
    if (!consumeScreenStream || !localConsumeScreenStream.current) return;
    checkStreamForChanges(consumeScreenStream, localConsumeScreenStream.current);
    setHasConsumeScreenTracks(localConsumeScreenStream.current.getTracks().length > 0);
  }, [consumeScreenStream, checkStreamForChanges]);

  useEffect(() => {
    window.addEventListener("beforeunload", endChat);
    return () => {
      window.removeEventListener("beforeunload", endChat);
    };
  }, [endChat]);

  const onCloseStream = () => {
    closeProduceStream();
    closeConsumeStream();
    endChat();
  };

  return {
    threadId: joinData?.threadId,
    users: joinData?.users ?? [],
    produceStream: localProduceStream.current,
    produceScreenStream: localProduceScreenStream.current,
    consumeStream: localConsumeStream.current,
    consumeScreenStream: localConsumeScreenStream.current,
    produceMediaConstraints,
    consumeMediaConstraints,
    onProduceStream,
    onCloseStream,
    onScreenShare,
    isShare,
    onChangeConstraints,
    loading: loading || loadingProduceData || processing,
    token,
    audioInputs,
    videoInputs,
    hasConsumeTracks,
    hasConsumeScreenTracks,
    hasProduceTracks,
    hasProduceScreenTracks,
    produceConnection,
    isProducerFinishedStream,
    onProducerFinishedStream,
  };
}
