import { UserVerificationStatus } from "@scrile/api-provider/dist/api/UserVerificationProvider";
import useAuthUserVerificationStatus from "hooks/useAuthUserVerificationStatus";
import emitter, { EVENTS } from "lib/emitter";

export default function useViewController() {
  const { userVerification } = useAuthUserVerificationStatus();
  const hide = !userVerification || userVerification.status === UserVerificationStatus.VERIFIED;
  const waiting =
    (userVerification && userVerification.status === UserVerificationStatus.WAITING_FOR_APPROVAL) || false;

  const showPerformerVerification = () => {
    if (waiting) return;
    emitter.emit(EVENTS.START_PERFORMER_VERIFICATION);
  };

  return {
    hide,
    waiting,
    showPerformerVerification,
  };
}
