import React from "react";
import { Link } from "react-router-dom";
import { Appointment } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import { I18n, useTranslation } from "locales";
import { printCredits } from "lib/moneyHelper";
import ActionStatus from "components/ActionStatus";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import BaseSpinner from "components/BaseSpinner";
import ModalWindow from "components/ModalWindow";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  show: boolean;
  onClose: () => void;
  title: string;
  content: string;
  appointment: Appointment | null;
  onAppointmentPaidSuccess?: () => void;
}

export default function PaymentBooking({
  show,
  onClose,
  title,
  content,
  appointment,
  onAppointmentPaidSuccess,
}: Props) {
  const { t } = useTranslation();
  const {
    date,
    time,
    min,
    errorMessage,
    balance,
    paidProcessing,
    showSuccessStatus,
    onRefillBalance,
    onPayForAppointment,
    onCloseModal,
  } = useViewController(appointment, onClose, onAppointmentPaidSuccess);

  return (
    <ModalWindow className="payment-booking" show={show} onClose={onCloseModal}>
      {!showSuccessStatus ? (
        <div className="payment-booking__container">
          <div className="payment-booking__icon-container mb-4">
            <BaseIcon size={32}>booking_confirmed</BaseIcon>
          </div>
          <h2 className="payment-booking__heading mb-4">{title}</h2>
          <div className="payment-booking__content">
            {!appointment ? (
              <BaseSpinner className="ma-auto" />
            ) : (
              <>
                <div className="payment-booking__text body text-center">{content}</div>
                <div className="payment-booking__description mb-1">
                  <span className="mr-3">{date && `${date.month} ${date.dayOfMonth}, ${date.year} `}</span>
                  <span>{time}</span>
                </div>
                <div className="payment-booking__description mb-6">
                  <span className="mr-3">{t("minute", { count: min })}</span>{" "}
                  <span>{printCredits(appointment.cost)}</span>
                </div>
                {errorMessage && <div className="payment-booking__text __error mb-5">{t(errorMessage)}</div>}
                {balance < appointment.cost ? (
                  <>
                    <div className="payment-booking__wrapper-buttons">
                      <BaseButton className="payment-booking__wrapper-buttons-btn" onClick={onCloseModal} large outline>
                        {t("Not now")}
                      </BaseButton>
                      <BaseButton
                        className="payment-booking__wrapper-buttons-btn"
                        name="refill-balance"
                        onClick={onRefillBalance}
                        large
                      >
                        {t("Refill balance")}
                      </BaseButton>
                    </div>
                  </>
                ) : appointment.cost > 0 ? (
                  <BaseButton
                    className="mt-6"
                    name="pay-for-appointment"
                    onClick={onPayForAppointment}
                    loading={paidProcessing}
                    fluid
                    large
                  >
                    {t("Pay now")}
                  </BaseButton>
                ) : (
                  <BaseButton className="mt-6" name="awesome" onClick={onCloseModal} fluid large>
                    {t("Awesome")}
                  </BaseButton>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <ActionStatus
          className="payment-booking__success-window"
          status={"success"}
          title={t("The money was transferred successfully")}
          description={
            <span className="payment-booking__success-window-description">
              <I18n
                path="Payment information will be displayed in the <balance>Balance history</balance>"
                components={{
                  balance: <Link onClick={onCloseModal} to="/user/transactions" className="blue-link" />,
                }}
              />
            </span>
          }
        >
          <BaseButton className="mt-6" name="awesome" onClick={onCloseModal} fluid large>
            {t("Awesome")}
          </BaseButton>
        </ActionStatus>
      )}
    </ModalWindow>
  );
}
