import React, { useMemo } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "locales";
import useCustomDomain from "hooks/useCustomDomain";
import { isPerformer } from "lib/usersHelper";
import AddToFavorite from "components/AddToFavorite";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import BasePage from "components/BasePage";
import BaseSpinner from "components/BaseSpinner";
import ContentHeader from "components/ContentHeader";
import PerformerBookingWindow from "./components/PerformerBookingWindow";
import PerformerProfileCard from "./components/PerformerProfileCard";
import PerformerReviewsCard from "./components/PerformerReviewsCard";
import PerformerVideo from "./components/PerformerVideo";
import PerformerVideoModal from "./components/PerformerVideoModal";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  username: string;
}

function PagePerformerProfile({ username }: Props) {
  const { isCustomDomain } = useCustomDomain();

  const {
    user,
    showBook,
    onOpenBook,
    onCloseBook,
    appointments,
    appointmentsLoading,
    video,
    videoLoading,
    showVideoModal,
    setShowVideoModal,
    reviewsLoading,
    mobileReviewsMode,
    onMobileReviewsModeOn,
    onMobileReviewsModeOff,
    isTablet,
    onReviewAdded,
    reviewItems,
    getNextReviewsPage,
    enableReviewsScroll,
    totalReviewsRecords,
    showBackButton,
    onClickBackButton,
  } = useViewController(username);
  const { t } = useTranslation();

  const buttonContentReview = useMemo(
    () => (
      <>
        {t("Reviews")}
        <span className="page-performer-profile__total-reviews small ml-1">{totalReviewsRecords}</span>
      </>
    ),
    [t, totalReviewsRecords]
  );

  const buttonContentBookShow = useMemo(() => <BaseIcon>book</BaseIcon>, []);
  const buttonContentBack = useMemo(() => <BaseIcon className="mx-n1">back</BaseIcon>, []);

  if (user && !isPerformer(user)) {
    return <Redirect to={"/"} />;
  }

  if (user) {
    const prepend = (
      <>
        <div className="page-performer-profile__header-wrapper">
          {showBackButton && (
            <BaseButton
              className="page-performer-profile__back-button mr-2"
              round
              borderless
              onClick={onClickBackButton}
            >
              {buttonContentBack}
            </BaseButton>
          )}
          <ContentHeader className="page-performer-profile__screen-name-header text-left">
            {user.screenName}
          </ContentHeader>
          {!isCustomDomain && (
            <div className="page-performer-profile__status-line">
              {!isTablet && (
                <AddToFavorite className="page-performer-profile__favorite mt-n2" withText={false} user={user} />
              )}
            </div>
          )}
        </div>
        {video?.fileData && !isTablet && (
          <PerformerVideo
            className="mb-8"
            user={user}
            video={video}
            loading={videoLoading}
            onClick={() => setShowVideoModal(true)}
          />
        )}
        <div className="page-performer-profile__switch-buttons">
          <BaseButton
            name="about"
            className={"page-performer-profile__switch-button body-big" + (!mobileReviewsMode ? " __active" : "")}
            onClick={onMobileReviewsModeOff}
            opacity
            large
          >
            {t("About")}
          </BaseButton>
          <BaseButton
            name="reviews"
            className={"page-performer-profile__switch-button body-big" + (mobileReviewsMode ? " __active" : "")}
            onClick={onMobileReviewsModeOn}
            opacity
            large
          >
            {buttonContentReview}
          </BaseButton>
        </div>
      </>
    );
    const side = (
      <div>
        {video?.fileData && isTablet && (
          <PerformerVideo
            className="mb-8"
            user={user}
            video={video}
            loading={videoLoading}
            onClick={() => setShowVideoModal(true)}
          />
        )}
        <PerformerBookingWindow
          user={user}
          appointments={appointments}
          loading={appointmentsLoading}
          show={showBook}
          onClose={onCloseBook}
        />
      </div>
    );
    return (
      <BasePage className="page-performer-profile" prepend={prepend} side={side} revers={true}>
        {(!mobileReviewsMode || isTablet) && (
          <PerformerProfileCard className="page-performer-profile__profile-card" user={user} />
        )}
        {(mobileReviewsMode || isTablet) && (
          <PerformerReviewsCard
            user={user}
            onReviewAdded={onReviewAdded}
            loading={reviewsLoading}
            items={reviewItems}
            enableScroll={enableReviewsScroll}
            getNextPage={getNextReviewsPage}
            totalRecords={totalReviewsRecords}
          />
        )}
        <BaseButton className="page-performer-profile__book-show-button" onClick={onOpenBook}>
          {buttonContentBookShow}
        </BaseButton>
        {video && <PerformerVideoModal show={showVideoModal} video={video} onClose={() => setShowVideoModal(false)} />}
      </BasePage>
    );
  } else {
    return (
      <div className="page-performer-profile__spinner-container">
        <BaseSpinner size={45} />
      </div>
    );
  }
}

export default PagePerformerProfile;
