import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { throttle } from "lodash-es";
import config from "config";
import useAuthUser from "hooks/useAuthUser";
import emitter, { EVENTS } from "lib/emitter";
import providers from "lib/providers";

export default function useController() {
  const { user } = useAuthUser();
  const prevUser = usePrevious(user);

  const [showGoLiveModal, setShowGoLiveModal] = useState(false);
  const [unreadThreadsCount, setUnreadThreadsCount] = useState(0);
  const showGoLiveButton: boolean = user?.role === config.userPerformerRole;

  const openGoLiveModal = useCallback(() => setShowGoLiveModal(true), []);
  const closeGoLiveModal = useCallback(() => setShowGoLiveModal(false), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledCountOfThreadListener = useCallback(
    throttle(() => {
      providers.MessageThreadsProvider.getUnreadCount().then((r) => setUnreadThreadsCount(r));
    }, 1000),
    []
  );

  useEffect(() => {
    if (!prevUser && user) {
      throttledCountOfThreadListener();
      emitter.on(EVENTS.MESSAGES_NEW_MESSAGE, throttledCountOfThreadListener);
      emitter.on(EVENTS.MESSAGES_MARKED_READ, throttledCountOfThreadListener);
    } else if (prevUser && !user) {
      emitter.off(EVENTS.MESSAGES_NEW_MESSAGE, throttledCountOfThreadListener);
      emitter.off(EVENTS.MESSAGES_MARKED_READ, throttledCountOfThreadListener);
    }

    return () => {
      emitter.off(EVENTS.MESSAGES_NEW_MESSAGE, throttledCountOfThreadListener);
      emitter.off(EVENTS.MESSAGES_MARKED_READ, throttledCountOfThreadListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, throttledCountOfThreadListener]);

  const onClickMemberBalance = useCallback(() => emitter.emit(EVENTS.BALANCE_REFILL_SHOW), []);

  return {
    onClickMemberBalance,
    user,
    showGoLiveButton,
    showGoLiveModal,
    openGoLiveModal,
    closeGoLiveModal,
    unreadThreadsCount,
  };
}
