import React from "react";
import { useTranslation } from "locales";
import BaseCard from "components/BaseCard";
import InfiniteScroll from "components/InfiniteScroll";
import TransactionList from "./components/TransactionList";
import useController from "./controller";
import "./style.scss";

export default function PageBalanceHistory() {
  const { t } = useTranslation();
  const { enableScroll, loadNext, transactions } = useController();
  return (
    <BaseCard className="page-balance-history">
      <h2 className="page-balance-history__heading headline-2 mb-5">{t("Balance history")}</h2>
      <InfiniteScroll enable={enableScroll} onNext={loadNext}>
        <TransactionList transactions={transactions} />
      </InfiniteScroll>
    </BaseCard>
  );
}
