import { useState } from "react";

export default function useViewController() {
  const [showReviewModal, setShowReviewModal] = useState(false);

  const onShowReviewModal = () => setShowReviewModal(true);
  const onCloseReviewModal = () => setShowReviewModal(false);

  return {
    showReviewModal,
    onShowReviewModal,
    onCloseReviewModal,
  };
}
