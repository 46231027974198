import { useMemo } from "react";
import { MediaFilesController } from "lib/MediaFileController";
import useController from "./controller";

export default function useViewController() {
  const { status, loading, onSendFile } = useController();

  const idPhoto = useMemo(
    () =>
      (status?.idPhoto?.urlPart && MediaFilesController.getImageThumbnail(status.idPhoto.urlPart ?? "", "720p")) || "",
    [status]
  );
  const photoWithId = useMemo(
    () =>
      (status?.photoWithId?.urlPart &&
        MediaFilesController.getImageThumbnail(status.photoWithId.urlPart ?? "", "720p")) ||
      "",
    [status]
  );

  return {
    idPhoto,
    photoWithId,
    loading,
    onSendFile,
  };
}
