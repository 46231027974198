import { useState } from "react";
import { useMount } from "react-use";
import { FileData, InputFile } from "@scrile/api-provider/dist/api/FileProvider";
import { FragmentFields } from "@scrile/api-provider/dist/api/types";
import { QuestionaryInfo } from "@scrile/api-provider/dist/projects/webvideo/QuestionaryInfoProvider";
import useAuthUser from "hooks/useAuthUser";
import useFiles from "hooks/useFiles";
import providers from "lib/providers";
import { FormValues } from "types";

const performerSearchFields: FragmentFields<QuestionaryInfo> = ["fields"];

function useController() {
  const { user } = useAuthUser();
  const { fileAddToQueue } = useFiles();

  const [loading, setLoading] = useState(false);
  const [dataFields, setDataFields] = useState<Record<string, any>>({});
  const [dataVideo, setDataVideo] = useState<FileData | null>(null);

  useMount(() => {
    (async function () {
      setLoading(true);
      try {
        const questionnaire = await providers.QuestionaryInfoProvider.find(
          { id: user?.id || "" },
          performerSearchFields
        );
        setDataFields((prevState) => {
          const newData: Record<string, any> = {};
          newData["title"] = questionnaire.fields.video?.title;
          newData["description"] = questionnaire.fields.video?.description;
          return {
            ...prevState,
            ...newData,
          };
        });

        const videos = await providers.FileProvider.find({
          fileIds: [{ subjectId: user?.id || "", type: "profile_video" }],
        });
        if (videos.length) setDataVideo(videos[0]);
      } finally {
        setLoading(false);
      }
    })();
  });

  async function onUploadVideo(file: File) {
    if (!user) return;

    const {
      uploadFileId,
      uploadData: { uuid },
    } = await fileAddToQueue({
      type: "profile_video",
      file,
      subjectId: user.id,
      onSuccess: async () => {
        const data: InputFile = {
          fileId: uploadFileId,
          uuid,
        };
        const video = await providers.FileProvider.update({ data });
        setDataVideo(video);
      },
    });
  }

  const onDeleteVideo = async () => {
    if (!user) return;
    const isDeleted = await providers.FileProvider.delete({ data: { subjectId: user.id, type: "profile_video" } });
    if (isDeleted) {
      setDataVideo(null);
    }
  };

  const sendQuestionnaire = async (values: FormValues) => {
    const data: QuestionaryInfo = {
      fields: {
        userId: user?.id || "",
        video: {
          title: values["title"],
          description: values["description"],
        },
      },
    };
    await providers.QuestionaryInfoProvider.update({
      data,
    });
  };

  return { sendQuestionnaire, loading, dataFields, dataVideo, onUploadVideo, onDeleteVideo };
}

export default useController;
