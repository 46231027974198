import { useEffect } from "react";
import { ErrorDTO } from "@scrile/api-provider/dist/lib/apiErrorHandler";
import useAuthUser from "hooks/useAuthUser";
import { gtagTrackEvent } from "lib/analytics";
import { getLoginData } from "lib/authHelper";
import providers from "lib/providers";

export function useController(onClose: () => void) {
  const loginData = getLoginData();
  const { user, setLogout, setEmailVerificationStatus } = useAuthUser();
  const sendAnalyticsEmailVerified = () => {
    gtagTrackEvent("Meet Email verification", {
      event_category: "meetConfirmEmailBtn",
      event_action: "meetConfirmEmailClick",
    });
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const checkConfirmation = async () => {
      if (!user) return;
      const status = await providers.EmailVerificationProvider.find({ userId: [user.id] });
      if (status[user.id]) {
        setEmailVerificationStatus(status[user.id]);
        onClose();
      } else {
        timeout = setTimeout(checkConfirmation, 5000);
      }
    };
    checkConfirmation();
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onResendCode() {
    await providers.EmailVerificationProvider.start({ userId: loginData.user?.id ?? "" });
  }

  async function onSendCode(code: string) {
    const data = { userId: loginData.user?.id ?? "", code };
    const status = await providers.EmailVerificationProvider.verify({ data });
    if (!status) {
      const error = new Error("Invalid verification code");
      const e: ErrorDTO = {
        error,
        message: error.message,
        status: "error",
        code: "UNKNOWN_ERROR",
      };
      // eslint-disable-next-line
      throw [e];
    }
    sendAnalyticsEmailVerified();
    setEmailVerificationStatus(status);
    onClose();
  }

  const verifyByLongCode = (code: string) => {
    return providers.EmailVerificationProvider.verifyByLongCode({ code }).then((status) => {
      setTimeout(() => {
        setEmailVerificationStatus(status);
      }, 5000);
      if (status) {
        sendAnalyticsEmailVerified();
      }
      return status;
    });
  };

  async function onCancel() {
    try {
      await setLogout();
    } finally {
      onClose();
    }
  }

  return { onResendCode, onSendCode, onCancel, verifyByLongCode };
}
