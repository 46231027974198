import { formatMoney, CREDITS_CURRENCY_CODE } from "@scrile/tools/dist/lib/MoneyHelpers";
import { t } from "locales";

export const printCredits = (amount: number, forceNumberSymbol?: boolean) => {
  const money = formatMoney(amount, { forceNumberSymbol });
  return `${
    money.numberSymbol +
    (money.numberSymbol ? "\u00A0" : "") +
    money.wholePart +
    money.separator +
    money.fractionalPart +
    "\u00A0" +
    t(money.symbol)
  }`;
};

export const formatMoneyToString = (amount: number, currencyCode: string = CREDITS_CURRENCY_CODE) => {
  if (currencyCode === CREDITS_CURRENCY_CODE) return printCredits(amount);
  else {
    const formatter = new Intl.NumberFormat("en", {
      style: "currency",
      currency: currencyCode,
      useGrouping: false,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    return formatter.format(amount);
  }
};

export const formatMoneyWithCodeToNumber = (amount: string | undefined) => {
  return Number((amount ?? "").replace(/[^0-9.-]+/g, ""));
};
