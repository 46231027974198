import React, { useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import useAuthUser from "hooks/useAuthUser";
import useCustomDomain from "hooks/useCustomDomain";
import useMounted from "hooks/useMounted";

export default function Logout() {
  const { setLogout } = useAuthUser();
  const [redirect, setRedirect] = useState(false);
  const mounted = useMounted();
  const { isCustomDomain } = useCustomDomain();
  const link = useMemo(() => (isCustomDomain ? "/" : "/home"), [isCustomDomain]);

  useEffect(() => {
    setLogout().then(() => {
      if (mounted.current) setRedirect(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return redirect ? <Redirect to={link} /> : null;
}
