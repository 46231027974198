import React, { useCallback, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "locales";
import useComponentVisible from "hooks/useComponentVisible";
import useCustomDomain from "hooks/useCustomDomain";
import BaseButton from "components/BaseButton";
import BaseCounter from "components/BaseCounter";
import BaseIcon from "components/BaseIcon";
import "./style.scss";

interface Props {
  unreadThreadsCount: number;
  className?: string;
}

function Navigation({ unreadThreadsCount, className }: Props) {
  const { ref, isComponentVisible, setIsComponentVisible, toggleComponentVisible: toggleMenu } = useComponentVisible(
    false
  );
  const { t } = useTranslation();
  const cls = ["navigation"];
  if (className) cls.push(className);

  const onClickItem = useCallback(() => {
    setIsComponentVisible(false);
  }, [setIsComponentVisible]);

  const { isCustomDomain } = useCustomDomain();

  const contentButtonToggle = useMemo(
    () => (
      <>
        <BaseIcon className={isComponentVisible ? "d-block" : "d-none"} size={"24"}>
          close
        </BaseIcon>
        <BaseIcon className={isComponentVisible ? "d-none" : "d-block"} size={"24"}>
          menu
        </BaseIcon>
      </>
    ),
    [isComponentVisible]
  );

  const links = useMemo(() => {
    const links: React.FC[] = [];

    if (isCustomDomain) {
      links.push(() => (
        <NavLink exact={true} to="/" className="link navigation__nav-link" onClick={onClickItem}>
          <BaseIcon className="navigation__nav-icon mr-1">profile</BaseIcon>
          <BaseIcon className="navigation__nav-icon __active-icon mr-1">profile_active</BaseIcon>
          {t("Profile")}
        </NavLink>
      ));
    } else {
      links.push(
        () => (
          <NavLink exact={true} to="/home" className="link navigation__nav-link" onClick={onClickItem}>
            <BaseIcon className="navigation__nav-icon mr-1">consultants</BaseIcon>
            <BaseIcon className="navigation__nav-icon __active-icon mr-1">consultants_active</BaseIcon>
            {t("Consultants")}
          </NavLink>
        ),
        () => (
          <NavLink to="/favorites" className="link navigation__nav-link" onClick={onClickItem}>
            <BaseIcon className="navigation__nav-icon mr-1">favorites</BaseIcon>
            <BaseIcon className="navigation__nav-icon __active-icon mr-1">favorites_active</BaseIcon>
            {t("Favorites")}
          </NavLink>
        )
      );
    }

    return links;
  }, [isCustomDomain, onClickItem, t]);

  return (
    <div className={cls.join(" ")} ref={ref}>
      <div className="navigation__activator mr-2">
        <BaseButton opacity icon className="navigation__burger-button" onClick={toggleMenu}>
          {contentButtonToggle}
        </BaseButton>
      </div>
      <div className={"navigation__content" + (isComponentVisible ? " __active" : "")}>
        <nav className="navigation__nav">
          {links.map((Component, i) => (
            <Component key={i} />
          ))}
          <NavLink to="/messages" className="link navigation__nav-link" onClick={onClickItem}>
            <BaseIcon className="navigation__nav-icon mr-1">messages</BaseIcon>
            <BaseIcon className="navigation__nav-icon __active-icon mr-1">messages_active</BaseIcon>
            {t("Messages")}
            <BaseCounter className="ml-1">{unreadThreadsCount}</BaseCounter>
          </NavLink>
        </nav>
      </div>
    </div>
  );
}

export default Navigation;
