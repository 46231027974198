import React from "react";
import useBreakPoints from "hooks/useBreakPoints";
import useMobileKeyboardOpen from "hooks/useMobileKeyboard";
import { MediaConstraints } from "types";
import BaseIcon from "components/BaseIcon";
import VideoControlButton from "components/VideoControlButton";
import TransitionControl from "../TransitionControl";
import VolumeControl from "../VolumeControl";
import "./style.scss";

interface Props {
  produce: boolean;
  consume: boolean;
  loading: boolean;
  showControlsButtons: boolean;
  constraints: MediaConstraints | null;
  onClickEndCall?: () => void;
  onSwitchCam: () => void;
  onToggleMic: () => void;
  onToggleCam: () => void;
  onToggleBlur: () => void;
  player: HTMLVideoElement | null;
  slotControlButtons?: JSX.Element;
  slotAdditionalControlButtons?: JSX.Element;
  className: string;
  showChatButton?: boolean;
  onToggleChat?: () => void;
}

function PanelControlButtons({
  produce,
  consume,
  loading,
  showControlsButtons,
  constraints,
  onClickEndCall,
  onSwitchCam,
  onToggleMic,
  onToggleCam,
  onToggleBlur,
  player,
  className,
  slotControlButtons,
  slotAdditionalControlButtons,
  showChatButton,
  onToggleChat,
}: Props) {
  const { desktopUp } = useBreakPoints();
  const { keyboardOpen } = useMobileKeyboardOpen();

  const cls = ["panel-control-buttons"];
  cls.push(className);

  return (
    <>
      <TransitionControl showControl={showControlsButtons && !keyboardOpen} className={cls.join(" ")}>
        {onClickEndCall && (
          <VideoControlButton className="panel-control-buttons__btn" name="end-chat" negative onClick={onClickEndCall}>
            <BaseIcon>call</BaseIcon>
          </VideoControlButton>
        )}
        {produce && constraints && (
          <>
            {!desktopUp && (
              <VideoControlButton
                className="panel-control-buttons__btn"
                name="switch-cam"
                borderless
                onClick={onSwitchCam}
                disabled={loading}
              >
                <BaseIcon>switch-camera</BaseIcon>
              </VideoControlButton>
            )}
            <VideoControlButton
              className="panel-control-buttons__btn"
              name="toggle-cam"
              toggle={constraints.videoEnabled}
              borderless
              onClick={onToggleCam}
              disabled={loading}
            >
              <BaseIcon>{constraints.videoEnabled ? "cam_on" : "cam_off"}</BaseIcon>
            </VideoControlButton>
            <VideoControlButton
              className="panel-control-buttons__btn"
              name="toggle-mic"
              toggle={constraints.audioEnabled}
              borderless
              onClick={onToggleMic}
              disabled={loading}
            >
              <BaseIcon>{constraints.audioEnabled ? "mic_on" : "mic_off"}</BaseIcon>
            </VideoControlButton>
          </>
        )}
        {slotControlButtons}
        {produce && constraints?.videoEnabled && (
          <VideoControlButton
            className="panel-control-buttons__btn"
            name="toggle-blur"
            toggle={constraints.blurEnabled}
            borderless
            onClick={onToggleBlur}
            disabled={loading}
          >
            <BaseIcon>{constraints.blurEnabled ? "sparkle" : "sparkle_off"}</BaseIcon>
          </VideoControlButton>
        )}
        {consume && player && <VolumeControl player={player} className="panel-control-buttons__btn" />}
        {showChatButton && onToggleChat && (
          <VideoControlButton
            className="panel-control-buttons__btn"
            name="toogle-chat"
            borderless
            onClick={onToggleChat}
            disabled={loading}
          >
            <BaseIcon>send</BaseIcon>
          </VideoControlButton>
        )}
      </TransitionControl>
      {slotAdditionalControlButtons && (
        <TransitionControl
          showControl={showControlsButtons && !keyboardOpen}
          className={[...cls, "additional-controls"].join(" ")}
        >
          {slotAdditionalControlButtons}
        </TransitionControl>
      )}
    </>
  );
}

export default PanelControlButtons;
