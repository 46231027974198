import useAuthUser from "hooks/useAuthUser";
import useAuthUserVerification from "hooks/useAuthUserVerificationStatus";
import providers from "lib/providers";

export default function useController() {
  const { user } = useAuthUser();
  const { dispatch } = useAuthUserVerification();
  const sendVerificationRequest = async () => {
    if (!user) throw new Error("Not authorised");
    const status = await providers.UserVerificationProvider.start(user.id);
    const searchData = await providers.UserVerificationProvider.search({ data: { userIds: [user.id] } });
    const newStatusData = searchData.result.find((i) => i.userId === user.id);
    if (newStatusData) dispatch({ type: "setUserVerification", payload: { ...newStatusData, role: user.role } });
    return status;
  };

  return { sendVerificationRequest };
}
