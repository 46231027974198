import React from "react";
import config from "config";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import BaseSpinner from "components/BaseSpinner";
import useController from "./controller";
import "./style.scss";

interface Props {
  onClose: () => void;
  title: string;
  className?: string;
}

function SocialLogin({ onClose, title, className }: Props) {
  const { fbLogin, twLogin, isLoading } = useController(onClose);
  const { t } = useTranslation();
  const hasLogin = Object.keys(config.auth).reduce<boolean>((out, key) => {
    return config.auth[key as keyof typeof config.auth] || out;
  }, false);
  const cls = ["social-login"];
  if (className) cls.push(className);
  return (
    (hasLogin && (
      <div className={cls.join(" ")}>
        <p className="social-login__text">{t(title)}</p>
        {isLoading ? (
          <BaseSpinner className="social-login__spinner" />
        ) : (
          <div className="social-login__buttons">
            {config.auth.google && (
              <BaseButton opacity={true} className="social-login__button" name="google">
                <div id="google_btn"></div>
              </BaseButton>
            )}
            {config.auth.twitter && (
              <BaseButton opacity={true} className="social-login__button" name="twitter" onClick={twLogin}>
                <BaseIcon className="social-login__button-icon __twitter">twitter</BaseIcon>
              </BaseButton>
            )}
            {config.auth.facebook && (
              <BaseButton opacity={true} className="social-login__button" name="facebook" onClick={fbLogin}>
                <BaseIcon className="social-login__button-icon __facebook">facebook</BaseIcon>
              </BaseButton>
            )}
          </div>
        )}
      </div>
    )) ||
    null
  );
}

export default SocialLogin;
