import React from "react";
import { NavLink } from "react-router-dom";
import { NavigationMenuItemInterface } from "types";
import BaseCard from "components/BaseCard";
import BaseIcon from "components/BaseIcon";
import "./style.scss";

interface Props {
  itemsList: NavigationMenuItemInterface[];
}

function NavigationMenu({ itemsList }: Props) {
  const top = itemsList.filter((item) => !item.secondMenu);
  const bot = itemsList.filter((item) => !!item.secondMenu);

  return (
    <div className="navigation-menu">
      <nav className="navigation-menu__nav">
        <BaseCard className="navigation-menu__list">
          {top.map((item) => {
            return (
              <NavLink to={item.to || "/home"} key={item.name} className={"navigation-menu__item"}>
                <div>
                  {item.icon && <BaseIcon className="navigation-menu__item-icon">{item.icon}</BaseIcon>}
                  {item.title}
                </div>
              </NavLink>
            );
          })}
        </BaseCard>
        {bot.length > 0 && (
          <BaseCard className="navigation-menu__list">
            {bot.map((item) => {
              return (
                <NavLink to={item.to || "/home"} key={item.name} className={"navigation-menu__item"}>
                  <div>
                    {item.icon && <BaseIcon className="navigation-menu__item-icon">{item.icon}</BaseIcon>}
                    {item.title}
                  </div>
                </NavLink>
              );
            })}
          </BaseCard>
        )}
      </nav>
    </div>
  );
}

export default NavigationMenu;
