import React, { useCallback, useMemo, useState } from "react";
import TextTruncate from "react-text-truncate";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import "./style.scss";

interface Props {
  lines: number;
  className?: string;
  children: string;
}

function TextClamp({ lines, className, children }: Props) {
  const { t } = useTranslation();
  const [more, setMore] = useState(false);
  const cls = ["text-clamp"];
  if (className) cls.push(className);

  const onOpen = useCallback(() => setMore(true), []);
  const onClose = useCallback(() => setMore(false), []);
  const buttonOpenContent = useMemo(
    () => (
      <>
        {t("More")}
        <BaseIcon className="ml-1" size={12.5}>
          fas-angle-down
        </BaseIcon>
      </>
    ),
    [t]
  );
  const buttonCloseContent = useMemo(
    () => (
      <>
        {t("Less")}
        <BaseIcon className="ml-1" size={12.5}>
          fas-angle-up
        </BaseIcon>
      </>
    ),
    [t]
  );

  return (
    <>
      <TextTruncate
        containerClassName={cls.join(" ")}
        line={more ? 9999 : lines}
        text={children}
        textTruncateChild={
          <BaseButton className="text-clamp__button __blue-hover __shadow" opacity onClick={onOpen}>
            {buttonOpenContent}
          </BaseButton>
        }
      />
      {more ? (
        <BaseButton className="text-clamp__button __blue-hover" opacity onClick={onClose}>
          {buttonCloseContent}
        </BaseButton>
      ) : null}
    </>
  );
}

export default React.memo(TextClamp);
