import React from "react";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseSpinner from "components/BaseSpinner";
import { useViewController } from "./viewController";
import "./style.scss";

export interface InfiniteScrollItem<T> {
  data: T;
  key: keyof T;
  component: React.FC<{ data: T }>;
}

interface Props<T> {
  list?: InfiniteScrollItem<T>[];
  className?: string;
  children?: React.ReactNode;
  startPosition?: number;
  pageSize?: number;
  prevNode?: React.FC;
  nextNode?: React.FC;
  onPrev?: (cb: () => void) => void;
  onNext?: (cb: () => void) => void;
  enable: boolean;
  scrollableWrapperRef?: React.RefObject<HTMLElement>;
}

function InfiniteScroll<T>({
  list,
  className = "",
  startPosition = 0,
  pageSize = 20,
  enable,
  children,
  onPrev,
  onNext,
  nextNode,
  prevNode,
  scrollableWrapperRef,
}: Props<T>) {
  const { t } = useTranslation();
  const {
    showPrevButton,
    showNextButton,
    loadingPrev,
    loadingNext,
    loadPrev,
    loadNext,
    observerPrev,
    observerNext,
    itemsToPrint,
  } = useViewController<T>({
    list,
    startPosition,
    pageSize,
    onPrev,
    onNext,
    enable,
    scrollableWrapperRef,
  });

  return (
    <div className={["infinite-scroll", className].join(" ")}>
      <div className="observer __prev" style={{ display: showPrevButton ? "block" : "none" }} ref={observerPrev}>
        {prevNode ? (
          prevNode({})
        ) : (
          <div className="infinite-scroll__button">
            {loadingPrev ? (
              <BaseSpinner />
            ) : (
              <BaseButton outline={true} onClick={loadPrev}>
                {t("Load more")}
              </BaseButton>
            )}
          </div>
        )}
      </div>
      {list
        ? itemsToPrint.map((i) => {
            const Component = i.component;
            const key = i.data[i.key];
            if (typeof key === "string" || typeof key === "number") return <Component data={i.data} key={key} />;
            throw new Error("Wrong type for key in Infinite Scroll list. Should be string | number.");
          })
        : children}
      <div className="observer __next" style={{ display: showNextButton ? "block" : "none" }} ref={observerNext}>
        {nextNode ? (
          nextNode({})
        ) : (
          <div className="infinite-scroll__button">
            {loadingNext ? (
              <BaseSpinner />
            ) : (
              <BaseButton outline={true} onClick={loadNext}>
                {t("Load more")}
              </BaseButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default InfiniteScroll;
