import { useState } from "react";
import { InputJoinData } from "@scrile/api-provider/dist/api/LoginProvider";
import config from "config";
import { useTranslation } from "locales";
import { validateFields } from "lib/validation";
import { FieldInterface, FormValues } from "types";
import { useController } from "./controller";

export function useViewController() {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [values, setValues] = useState<FormValues>({});
  const { onJoin } = useController(setErrors);
  const onChange = (values: FormValues) => setValues(values);
  const { t } = useTranslation();

  const fields: FieldInterface[] = [
    {
      name: "email",
      type: "email",
      placeholder: t("Email"),
      validation: {
        required: true,
      },
      autocomplete: "email",
      textAlign: "center",
    },
    {
      name: "password",
      type: "password",
      placeholder: t("Password"),
      validation: {
        required: true,
      },
      autocomplete: "new-password",
      textAlign: "center",
    },
    {
      name: "terms",
      type: "checkbox",
      className: "client-signup__checkbox",
      validation: {
        required: true,
        customMessage: t("You have to accept the Terms and Conditions"),
      },
    },
  ];

  const sendJoin = async (cb?: () => void) => {
    const email = values.email || "";
    const data: InputJoinData = {
      email,
      password: values.password,
      role: config.userJoinRole,
      screenName: email.split("@")[0],
    };
    const validation = validateFields(fields, values);
    if (validation.valid) return onJoin(data, cb);
    else setErrors(validation.errors);
  };

  return {
    fields,
    values,
    errors,
    setErrors,
    onChange,
    onJoin: sendJoin,
  };
}
