import React, { useRef } from "react";
import { useTranslation } from "locales";
import useBreakPoints from "hooks/useBreakPoints";
import BaseCard from "components/BaseCard";
import BaseIcon from "components/BaseIcon";
import CheckboxInput from "components/CheckboxInput";
import useBlurAnimation from "./useBlurAnimation";
import useViewController from "./viewController";
import "./style.scss";

export interface Item {
  id: string;
  label: string;
}

export interface Props {
  title: string;
  items: Item[];
  values: string[];
  onInput: (values: string[]) => void;
  filterPlaceholder?: string;
  className?: string;
}

export default function BaseFilterContainer({ title, items, values, onInput, filterPlaceholder, className }: Props) {
  const { t } = useTranslation();
  const {
    showMore,
    hasMore,
    itemsToPrint,
    toggleMore,
    onChange,
    filter,
    onFilter,
    mobileHide,
    toggleMobileHide,
  } = useViewController({
    items,
    values,
    onInput,
  });
  const { className: inputClassName, onFocus, onBlur } = useBlurAnimation(500, "__focus");
  const { desktopUp } = useBreakPoints();

  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onFilter(value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  };

  const onClear = () => {
    onFilter("");
    inputRef.current && inputRef.current.focus();
  };

  const cls = ["base-filter-container"];
  if (mobileHide) cls.push("__hide");
  if (className) cls.push(className);

  const listCls = ["base-filter-container__list"];
  if (showMore) listCls.push("__long-list");

  const inputCls = ["base-filter-container__search-wrap"];
  if (filter) inputCls.push("__show");
  if (inputClassName) inputCls.push(inputClassName);

  const Tag = desktopUp ? BaseCard : "div";

  if (items.length === 0) return null;

  return (
    <Tag className={cls.join(" ")}>
      <div className="base-filter-container__title-wrap mb-4">
        <div className="base-filter-container__title-text body-big" onClick={toggleMobileHide}>
          {title}
          <BaseIcon className="base-filter-container__title-icon ml-2">
            {mobileHide ? "fas-angle-down" : "fas-angle-up"}
          </BaseIcon>
        </div>
        <div className={inputCls.join(" ")}>
          <BaseIcon className="base-filter-container__search-icon">search</BaseIcon>
          <input
            ref={inputRef}
            className="base-filter-container__search-input"
            type="text"
            value={filter}
            onChange={onChangeHandler}
            onKeyDown={onKeyDown}
            placeholder={filterPlaceholder}
            onBlur={onBlur}
            onFocus={() => {
              mobileHide && toggleMobileHide();
              onFocus();
            }}
          />
          <BaseIcon className="base-filter-container__search-clear-icon" onClick={onClear}>
            close
          </BaseIcon>
        </div>
      </div>
      <div className="base-filter-container__list-wrap">
        <div className={listCls.join(" ")}>
          {itemsToPrint.map((i) => (
            <CheckboxInput name={i.id} value={i.value} onChange={(v) => onChange(i, v)} key={i.id}>
              {i.label}
            </CheckboxInput>
          ))}
          {items.length > 0 && itemsToPrint.length === 0 && <span>{t("No matching result found")}</span>}
        </div>
        {hasMore && (
          <div className="link mt-6" onClick={toggleMore}>
            {t(showMore ? "Less" : "More")}
            <BaseIcon className="ml-2">{showMore ? "fas-angle-up" : "fas-angle-down"}</BaseIcon>
          </div>
        )}
      </div>
    </Tag>
  );
}
