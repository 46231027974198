import React from "react";
import { Link } from "react-router-dom";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { useTranslation } from "locales";
import "./style.scss";

interface Props {
  user: UserPublicData;
  className?: string;
}

function OnlineStatus({ user, className }: Props) {
  const { t } = useTranslation();
  const cls = ["online-status", "d-center"];
  if (className) cls.push(className);

  let statusText;
  let statusCls;

  if (user?.publicLivechatUser?.broadcasting) {
    statusText = t("Live now");
    statusCls = "__live-now";
  } else if (user.onlineStatus?.isOnline) {
    statusText = t("Online");
    statusCls = "__online";
  } else {
    statusText = t("Offline");
  }
  if (statusCls) cls.push(statusCls);

  return user?.publicLivechatUser?.broadcasting ? (
    <Link style={{ display: "block" }} to={`/${user.username}/live`}>
      <div className={cls.join(" ")}>{statusText}</div>
    </Link>
  ) : (
    <div className={cls.join(" ")}>{statusText}</div>
  );
}

export default OnlineStatus;
