import React from "react";
import filesize from "filesize";
import config from "config";
import { AttachedFileData, AttachedFileError } from "types";
import "./style.scss";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";

interface Props {
  className?: string;
  attachedFileData: AttachedFileData | AttachedFileError;
  removeAttachment?: (id: string) => void;
  link?: string;
  showPreview?: boolean;
}

function MessageAttachView({ className, attachedFileData, removeAttachment, link, showPreview = true }: Props) {
  const cls = ["file-attached"];
  if (className) cls.push(className);

  if ("error" in attachedFileData) {
    cls.push(" __error");
    return (
      <div className={cls.join(" ")}>
        <span className="file-attached__message label">{attachedFileData.error}</span>
        {removeAttachment && (
          <BaseButton
            className="file-attached__remove"
            small={true}
            icon={true}
            opacity={true}
            name="close-error"
            onClick={() => removeAttachment(attachedFileData.id)}
          >
            <BaseIcon size={18}>close</BaseIcon>
          </BaseButton>
        )}
      </div>
    );
  }
  const fileNameParts = attachedFileData.fileName.split(".");
  const extension = (fileNameParts.length > 1 ? fileNameParts[fileNameParts.length - 1] : "").toLocaleLowerCase();
  const typeCls = ["file-attached__type mr-1"];
  if (extension === "doc" || extension === "docx") {
    typeCls.push("__doc");
  } else if (config.fileTypes.image.includes(extension)) {
    typeCls.push("__image");
  } else if (config.fileTypes.video.includes(extension)) {
    typeCls.push("__video");
  } else if (config.fileTypes.audio.includes(extension)) {
    typeCls.push("__audio");
  } else if (config.fileTypes.document.includes(extension)) {
    typeCls.push("__document");
  }
  const size = filesize(attachedFileData.size);

  const content = (
    <>
      {showPreview && attachedFileData.src && (
        <img className="file-attached__image mb-1" src={attachedFileData.src} alt={attachedFileData.fileName} />
      )}
      <div className="file-attached__info">
        {extension && <div className={typeCls.join(" ")}>{extension}</div>}
        <div className="file-attached__name mr-2" title={attachedFileData.fileName}>
          {attachedFileData.fileName}
        </div>
        <div className="file-attached__size">{size}</div>
        {attachedFileData.uploadProgress !== 100 && (
          <div
            className="file-attached__progress ml-2"
            style={{ backgroundSize: `${attachedFileData.uploadProgress}% 100%` }}
          />
        )}
      </div>
    </>
  );

  return link ? (
    <a className={cls.join(" ")} href={link} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  ) : (
    <div className={cls.join(" ")}>{content}</div>
  );
}

export default MessageAttachView;
