import React from "react";
import { useTranslation } from "locales";
import { MediaConstraints } from "types";
import BaseButton from "components/BaseButton";
import BaseForm from "components/BaseForm";
import BaseIcon from "components/BaseIcon";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  loading: boolean;
  className?: string;
  onClose?: () => void;
  onChangeConstraints?: (constraints: MediaConstraints) => void;
  constraints?: MediaConstraints | null;
  audioInputs: MediaDeviceInfo[];
  videoInputs: MediaDeviceInfo[];
  hideTitle?: boolean;
}

function MediaSettings({
  loading,
  className,
  onClose,
  audioInputs,
  videoInputs,
  onChangeConstraints,
  constraints,
  hideTitle = false,
}: Props) {
  const { t } = useTranslation();
  const { fields, values, onChange, setErrors, errors } = useViewController({
    audioInputs,
    videoInputs,
    onChangeConstraints,
    constraints,
    loading,
  });
  const cls = ["media-settings"];
  if (className) cls.push(className);

  return (
    <div className={cls.join(" ")}>
      {!hideTitle && (
        <>
          <h2 className="body fw-semibold mb-4">{t("Voice & Video settings")}</h2>
          <BaseButton className="media-settings__close" icon borderless onClick={onClose}>
            <BaseIcon>close</BaseIcon>
          </BaseButton>
        </>
      )}

      <BaseForm fields={fields} values={values} onChange={onChange} setErrors={setErrors} errors={errors} />
    </div>
  );
}

export default MediaSettings;
