import React from "react";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseForm from "components/BaseForm";
import ElementLoading from "components/ElementLoading";
import UploadMedia from "components/UploadMedia";
import useViewController from "./viewController";
import "./style.scss";

function ProfileVideoPage() {
  const {
    fields,
    values,
    setErrors,
    processing,
    loading,
    errors,
    onChange,
    onSendQuestionnaire,
    src,
    duration,
    buttonText,
    onInputAvatar,
  } = useViewController();
  const { t } = useTranslation();
  return (
    <div className="profile-video-page">
      <BaseForm
        className="profile-video-page-form"
        fields={fields}
        values={values}
        onChange={onChange}
        errors={errors}
        setErrors={setErrors}
        headline={
          <p className="profile-video-page-form__header caption mb-4">
            {t("Upload your presentation video to promote your services more effectively.")}
          </p>
        }
      >
        <div className="profile-video-page__container-profile-video mt-4 mb-8">
          <UploadMedia
            className="profile-video-page__profile-video"
            aspectRatio={9 / 16}
            accept={["video/*"]}
            onInput={onInputAvatar}
            src={src}
            duration={duration}
            buttonText={buttonText}
            showEmptyImage={false}
          />
        </div>
        <BaseButton
          name="submit"
          className="profile-video-page-form__button"
          loading={processing}
          large={true}
          onClick={onSendQuestionnaire}
        >
          {t("Save Changes")}
        </BaseButton>
      </BaseForm>
      <ElementLoading loading={loading} />
    </div>
  );
}

export default ProfileVideoPage;
