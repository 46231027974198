import questionnaireFieldsConfig from "questionnaireConfig";
import { FieldInterface } from "types";

export const questionnaireFields: FieldInterface[] = [];

questionnaireFieldsConfig.forEach((item) => {
  const labelClassName = "questionnaire-page-form__textarea-label";
  if (item.type === "textarea") {
    questionnaireFields.push({
      ...item,
      labelClassName,
      autoGrow: true,
      rows: 3,
      maxRows: 10,
    });
  } else {
    questionnaireFields.push({
      ...item,
      labelClassName,
    });
  }
});
