import React, { useCallback, useMemo } from "react";
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import config from "config";
import useCustomDomain from "hooks/useCustomDomain";
import ClientLogin from "modals/ClientLogin";
import ClientSignUp from "modals/ClientSignUp";
import ConfirmEmail from "modals/ConfirmEmail";
import EmailVerificationByCode from "modals/EmailVerificationByCode";
import ForgotPassword from "modals/ForgotPassword";
import GoAppointmentChatModal from "modals/GoAppointmentChatModal";
import GoConferenceModal from "modals/GoConferenceModal";
import { LocationState } from "types";
import ModalWindow from "components/ModalWindow";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";
import PageAppointmentChat from "pages/PageAppointmentChat";
import PageConferenceChat from "pages/PageConferenceChat";
import PageLiveChat from "pages/PageLiveChat";
import PageLoginToken from "pages/PageLoginToken";
import PageLoginTwitter from "pages/PageLoginTwitter";
import PageLogout from "pages/PageLogout";
import PageMessages from "pages/PageMessages";
import PagePerformerProfile from "pages/PagePerformerProfile";
import PagePerformers from "pages/PagePerformers";
import PageSettings from "pages/PageUserArea";
import LinkedModalPageView from "../LinkedModalPageView";

interface LinkedModalDescription {
  path: string;
  Component: React.FC;
  meta?: {
    authOnly?: boolean;
    modal?: boolean;
  };
  comparator?: (currentPath: string) => boolean;
}

interface RouterDescription {
  path: string;
  exact?: boolean;
  Component: React.FC;
}

export default function MainRouterView() {
  const location = useLocation<LocationState>();
  const history = useHistory<LocationState>();
  const backgroundLocation = location.state?.location;
  const onModalClose = useCallback(() => history.replace(backgroundLocation || { pathname: "/" }), [
    history,
    backgroundLocation,
  ]);

  const modals: LinkedModalDescription[] = useMemo(
    () => [
      {
        path: "/login",
        Component: () => <ClientLogin onClose={onModalClose} />,
      },
      {
        path: "/login/forgot",
        Component: () => <ForgotPassword onCancel={onModalClose} />,
      },
      {
        path: "/login/forgot/:email",
        Component: () => {
          const { email } = useParams<{ email: string }>();
          return <ForgotPassword email={email} onCancel={onModalClose} />;
        },
      },
      {
        path: "/login/forgot/:email/:token",
        Component: () => {
          const { token } = useParams<{ token: string }>();
          return <ForgotPassword token={token} onCancel={onModalClose} />;
        },
      },
      {
        path: "/signup",
        Component: () => <ClientSignUp onClose={onModalClose} />,
      },
      {
        path: "/signup/email",
        Component: () => <ConfirmEmail onCancel={onModalClose} />,
        meta: {
          modal: location.pathname === "/signup/email",
        },
      },
      {
        path: "/signup/confirm",
        Component: () => {
          const search = new URLSearchParams(location.search);
          const code = search.get("code");
          return <EmailVerificationByCode onClose={onModalClose} code={code} />;
        },
        meta: {
          modal: location.pathname === "/signup/confirm",
        },
      },
      {
        path: "/appointment/join/:appointmentId",
        Component: () => {
          const { appointmentId } = useParams<{ appointmentId: string }>();
          return <GoAppointmentChatModal onClose={onModalClose} appointmentId={appointmentId} />;
        },
        meta: { modal: true },
        comparator: (currentPath) => /\/appointment\/join\/\d+/.test(currentPath),
      },
      {
        path: "/conference/join/:conferenceId",
        Component: () => {
          const { conferenceId } = useParams<{ conferenceId: string }>();
          return <GoConferenceModal onClose={onModalClose} conferenceId={conferenceId} />;
        },
        meta: { modal: true },
        comparator: (currentPath) => /\/conference\/join\/\d+/.test(currentPath),
      },
    ],
    [location, onModalClose]
  );

  const { user, isCustomDomain } = useCustomDomain();

  const routes = useMemo(() => {
    const routes: RouterDescription[] = [];

    if (isCustomDomain) {
      routes.push({
        path: "/live",
        Component: () => <PageLiveChat username={user ? user.username : ""} />,
      });
    } else {
      routes.push(
        {
          path: "/home",
          exact: true,
          Component: () => <PagePerformers key="performers" />,
        },
        {
          path: "/favorites",
          Component: () => <PagePerformers onlyFollowings key="favorites" />,
        }
      );
    }

    if (config.featureLoginAsUser) {
      routes.push({
        path: "/login/token/:token",
        Component: () => {
          const { token } = useParams<{ token: string }>();
          return <PageLoginToken token={token} />;
        },
      });
    }

    routes.push(
      {
        path: "/conference/:conferenceId",
        Component: () => {
          const { conferenceId } = useParams<{ conferenceId: string }>();
          return <PageConferenceChat conferenceId={conferenceId} />;
        },
      },
      {
        path: "/:username/live",
        Component: () => {
          const { username } = useParams<{ username: string }>();
          return <PageLiveChat username={username} />;
        },
      },
      {
        path: "/:username",
        Component: () => {
          const { username } = useParams<{ username: string }>();
          return <PagePerformerProfile username={username} />;
        },
      },
      {
        path: "/",
        Component: () =>
          isCustomDomain ? <PagePerformerProfile username={user ? user.username : ""} /> : <Redirect to={"/home"} />,
      }
    );

    return routes;
  }, [isCustomDomain, user]);

  const currentModal = modals.find((m) =>
    m.comparator ? m.comparator(location.pathname) : m.path === location.pathname
  );

  return (
    <>
      <Switch location={backgroundLocation || location}>
        {!backgroundLocation &&
          modals.map((data) => (
            <Route path={data.path} exact key={data.path}>
              <LinkedModalPageView path={location.pathname} />
            </Route>
          ))}
        <Route path={"/user"}>
          <ScrollToTopOnMount />
          <PageSettings />
        </Route>
        <Route path={"/logout"}>
          <PageLogout />
        </Route>
        <Route path={"/login/twitter"}>
          <PageLoginTwitter />
        </Route>
        <Route path={"/messages"}>
          <PageMessages />
        </Route>
        <Route path={"/appointment/:appointmentId"}>
          <PageAppointmentChat />
        </Route>
        {routes.map(({ path, exact, Component }) => (
          <Route path={path} exact={exact} key={path}>
            <ScrollToTopOnMount />
            <Component />
          </Route>
        ))}
      </Switch>
      {backgroundLocation && (
        <ModalWindow show modal={currentModal?.meta?.modal} onClose={onModalClose}>
          {modals.map(({ path, Component }) => (
            <Route path={path} exact key={path}>
              <Component />
            </Route>
          ))}
        </ModalWindow>
      )}
    </>
  );
}
