import { useEffect, useState } from "react";
import { InputSliderProps } from "react-input-slider";

interface VolumeSettings {
  muted: boolean;
  level: number;
  lastLevel: number;
}

const styles = {
  track: {
    "height": "100%",
    "width": 4,
    "backgroundColor": "var(--palette-input-bg)",
    "&:active > div:first-of-type": { backgroundColor: "var(--palette-primary-darken-1)" },
    "&:active > div > div": {
      boxShadow: "inset 0 0 0 1px var(--palette-primary-darken-1), 0 0 5px rgba(var(--palette-foreground-rgb), 0.25)",
    },
  },
  active: {
    backgroundColor: "var(--palette-primary)",
    transition: "background-color 0.1s linear",
  },
  thumb: {
    "width": 12,
    "height": 12,
    "boxShadow": "0px 0px 5px rgba(var(--palette-foreground-rgb), 0.25)",
    "transition": "box-shadow 0.1s linear",
    "&:hover": {
      boxShadow: "inset 0 0 0 1px var(--palette-primary), 0 0 5px rgba(var(--palette-foreground-rgb), 0.25)",
    },
  },
};

const useController = (player: HTMLVideoElement) => {
  const storageFieldName = "volumeSettings";
  const initialVolume = 0.5;

  const [volumeSettings, setVolumeSettings] = useState<VolumeSettings>({
    muted: player.muted,
    level: player.muted ? 0 : initialVolume,
    lastLevel: 1,
  });

  const onVolumeClick = () => {
    setVolumeSettings((settings) => ({
      muted: !settings.muted,
      level: !settings.muted ? 0 : settings.lastLevel,
      lastLevel: settings.level === 0 ? initialVolume : settings.level,
    }));
  };

  const onVolumeChange = (values: InputSliderProps) => {
    if (!values.y) return;
    const volumeValue = values.y <= 0.08 ? 0 : values.y; // react-input-slider issue with 0 value fix
    setVolumeSettings((settings) => ({
      ...settings,
      muted: !volumeValue,
      level: +volumeValue.toFixed(2),
    }));
  };

  useEffect(() => {
    const volume = player.volume;
    const muted = player.muted;
    const storedVolumeSettings = localStorage.getItem(storageFieldName);

    if (storedVolumeSettings) {
      const storedSettings: VolumeSettings = JSON.parse(storedVolumeSettings);
      setVolumeSettings((settings) => ({
        ...settings,
        level: muted ? 0 : storedSettings.level,
        muted: storedSettings.muted || muted,
      }));
    } else {
      setVolumeSettings((settings) => ({
        ...settings,
        level: muted ? 0 : volume,
        muted: muted,
      }));
    }
  }, [player]);

  useEffect(() => {
    player.volume = volumeSettings.level;
    player.muted = volumeSettings.muted;
    localStorage.setItem(storageFieldName, JSON.stringify(volumeSettings));
  }, [player, volumeSettings]);

  return {
    styles,
    volumeSettings,
    onVolumeClick,
    onVolumeChange,
  };
};

export default useController;
