import React from "react";
import { useTranslation } from "locales";
import BasePage from "components/BasePage";
import InfiniteScroll from "components/InfiniteScroll";
import BaseFilters from "./components/BaseFilters";
import PerformerList from "./components/PerformerList";
import useController from "./controller";
import "./style.scss";

interface Props {
  onlyFollowings?: boolean;
}

function PagePerformers({ onlyFollowings = false }: Props) {
  const { users, loading, scroll, loadMore, filtersDTO, setFilters, totalRecords, userFavoriteIds } = useController({
    onlyFollowings,
  });
  const { t } = useTranslation();
  const side = (
    <div className="page-performers__side">
      <BaseFilters filtersDTO={filtersDTO} setFilters={setFilters} totalRecords={totalRecords} />
    </div>
  );

  return (
    <BasePage loading={loading} side={side} className="page-performers">
      {users.length > 0 && (
        <InfiniteScroll enable={scroll} onNext={loadMore}>
          <PerformerList users={users} userFavoriteIds={userFavoriteIds} />
        </InfiniteScroll>
      )}
      {!loading && users.length === 0 && (
        <div className="d-center headline-2" style={{ height: "100%" }}>
          {t("No matching result found")}
        </div>
      )}
    </BasePage>
  );
}

export default PagePerformers;
