import React from "react";
import { TipMessageDetails } from "@scrile/api-provider/dist/api/TipProvider";
import { printCredits } from "../../../../../lib/moneyHelper";
import { useTranslation } from "../../../../../locales";
import DefaultMessageView from "../DefaultMessageView";
import { PropsMessageItem } from "../types";
import "./style.scss";

export default function MessageTipItem({ authUser, ...props }: PropsMessageItem) {
  const selfMessage = String((props.message.details as TipMessageDetails).senderId) === authUser?.id;
  const { t } = useTranslation();
  return (
    <DefaultMessageView
      {...props}
      selfMessage={selfMessage}
      highlightMessage={false}
      contentMessage={
        <div className={"message-tip-item__content " + (selfMessage ? "text-right" : "text-left")}>
          <div className="message-tip-item__text small mb-2">{t("Payment")}</div>
          <div className="message-tip-item__amount headline-2 fw-normal">
            {printCredits((props.message.details as TipMessageDetails).amount)}
          </div>
        </div>
      }
    />
  );
}
