import React from "react";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import BaseAvatar from "components/BaseAvatar";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import "./style.scss";

interface Props {
  user: LiveChatUser;
  appendIcon?: string;
  onAppendIconClick?: () => void;
}

const UserListItem = ({ user, appendIcon, onAppendIconClick }: Props) => {
  return (
    <div className="users-list-item">
      <BaseAvatar className="users-list-item__avatar" size={30} user={user.user} />
      <span className="users-list-item__name label">{user.screenName || user.user?.screenName}</span>
      {appendIcon && (
        <BaseButton className="users-list-item__icon-btn" icon opacity onClick={onAppendIconClick}>
          <BaseIcon className="users-list-item__icon">{appendIcon}</BaseIcon>
        </BaseButton>
      )}
    </div>
  );
};

export default UserListItem;
