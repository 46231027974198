import { AppointmentType } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import useAuthUser from "hooks/useAuthUser";
import providers from "lib/providers";

export default function useController() {
  const { user } = useAuthUser();

  const syncAppointmentTypes = async (setSyncedAppointments: (appointmentTypes: AppointmentType[]) => void) => {
    const response = await providers.AppointmentProvider.syncAppointmentTypes({ userId: user?.id || "" });
    setSyncedAppointments(response);
  };

  return { syncAppointmentTypes };
}
