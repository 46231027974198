import { useEffect, useState } from "react";
import useBaseForm from "hooks/useBaseForm";
import { formatMoneyWithCodeToNumber } from "lib/moneyHelper";
import { FormValue } from "types";
import useController from "./controller";

export default function useViewController() {
  const { appointments, loading, updateAppointmentTypes, setSyncedAppointments } = useController();
  const [processing, setProcessing] = useState(false);
  const { fields, values, setValues } = useBaseForm(
    appointments.map((appointment) => {
      return {
        className: "appointments-pricing__prices-input",
        label: appointment.name,
        name: appointment.id,
        type: "text",
        placeholder: "0 Cr",
        autocomplete: "off",
        validation: {
          required: true,
        },
        maskOptions: {
          mask: "[9]{1,20} Cr",
          placeholder: "0 Cr",
        },
      };
    })
  );

  useEffect(() => {
    const tempValues: Record<string, any> = {};
    appointments.forEach((appointment) => {
      tempValues[appointment.id] = appointment.cost;
    });
    setValues(tempValues);
  }, [appointments, setValues]);

  const onChange = (value: FormValue, name: string) => {
    setValues({ ...values, [name]: value });
  };

  const onSave = async () => {
    setProcessing(true);
    const data = Object.entries(values).map(([key, value]) => {
      return {
        id: key,
        cost: formatMoneyWithCodeToNumber(value.toString()),
      };
    });

    try {
      await updateAppointmentTypes(data);
    } finally {
      setProcessing(false);
    }
  };

  return {
    fields,
    values,
    onChange,
    onSave,
    appointments,
    loading,
    processing,
    setSyncedAppointments,
  };
}
