import { useEffect, useRef, useState } from "react";
import emitter, { EVENTS } from "lib/emitter";

export function useUserFirstDocInteract() {
  const [showStreamPlayButton, setShowStreamPlayButton] = useState(false);
  const callbacks = useRef<Array<() => void | Promise<void>>>([]);

  useEffect(() => {
    const onClick = () => {
      setShowStreamPlayButton(false);
      document.removeEventListener("click", onClick);
      return Promise.all(callbacks.current.map((cb) => cb()));
    };

    const onEvent = async (cb?: () => void) => {
      setShowStreamPlayButton(true);
      cb && callbacks.current.push(cb);
      document.addEventListener("click", onClick);
    };

    emitter.on<() => void>(EVENTS.USER_DID_NOT_INTERACT_DOC_FIRST, onEvent);
    return () => emitter.off<() => void>(EVENTS.USER_DID_NOT_INTERACT_DOC_FIRST, onEvent);
  }, []);

  return {
    showStreamPlayButton,
  };
}
