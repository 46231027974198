import { useEffect, useState } from "react";
import { useTranslation } from "locales";
import useBaseForm from "hooks/useBaseForm";
import useMounted from "hooks/useMounted";
import { parseErrors } from "lib/requestHelpers";
import { validateFieldsWithException } from "lib/validation";
import useController from "./controller";

export default function useViewController(onNext: () => Promise<void>) {
  const { t } = useTranslation();
  const mounted = useMounted();
  const [processing, setProcessing] = useState(false);
  const { loading, info, sendData } = useController();
  const { fields, values, errors, setValues, setErrors } = useBaseForm([
    {
      name: "firstName",
      type: "text",
      label: t("First Name"),
      className: "performer-verification__short-input",
      validation: {
        required: true,
      },
    },
    {
      name: "lastName",
      type: "text",
      label: t("Last Name"),
      className: "performer-verification__short-input ",
      validation: {
        required: true,
      },
    },
    {
      name: "phone",
      type: "tel",
      label: t("Phone Number"),
      validation: {
        required: true,
      },
    },
  ]);

  const onSubmit = async () => {
    try {
      setProcessing(true);
      validateFieldsWithException(fields, values);
      await sendData(values);
      await onNext();
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      mounted.current && setProcessing(false);
    }
  };

  useEffect(() => {
    if (!info) {
      setValues({});
      return;
    }
    setValues({
      firstName: info.firstName,
      lastName: info.lastName,
      phone: info.phone,
    });
  }, [info, setValues]);

  return {
    loading,
    processing,
    fields,
    values,
    errors,
    setValues,
    setErrors,
    onSubmit,
  };
}
