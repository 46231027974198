import { useEffect, useState } from "react";
import { InputAppointmentRegister } from "@scrile/api-provider/dist/projects/webvideo/CalendlyProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import emitter, { EVENTS } from "lib/emitter";
import providers from "lib/providers";

export default function useController() {
  const [providerUser, setProviderUser] = useState<UserPublicData | null>(null);

  useEffect(() => {
    const setBookingUser = (user: UserPublicData) => {
      setProviderUser(user);
    };
    emitter.on<any>(EVENTS.SET_BOOKING_USER, setBookingUser);
    return () => {
      emitter.off<any>(EVENTS.SET_BOOKING_USER, setBookingUser);
    };
  }, []);

  const registerAppointment = async (data: InputAppointmentRegister) => {
    return await providers.CalendlyProvider.registerAppointment(data);
  };

  return {
    providerUser,
    setProviderUser,
    registerAppointment,
  };
}
