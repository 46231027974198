import { Transaction } from "@scrile/api-provider/dist/api/TransactionProvider";
import { getComponent, getContextModules } from "lib/requireContextHelper";
import DefaultTransaction from "./DefaultTransaction";

const context = require.context("./", true, /\.\/Transaction[a-zA-Z]*\/index.ts/);
const modules = getContextModules(context);

export function getTransactionItem(transaction: Transaction) {
  return getComponent(modules, transaction.details, DefaultTransaction);
}
