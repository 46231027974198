import React from "react";
import { useTranslation } from "locales";
import ShortCodeVerification from "components/ShortCodeVerification";
import { useController } from "./controller";

interface Props {
  email: string;
  onCancel: () => void;
  onCodeVerified: (data: { email: string; token: string }) => void;
}

export default function CodeVerification({ email, onCancel, onCodeVerified }: Props) {
  const { onResendCode, onSendCode } = useController(onCodeVerified);
  const { t } = useTranslation();

  return (
    <ShortCodeVerification
      title={t("We sent you a 6-digit code")}
      email={email}
      onCancel={onCancel}
      resendCode={() => onResendCode(email)}
      sendCode={(code) => onSendCode({ email, code })}
    />
  );
}
