import React, { createContext, Reducer, useContext, useEffect, useReducer, useRef } from "react";
import { usePrevious } from "react-use";
import { SystemSettings } from "@scrile/api-provider/dist/api/SystemSettingsProvider";
import { isMobile } from "@scrile/tools/dist/lib/browserUtils";
import { Subscription } from "apollo-client/util/Observable";
import providers from "lib/providers";
import useAuthUser from "./useAuthUser";
type Action =
  | {
      type: "setBalance";
      payload: number;
    }
  | {
      type: "setBalanceLoading";
      payload: boolean;
    }
  | {
      type: "setSystemSettings";
      payload: SystemSettings;
    }
  | {
      type: "setKeyboardOpen";
      payload: boolean;
    };

interface AppState {
  balance: number;
  balanceLoading: boolean;
  systemSettings: SystemSettings | null;
  keyboardOpen: boolean;
}

const reducer: Reducer<AppState, Action> = (state, action) => {
  switch (action.type) {
    case "setBalance":
      return { ...state, balance: action.payload };
    case "setBalanceLoading":
      return { ...state, balanceLoading: action.payload };
    case "setSystemSettings":
      return { ...state, systemSettings: action.payload };
    case "setKeyboardOpen":
      return { ...state, keyboardOpen: action.payload };
  }
};

const defaultState: AppState = {
  balance: 0,
  balanceLoading: true,
  systemSettings: null,
  keyboardOpen: false,
};

const AppStateContext = createContext<[AppState, React.Dispatch<Action>]>([
  defaultState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

function useUserBalance(dispatch: React.Dispatch<Action>) {
  const { user } = useAuthUser();
  const prevUser = usePrevious(user);
  const subscription = useRef<Subscription | null>(null);

  useEffect(() => {
    if (!user) {
      dispatch({ type: "setBalance", payload: 0 });
      dispatch({ type: "setBalanceLoading", payload: true });
      return;
    }
    if ((!prevUser && user) || prevUser?.emailVerified !== user.emailVerified) {
      providers.TransactionProvider.getBalance({ userIds: [user.id] })
        .then((balance) => {
          dispatch({ type: "setBalance", payload: balance[user.id] });
        })
        .catch(() => {
          dispatch({ type: "setBalance", payload: 0 });
        })
        .finally(() => {
          dispatch({ type: "setBalanceLoading", payload: false });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch]);

  useEffect(() => {
    const setBalance = (balance: number, balanceLoading = false) => {
      dispatch({ type: "setBalance", payload: balance });
      dispatch({ type: "setBalanceLoading", payload: balanceLoading });
    };

    if (!user) {
      setBalance(0, true);
      subscription.current && subscription.current.unsubscribe();
      return;
    }

    if ((!prevUser && user) || prevUser?.emailVerified !== user.emailVerified) {
      providers.TransactionProvider.subscribeUserBalanceUpdated(setBalance).then(
        (data) => (subscription.current = data)
      );
      return;
    }

    return () => {
      subscription.current && subscription.current.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch]);
}

function useSystemSettings(dispatch: React.Dispatch<Action>) {
  useEffect(() => {
    (async function () {
      const systemSettings = await providers.SystemSettingsProvider.get();
      dispatch({ type: "setSystemSettings", payload: systemSettings });
    })();
  }, [dispatch]);
}

function useKeyboardOpen(keyboardOpen: boolean) {
  useEffect(() => {
    if (keyboardOpen && isMobile()) {
      document.body.classList.add("keyboard-open");
    } else {
      document.body.classList.remove("keyboard-open");
    }
  }, [keyboardOpen]);
}

export function AppStateContextProvider(props: React.PropsWithChildren<Record<string, any>>) {
  const [state, dispatch] = useReducer(reducer, defaultState);
  useKeyboardOpen(state.keyboardOpen);
  useUserBalance(dispatch);
  useSystemSettings(dispatch);
  return React.createElement(
    AppStateContext.Provider,
    {
      value: [state, dispatch],
    },
    props.children
  );
}

export default function useAppState() {
  const [{ balance, balanceLoading, systemSettings, keyboardOpen }, dispatch] = useContext(AppStateContext);
  return {
    balance,
    systemSettings,
    balanceLoading,
    keyboardOpen,
    dispatch,
  };
}
