import React from "react";
import { Link } from "react-router-dom";
import { Appointment } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import { useTranslation } from "locales";
import PaymentBooking from "modals/PaymentBooking";
import BaseButton from "components/BaseButton";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  appointment: Appointment;
  isConsultant?: boolean;
  hideStatus?: boolean;
}

export default function AppointmentItem({ appointment, isConsultant = true, hideStatus = false }: Props) {
  const { t } = useTranslation();
  const {
    user,
    date,
    time,
    min,
    status,
    showModal,
    onOpenModal,
    onCloseModal,
    isAppointmentPaid,
    onAppointmentPaidSuccess,
  } = useViewController(appointment, isConsultant);

  return (
    <div className="appointments-item label">
      <div className="appointments-item__date fw-semibold">
        <span>
          {date.dayOfWeek} {date.dayOfMonth},{" "}
        </span>
        <span className="appointments-item__date-month">
          {date.month} {date.year}
        </span>
      </div>
      <div className={"appointments-item__status " + (isAppointmentPaid ? "__paid" : "")}>
        {hideStatus ? null : isConsultant || isAppointmentPaid ? (
          status
        ) : (
          <BaseButton name="pay-for-appointment" onClick={onOpenModal}>
            {t("Pay now")}
          </BaseButton>
        )}
      </div>
      <div className="appointments-item__time fw-semibold">{`${time}, ${min} ${t("min")}`}</div>
      <div className="appointments-item__name">
        {isConsultant ? user.screenName : <Link to={`/${user.username}`}>{user.screenName}</Link>}
      </div>
      <PaymentBooking
        show={showModal}
        appointment={appointment}
        onClose={onCloseModal}
        title={t("Pay for an appointment")}
        content={t("You have booked an appointment with {{screenName}}", {
          screenName: appointment.provider.screenName,
        })}
        onAppointmentPaidSuccess={onAppointmentPaidSuccess}
      />
    </div>
  );
}
