import { useEffect, useState } from "react";
import {
  AppointmentType,
  InputAppointmentTypeUpdate,
} from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import useAuthUser from "hooks/useAuthUser";
import providers from "lib/providers";

export default function useController() {
  const { user } = useAuthUser();
  const [appointments, setAppointments] = useState<AppointmentType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await providers.AppointmentProvider.searchAppointmentTypes({ userId: user?.id || "" });
        setAppointments(response);
      } finally {
        setLoading(false);
      }
    })();
  }, [user]);

  const updateAppointmentTypes = async (data: InputAppointmentTypeUpdate[]) => {
    await providers.AppointmentProvider.updateAppointmentTypes(data);
  };

  const setSyncedAppointments = (appointmentTypes: AppointmentType[]) => {
    setAppointments(appointmentTypes);
  };

  return {
    appointments,
    loading,
    updateAppointmentTypes,
    setSyncedAppointments,
  };
}
