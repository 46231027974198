import React from "react";
import { PaymentSystemPublic } from "@scrile/api-provider/dist/api/PaymentSystemsProvider";
import { useTranslation } from "locales";
import useBaseForm from "hooks/useBaseForm";
import BaseForm from "components/BaseForm";
import { submitPaymentCB } from "../types";

function DefaultComponent({
  setOnSubmitPaymentCB,
}: {
  setOnSubmitPaymentCB: (cb: submitPaymentCB) => void;
  pricePackageId: string;
  currentSystem: PaymentSystemPublic | null;
}) {
  const { t } = useTranslation();
  const { fields, values, errors, setValues, setErrors } = useBaseForm([]);

  setOnSubmitPaymentCB(async () => {
    setErrors({ common: t("Something wrong") });
  });

  return <BaseForm fields={fields} values={values} onChange={setValues} setErrors={setErrors} errors={errors} />;
}

export default DefaultComponent;
