import React from "react";
import { User } from "@scrile/api-provider/dist/api/UserProvider";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { MediaConstraints } from "types";
import PanelControlButtons from "components/StreamView/components/PanelControlButtons";
import PlayStreamButton from "components/StreamView/components/PlayStreamButton";
import RippleAvatarAnimation from "components/StreamView/components/RippleAvatarAnimation";
import TransitionControl from "components/StreamView/components/TransitionControl";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  produceUser: User | UserPublicData | null;
  stream: MediaStream;
  loading: boolean;
  constraints: MediaConstraints;
  onChangeConstraints: (constraints: MediaConstraints) => void;
  screenName?: string;
}

function StreamPreview({ produceUser, stream, loading, constraints, onChangeConstraints, screenName }: Props) {
  const {
    player,
    desktopUp,
    showControls,
    showControlsButtons,
    showStreamPlayButton,
    onShowControls,
    onToggleCam,
    onToggleMic,
    onToggleBlur,
    onSwitchCam,
    facingMode,
    hasVideo,
    hasAudio,
    streamView,
    playStream,
  } = useViewController({
    stream,
    constraints,
    onChangeConstraints,
  });

  const cls = ["stream-preview"];
  (!hasVideo || !stream) && cls.push("__video-disabled");

  return (
    <div className={cls.join(" ")} ref={streamView} onClick={onShowControls}>
      {!hasVideo && <RippleAvatarAnimation className="stream-preview__animation" size={60} user={produceUser} />}
      <TransitionControl className="stream-preview__video-wrapper-header" showControl={desktopUp || !showControls}>
        <div className="stream-preview__live-user mt-3 ml-4 label fw-semibold">
          {screenName ?? produceUser?.screenName ?? ""}
        </div>
      </TransitionControl>
      <video
        className={"stream-preview__video" + (facingMode !== "environment" ? " __mirror-reflection" : "")}
        ref={player}
        muted={!hasAudio}
        playsInline
      />
      {showStreamPlayButton && <PlayStreamButton onClick={playStream} />}
      <PanelControlButtons
        className="stream-preview__control-buttons"
        produce
        consume={false}
        loading={loading}
        showControlsButtons={showControlsButtons}
        constraints={constraints}
        onClickEndCall={undefined}
        onSwitchCam={onSwitchCam}
        onToggleMic={onToggleMic}
        onToggleCam={onToggleCam}
        onToggleBlur={onToggleBlur}
        player={player.current}
      />
      <div className="stream-preview__shadow" />
    </div>
  );
}

export default StreamPreview;
