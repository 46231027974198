import { useMount } from "react-use";
import { PaymentSystemPublic } from "@scrile/api-provider/dist/api/PaymentSystemsProvider";
import { InputStripeDepositPackagePurchase } from "@scrile/api-provider/dist/api/StripePurchaseProvider";
import useController from "./controller";
import useAppState from "../../../../hooks/useAppState";
import useBaseForm from "../../../../hooks/useBaseForm";
import { parseErrors } from "../../../../lib/requestHelpers";
import { submitPaymentCB } from "../../types";

export default function useViewController(
  setOnSubmitPaymentCB: (cb: submitPaymentCB) => void,
  pricePackageId: string,
  currentSystem: PaymentSystemPublic | null
) {
  const { systemSettings } = useAppState();
  const { sendSubmit } = useController();
  const { fields, values, errors, setValues, setErrors } = useBaseForm([]);

  const urlPurchaseSuccess = new URL("/payments/stripe/thankyou.html", window.location.origin);
  const urlPurchaseCancel = new URL("/payments/stripe/thankyou.html", window.location.origin);
  urlPurchaseSuccess.searchParams.set("result", "success");
  urlPurchaseCancel.searchParams.set("result", "canceled");

  const onSubmit = async () => {
    const data: InputStripeDepositPackagePurchase = {
      successUrl: urlPurchaseSuccess.toString(),
      cancelUrl: urlPurchaseCancel.toString(),
      pricePackageId: pricePackageId,
      paymentSystemId: currentSystem?.id || "",
      currency: systemSettings?.mainCurrency || "",
    };
    try {
      setErrors({});
      return await sendSubmit(data);
    } catch (e) {
      const errors = parseErrors(e);
      setErrors(errors);
    }
  };

  useMount(() => {
    setOnSubmitPaymentCB(onSubmit);
  });

  return {
    fields,
    values,
    errors,
    setValues,
    setErrors,
  };
}
