import { useEffect, useMemo, useState } from "react";
import { InputPayeeInformation, PayeeInformation } from "@scrile/api-provider/dist/api/PayeeInformationProvider";
import { PayoutSystemSettings } from "@scrile/api-provider/dist/api/PayoutSystemSettingsProvider";
import useAuthUser from "hooks/useAuthUser";
import useMounted from "hooks/useMounted";
import providers from "lib/providers";
import { FormValues } from "types";
import { payeeSystemsList, PayoutSystemType } from "./config";

export default function useController() {
  const [loading, setLoading] = useState(false);
  const [configuredPayoutSystem, setConfiguredPayoutSystem] = useState<PayoutSystemSettings[]>([]);
  const [currentPayeeInfo, setCurrentPayeeInfo] = useState<PayeeInformation | null>(null);
  const mounted = useMounted();
  const { user } = useAuthUser();

  const getCurrentPayeeInfo = async () => {
    if (!user) return;
    setCurrentPayeeInfo(await providers.PayeeInformationProvider.find({ id: user.id }));
  };

  const getConfigurePayoutSystems = async () => {
    const result = await providers.PayoutSystemSettingsProvider.findAll();
    setConfiguredPayoutSystem(result);
  };

  // get only supported payout system based on backend response
  const payoutSystems = useMemo(
    () =>
      payeeSystemsList.filter(
        (i) => !!configuredPayoutSystem.find((s) => s.systemName === i.value && s.currencies.length > 0)
      ),
    [configuredPayoutSystem]
  );

  // get data on mount
  useEffect(() => {
    setLoading(true);
    Promise.all([getConfigurePayoutSystems(), getCurrentPayeeInfo()]).finally(
      () => mounted.current && setLoading(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendData = async (values: FormValues) => {
    if (!user) return;
    const data: InputPayeeInformation = {
      userId: user.id,
      payoutSystem: values.payoutSystem || "",
      currency: values.currency || "",
      ...(values.payoutSystem === PayoutSystemType.PAYPAL
        ? {
            paypal: {
              account: values.paypalAccount || "",
            },
          }
        : {}),
      ...(values.payoutSystem === PayoutSystemType.OTHER
        ? {
            other: {
              details: values.otherDetails || "",
            },
          }
        : {}),
    };
    await providers.PayeeInformationProvider.update({ data });
  };

  return {
    loading,
    payoutSystems,
    currentPayeeInfo,
    configuredPayoutSystem,
    sendData,
  };
}
