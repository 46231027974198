import React, { useCallback } from "react";
import i18next, { TFunction } from "i18next";
import { initReactI18next, Trans, useTranslation as useI18nTranslation } from "react-i18next";
import en from "./en";

type InterpolationMap = Record<string, string | number | boolean>;

interface I18nProps {
  path: string;
  components?: Record<string, React.ReactNode>;
  values?: Record<string, any>;
}

let tFunction: TFunction;
i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          ...en,
        },
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  })
  .then((t) => (tFunction = t));

export const t = (key: string, interpolationMap?: InterpolationMap) => {
  if (!tFunction) return key;
  return tFunction(key, interpolationMap);
};

export function useTranslation() {
  const { t } = useI18nTranslation();

  return { t: useCallback((key: string, interpolationMap?: InterpolationMap) => t(key, interpolationMap), [t]) };
}

export function I18n({ path, components, values }: I18nProps) {
  return <Trans i18nKey={path} components={components} values={values} />;
}
