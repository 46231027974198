import React from "react";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { useTranslation } from "locales";
import "./style.scss";

interface Props {
  user: UserPublicData;
  className?: string;
}

function BaseTags({ user, className = "" }: Props) {
  const { t } = useTranslation();

  const cls = ["base-tags"];
  cls.push(className);

  const tags = user.questionary?.tags || [];

  return (
    <ul className={cls.join(" ")}>
      {tags.map((tag) => {
        return (
          <li className="base-tags__item" key={tag.id}>
            {t(tag.title)}
          </li>
        );
      })}
    </ul>
  );
}

export default BaseTags;
