import React, { useRef } from "react";
import { useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import ConferenceShare, { CopyHandle } from "components/ConferenceShare";
import ModalWindow from "components/ModalWindow";
import "./style.scss";

interface Props {
  conferenceId: string;
  show?: boolean;
  onClose: () => void;
}

function ConferenceShareModal({ show = false, onClose, conferenceId }: Props) {
  const { t } = useTranslation();
  const shareRef = useRef<CopyHandle>(null);

  return (
    <ModalWindow show={show} onClose={onClose} className="conference-share-modal">
      <ConferenceShare conferenceId={conferenceId} ref={shareRef} hideText customCaption={t("Share link")} />
      <div className="conference-share-modal__buttons">
        <BaseButton className="conference-share-modal__btn" outline large onClick={onClose}>
          {t("Close")}
        </BaseButton>
        <BaseButton className="conference-share-modal__btn" large onClick={(e) => shareRef.current?.onCopyText(e)}>
          {t("Copy link")}
        </BaseButton>
      </div>
    </ModalWindow>
  );
}

export default ConferenceShareModal;
