import { useEffect, useMemo, useRef, useState } from "react";
import useMobileBlurOnOrientationChanged from "hooks/useMobileBlurOnOrientationChanged";

interface Data {
  value: string;
  rows: number;
  maxRows: number;
  rowHeight: number;
  autoGrow: boolean;
  showLengthCounter: boolean;
  setTextarea?: (textArea: HTMLTextAreaElement | null) => void;
}

export default function useViewController({
  value,
  rows,
  rowHeight,
  autoGrow,
  maxRows,
  showLengthCounter,
  setTextarea,
}: Data) {
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [contentHeight, setContentHeight] = useState(rowHeight);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const minHeight = useMemo(() => rows * rowHeight, [rows, rowHeight]);
  const maxHeight = useMemo(() => Math.max(maxRows * rowHeight, minHeight), [maxRows, rowHeight, minHeight]);
  const currentHeight = useMemo(() => {
    const defaultHeight = rows * rowHeight;
    if (!autoGrow) return defaultHeight;
    return Math.min(contentHeight, maxHeight);
  }, [autoGrow, contentHeight, maxHeight, rowHeight, rows]);
  const count = useMemo(() => {
    if (!showLengthCounter) return 0;
    return value.length;
  }, [showLengthCounter, value]);

  let paddingY = 0;
  if (ref.current) {
    const style = getComputedStyle(ref.current);
    paddingY = parseFloat(style["paddingTop"]) + parseFloat(style["paddingBottom"]);
  }

  useEffect(() => {
    const el = ref.current;
    if (!el) return;
    const initialValue = el.value;
    const h = el.style.height;
    el.value = value;
    el.style.height = "0px";
    setContentHeight(el.scrollHeight - paddingY);
    el.value = initialValue;
    el.style.height = h;
  }, [currentHeight, value, paddingY]);

  useEffect(() => {
    if (ref.current && setTextarea) {
      setTextarea(ref.current);
      setScrollbarWidth(ref.current.offsetWidth - ref.current.clientWidth);
    }
  }, [setTextarea]);

  useMobileBlurOnOrientationChanged(ref);

  return {
    ref,
    scrollbarWidth,
    minHeight,
    maxHeight,
    currentHeight,
    count,
    paddingY,
  };
}
