import { useEffect, useState } from "react";
import { getMediaDuration } from "@scrile/tools/dist/lib/TimeHelpers";
import { useTranslation } from "locales";
import useBaseForm from "hooks/useBaseForm";
import { MediaFilesController } from "lib/MediaFileController";
import { parseErrors } from "lib/requestHelpers";
import { validateFieldsWithException } from "lib/validation";
import { FormValues } from "types";
import useController from "./controller";
import { questionnaireFields } from "./questionnaireFields";

function useViewController() {
  const [processing, setProcessing] = useState(false);
  const { fields, values, errors, setErrors, setValues } = useBaseForm(questionnaireFields);
  const onChange = (values: FormValues) => setValues(values);
  const { t } = useTranslation();

  const { sendQuestionnaire, loading, dataFields, dataVideo, onUploadVideo, onDeleteVideo } = useController();

  useEffect(() => {
    setValues(dataFields);
  }, [dataFields, setValues]);

  const onInputAvatar = async (files: FileList | null) => {
    const file = files && files[0];
    if (file) {
      await onUploadVideo(file);
    } else if (dataVideo) {
      await onDeleteVideo();
    }
  };

  const onSendQuestionnaire = async () => {
    try {
      validateFieldsWithException(fields, values);
      setProcessing(true);
      await sendQuestionnaire(values);
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      setProcessing(false);
    }
  };

  const src = (dataVideo && MediaFilesController.getVideoThumbnail(dataVideo.urlPart, "480p")) || "";
  const buttonText = src ? t("Replace video") : t("Upload video");
  const duration = (dataVideo && getMediaDuration(Number(dataVideo.metadata.duration))) || "";

  return {
    errors,
    setErrors,
    fields,
    values,
    onChange,
    onSendQuestionnaire,
    onInputAvatar,
    src,
    duration,
    buttonText,
    processing,
    loading,
  };
}

export default useViewController;
