import { useEffect, useState } from "react";
import useAppState from "hooks/useAppState";
import useBaseForm from "hooks/useBaseForm";
import emitter, { EVENTS } from "lib/emitter";
import { formatMoneyWithCodeToNumber } from "lib/moneyHelper";
import { parseErrors } from "lib/requestHelpers";
import { validateFieldsWithException } from "lib/validation";
import { FormValues } from "types";
import useController from "./controller";

export default function useViewController(userId: string, threadId: string, onClose: () => void) {
  const { sendTip } = useController();
  const { balance } = useAppState();

  const { fields, values, errors, setValues, setErrors } = useBaseForm([
    {
      name: "tip-field",
      type: "text",
      placeholder: "0 Cr",
      textAlign: "center",
      validation: {
        required: true,
      },
      maskOptions: {
        mask: "[9]{1,20} Cr",
        placeholder: "0 Cr",
      },
    },
  ]);
  const [processing, setProcessing] = useState(false);
  const [showRefillButton, setShowRefillButton] = useState(false);
  const [showSuccessStatus, setShowSuccessStatus] = useState(false);
  const onChange = (values: FormValues) => setValues(values);

  useEffect(() => {
    const amount = formatMoneyWithCodeToNumber(values["tip-field"]);
    if (amount > balance) {
      setErrors({ "tip-field": "Insufficient funds" });
      setShowRefillButton(true);
    } else {
      setErrors({ "tip-field": "" });
      setShowRefillButton(false);
    }
  }, [values, balance, setErrors]);

  const onSendTip = async () => {
    const amount = formatMoneyWithCodeToNumber(values["tip-field"]);
    try {
      validateFieldsWithException(fields, values);
      setProcessing(true);
      await sendTip(userId, threadId, amount);
      setShowSuccessStatus(true);
    } catch (e) {
      setErrors(parseErrors(e));
    } finally {
      setProcessing(false);
    }
  };

  const onCloseModal = () => {
    setShowSuccessStatus(false);
    setValues({});
    onClose();
  };

  const onRefillBalance = () => {
    emitter.emit(EVENTS.BALANCE_REFILL_SHOW);
  };

  return {
    onSendTip,
    fields,
    values,
    errors,
    setErrors,
    onChange,
    processing,
    showRefillButton,
    onRefillBalance,
    showSuccessStatus,
    onCloseModal,
  };
}
