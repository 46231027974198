import React from "react";
import { GuestUser } from "@scrile/api-provider/dist/api/GuestUsersProvider";
import { LiveChatUser } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { useTranslation } from "locales";
import useMobileKeyboardOpen from "hooks/useMobileKeyboard";
import { isPerformer } from "lib/usersHelper";
import TipModal from "modals/TipModal";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import BaseTextarea from "components/BaseTextarea";
import MessageAttachView from "components/MessageAttachView";
import MessageAttachButton from "./components/MessageAttachButton";
import useController from "./controller";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  disableAttachments?: boolean;
  onFocus?: (e: React.FocusEvent) => void;
  className?: string;
  maxRows?: number;
  transparentOnBlur?: boolean;
  userIds?: string[];
  threadIds?: string[];
  allowGuestMessage?: boolean;
  guestUser?: GuestUser | null;
  setGuestUser?: (guest: GuestUser) => void;
  me?: LiveChatUser;
}

function MessageInput({
  userIds,
  threadIds,
  disableAttachments = false,
  onFocus,
  className,
  transparentOnBlur,
  allowGuestMessage,
  guestUser,
  setGuestUser,
  me,
  maxRows = 5,
}: Props) {
  const { t } = useTranslation();
  const {
    user,
    value,
    setValue,
    onSendMessage,
    setTextarea,
    attachedFiles,
    addAttachment,
    removeAttachment,
    removeAllAttachments,
  } = useController(userIds, threadIds, allowGuestMessage, guestUser, setGuestUser, me);
  const { showTipModal, setShowTipModal } = useViewController();
  const { keyboardOpen } = useMobileKeyboardOpen();
  const cls = ["message-input"];
  if (className) cls.push(className);
  if (transparentOnBlur && !keyboardOpen) cls.push("__transparent");

  return (
    <div className={cls.join(" ")} onClick={(e) => e.stopPropagation()}>
      {!disableAttachments ? (
        attachedFiles.filter((file) => "uploadProgress" in file).length > 0 ? (
          <BaseButton icon={true} opacity={true} className="mr-1" name="delete-files" onClick={removeAllAttachments}>
            <BaseIcon>close</BaseIcon>
          </BaseButton>
        ) : (
          <MessageAttachButton className="mr-1 mt-auto message-input__btn" onInput={addAttachment} />
        )
      ) : null}
      <div className="message-input__input-wrapper">
        {attachedFiles.map((attachedFile) => (
          <div className="mb-1" key={attachedFile.id}>
            <MessageAttachView attachedFileData={attachedFile} removeAttachment={removeAttachment} />
          </div>
        ))}
        <BaseTextarea
          setTextarea={setTextarea}
          className="message-input__input"
          value={value}
          type="textarea"
          onInput={setValue}
          name="message-input"
          placeholder={t("Write your message...")}
          maxRows={maxRows}
          rowHeight={23}
          autoGrow={true}
          rows={1}
          maxLength={3000}
          submit="enter"
          onSubmit={onSendMessage}
          onFocus={onFocus}
        />
      </div>
      {threadIds?.length === 1 && userIds?.length === 1 && !isPerformer(user) && (
        <BaseButton
          className="mt-auto mr-1"
          icon={true}
          opacity={true}
          onClick={() => setShowTipModal(true)}
          name="open-tip"
        >
          <BaseIcon>pay-service</BaseIcon>
        </BaseButton>
      )}
      <BaseButton
        className="mt-auto message-input__btn"
        icon={true}
        opacity={true}
        onClick={onSendMessage}
        name="send-message"
      >
        <BaseIcon>send</BaseIcon>
      </BaseButton>
      {threadIds?.length === 1 && userIds?.length === 1 && !isPerformer(user) && (
        <TipModal
          threadId={threadIds[0]}
          userId={userIds[0]}
          show={showTipModal}
          onClose={() => setShowTipModal(false)}
        />
      )}
    </div>
  );
}

export default MessageInput;
