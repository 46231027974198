import React from "react";
import "./style.scss";
import { useTranslation } from "locales";
import useAuthUser from "hooks/useAuthUser";
import ActionStatus from "components/ActionStatus";
import BaseButton from "components/BaseButton";

function ConfirmationLongCode({ status }: { status: "pending" | "success" | "failed" }) {
  const { t } = useTranslation();
  const { user } = useAuthUser();
  const showLoginButton = !user;

  let headerText = "Loading...";
  let messageText = "";

  if (status === "success") {
    headerText = "Registration success";
    messageText = !showLoginButton ? "Please proceed to a login form" : "";
  } else if (status === "failed") {
    headerText = "Verification failed";
    messageText = "Incorrect verification code";
  }

  return (
    <ActionStatus title={t(headerText)} description={t(messageText)} status={status} className="notification-window">
      {status === "failed" ? (
        <BaseButton className="notification-window__button" large={true} to={"/signup/confirm"}>
          {t("Try again")}
        </BaseButton>
      ) : showLoginButton ? (
        <BaseButton className="notification-window__button" large={true} to={"/login"}>
          {t("Log In")}
        </BaseButton>
      ) : null}
    </ActionStatus>
  );
}

export default ConfirmationLongCode;
