import { useMemo } from "react";
import { GuestUser } from "@scrile/api-provider/dist/api/GuestUsersProvider";
import { Message } from "@scrile/api-provider/dist/api/MessagesProvider";
import { User } from "@scrile/api-provider/dist/api/UserProvider";
import useBreakPoints from "hooks/useBreakPoints";
import { MediaFilesController } from "lib/MediaFileController";
import { AttachedFileData } from "types";

export default function useViewController(
  message: Message,
  authUser: User | null,
  isLiveChat?: boolean,
  guestUser?: GuestUser | null
) {
  const { desktopUp } = useBreakPoints();

  const selfMessage = guestUser ? message.link?.subject.subjectId === guestUser.id : message.senderId === authUser?.id;
  let highlightMessage: boolean;
  if (isLiveChat) {
    highlightMessage = !!message.senderUser?.publicLivechatUser?.broadcasting;
  } else {
    highlightMessage = !selfMessage;
  }

  const attachedFile = useMemo(() => message.attachment.find((f) => !!f.file)?.file ?? null, [message]);

  const attachedFileData: AttachedFileData | null = useMemo(() => {
    if (!attachedFile) return null;
    const src = attachedFile.metadata.contentType.startsWith("image")
      ? MediaFilesController.getImageThumbnail(attachedFile.urlPart, "720p")
      : undefined;
    return {
      id: attachedFile.fileId.subjectId,
      mediaType: attachedFile.metadata.contentType,
      uploadProgress: 100,
      fileName: attachedFile.metadata.filename,
      size: Number(attachedFile.metadata.contentLength),
      src,
    };
  }, [attachedFile]);

  const link = useMemo(() => {
    if (!attachedFile) return undefined;
    return MediaFilesController.getFileUrl(attachedFile.urlPart);
  }, [attachedFile]);

  return {
    desktopUp,
    highlightMessage,
    selfMessage,
    attachedFileData,
    link,
  };
}
