import React from "react";
import { useTranslation } from "locales";
import BaseIcon from "components/BaseIcon";
import "./style.scss";

interface Props {
  value: number;
  onClick?: (value: number) => void;
  errorMessage?: string;
  className?: string;
  enabled?: boolean;
}

export default function BaseRating({ value, onClick, errorMessage, className, enabled }: Props) {
  const { t } = useTranslation();

  const cls = ["base-rating"];
  if (errorMessage) cls.push("__error");
  if (enabled) cls.push("__enabled");
  if (className) cls.push(className);

  const onClickStar = (value: number) => {
    if (enabled) {
      onClick && onClick(value);
    }
  };

  return (
    <div className={cls.join(" ")}>
      {enabled && <div className="label mb-2">{`*${t("Select a rating")}`}</div>}
      <div className={"base-rating__star-container"}>
        {Array.from(new Array(5))
          .map((i, index) => {
            const active = index < value;
            return (
              <BaseIcon
                key={index}
                className={"base-rating__star" + (active ? " __active" : "")}
                onClick={() => onClickStar(index + 1)}
              >
                {active ? "favorites_active" : "favorites"}
              </BaseIcon>
            );
          })
          .reverse()}
      </div>
      {errorMessage && <div className="base-rating__error-message label mt-3">{errorMessage}</div>}
    </div>
  );
}
