import React from "react";
import { Link } from "react-router-dom";
import { Review } from "@scrile/api-provider/dist/api/ReviewProvider";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { I18n, useTranslation } from "locales";
import ActionStatus from "components/ActionStatus";
import BaseButton from "components/BaseButton";
import BaseForm from "components/BaseForm";
import BaseRating from "components/BaseRating";
import ContentHeader from "components/ContentHeader";
import ModalWindow from "components/ModalWindow";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  show: boolean;
  onClose: () => void;
  recipientUser: UserPublicData;
  onReviewAdded?: (review: Review) => void;
}

export default function LeaveReviewModal({ show, onClose, recipientUser, onReviewAdded }: Props) {
  const {
    fields,
    values,
    errors,
    onChange,
    setErrors,
    rating,
    onSetRating,
    ratingErrorMessage,
    onPublishReview,
    processing,
    showSuccessStatus,
    onCloseModal,
  } = useViewController(recipientUser, onClose, onReviewAdded);
  const { t } = useTranslation();

  return (
    <ModalWindow className="leave-review-modal" show={show} onClose={onCloseModal}>
      {showSuccessStatus ? (
        <ActionStatus
          status="success"
          title={t("Review is published")}
          className="leave-review-modal__success"
          description={
            <I18n
              path="Your review will be displayed in the <profile>Consultant’s profile</profile>"
              components={{
                profile: <Link className="blue-link" to={`/${recipientUser.username}`} onClick={onCloseModal} />,
              }}
            />
          }
        >
          <BaseButton className="mt-6" name="awesome" onClick={onCloseModal} fluid large>
            {t("Awesome")}
          </BaseButton>
        </ActionStatus>
      ) : (
        <BaseForm
          className="leave-review-modal__form"
          fields={fields}
          values={values}
          errors={errors}
          setErrors={setErrors}
          onChange={onChange}
          headline={
            <>
              <ContentHeader>{t("Write a review")}</ContentHeader>
              <BaseRating
                className="mb-4"
                value={rating}
                onClick={onSetRating}
                errorMessage={ratingErrorMessage}
                enabled
              />
            </>
          }
        >
          <div className="leave-review-modal__buttons d-flex">
            <BaseButton className="leave-review-modal__btn" onClick={onCloseModal} outline large>
              {t("Cancel")}
            </BaseButton>
            <BaseButton
              name="submit"
              className="leave-review-modal__btn"
              onClick={onPublishReview}
              large
              loading={processing}
            >
              {t("Publish")}
            </BaseButton>
          </div>
        </BaseForm>
      )}
    </ModalWindow>
  );
}
