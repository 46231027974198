import { useCallback, useEffect, useState } from "react";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import useAuthUser from "hooks/useAuthUser";
import useModalLinkPrinter from "hooks/useModalLinkPrinter";
import providers from "lib/providers";

interface Props {
  user: UserPublicData;
  favorite?: boolean;
}

export default function useController({ user, favorite }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFavorite, setIsFavorite] = useState(favorite);
  const { user: loggedUser } = useAuthUser();
  const { getLocation } = useModalLinkPrinter();

  const isAuth = !!loggedUser;
  const isYourself = loggedUser?.id === user?.id;
  const loginLink = isAuth ? undefined : getLocation("/login");

  useEffect(() => {
    if (isYourself || !isAuth) {
      return;
    }

    if (favorite !== undefined) {
      setIsFavorite(favorite);
      return;
    }

    providers.FollowingsProvider.find({ data: { userId: user.id } }).then((response) => {
      setIsFavorite(response.result.some((item) => item.followerId === loggedUser?.id));
    });
  }, [favorite, isAuth, isYourself, loggedUser, user.id]);

  const onFavoriteToggle = useCallback(async () => {
    if (!isLoading && isAuth) {
      setIsLoading(true);
      try {
        setIsFavorite(await providers.FollowingsProvider.toggle({ id: user.id }));
      } finally {
        setIsLoading(false);
      }
    }
  }, [isAuth, isLoading, user.id]);

  return {
    loginLink,
    isYourself,
    isFavorite,
    isLoading,
    onFavoriteToggle,
  };
}
