import { Message } from "@scrile/api-provider/dist/api/MessagesProvider";
import { InputMessageThreadMarkRead } from "@scrile/api-provider/dist/api/MessageThreadsProvider";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import config from "config";
import emitter, { EVENTS } from "lib/emitter";
import providers from "lib/providers";
import useAuthUser from "./useAuthUser";
import useModalLinkPrinter from "./useModalLinkPrinter";

function useMessages() {
  const { user: authUser } = useAuthUser();
  const { getLocation } = useModalLinkPrinter();

  const markRead = async (message: Message) => {
    const data: InputMessageThreadMarkRead = {
      messageId: message.id,
      messageThreadId: message.threadId,
    };
    await providers.MessageThreadsProvider.markRead({ data });
    emitter.emit(EVENTS.MESSAGES_MARKED_READ, message);
  };

  const getMessageUrl = (user: UserPublicData) => {
    return authUser ? (authUser?.role === config.userJoinRole ? `/messages/${user.id}` : "") : getLocation("/login");
  };

  return { markRead, getMessageUrl };
}

export default useMessages;
