import React from "react";
import { Link } from "react-router-dom";
import { UserPublicData } from "@scrile/api-provider/dist/projects/webvideo/UserPublicDataProvider";
import { useTranslation } from "locales";
import AddToFavorite from "components/AddToFavorite";
import BaseButton from "components/BaseButton";
import BaseCard from "components/BaseCard";
import BaseIcon from "components/BaseIcon";
import BaseTags from "components/BaseTags";
import CalendlyButton from "components/CalendlyButton";
import CardAvatar from "components/CardAvatar";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  user: UserPublicData;
  favorite?: boolean;
}

function PerformerCard({ user, favorite }: Props) {
  const { t } = useTranslation();
  const { askLink, showBooking } = useViewController(user);
  const toProfile = {
    pathname: user.username,
    state: { fromPerformers: true },
  };

  return (
    <BaseCard className="performer-card">
      <div className="performer-card__inner">
        <CardAvatar
          to={toProfile}
          user={user}
          className="performer-card__avatar-container"
          classNameAvatar="performer-card__avatar"
        />
        <div className="performer-card__status-line">
          <AddToFavorite className="performer-card__favorite pa-0" user={user} favorite={favorite} />
        </div>
        <div className="performer-card__container">
          <div className="performer-card__description">
            <Link className="performer-card__link" to={toProfile}>
              <h3 className="performer-card__name">{user.screenName}</h3>
            </Link>
            {user?.questionary?.fields.location && (
              <div className="performer-card__location">
                <BaseIcon className="performer-card__location-icon">location</BaseIcon>
                <span className="performer-card__location-text">{user.questionary?.fields.location as string}</span>
              </div>
            )}
            {user.questionary?.fields.about && (
              <p className="performer-card__about">{user?.questionary?.fields?.about as string}</p>
            )}
            {user.questionary?.tags && <BaseTags user={user} className="performer-card__tags" />}
            <div className="performer-card__bottom-buttons">
              {askLink && (
                <BaseButton to={askLink} outline={true} className="performer-card__button performer-card__ask-button">
                  {t("Ask a question")}
                </BaseButton>
              )}
              {showBooking && (
                <CalendlyButton
                  className="performer-card__button"
                  providerUser={user}
                  scheduleUrl={user.calendlyScheduleUrl!}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  );
}

export default PerformerCard;
