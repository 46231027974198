import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "locales";
import useViewController from "./viewController";
import "./style.scss";
import config from "../../config";

function PageFooter() {
  const { t } = useTranslation();
  const { isShowFooter } = useViewController();
  if (!isShowFooter) {
    return null;
  }
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <ul className="footer__links">
            <li className="footer__links-item">
              <Link className="footer__link" to="/messages/1">
                {t("Support")}
              </Link>
            </li>
            <li className="footer__links-item">
              <a className="footer__link" href="/static/terms-of-use.html" target="_blank">
                {t("Terms of Use")}
              </a>
            </li>
            <li className="footer__links-item">
              <a className="footer__link" href="/static/privacy-policy.html" target="_blank">
                {t("Privacy Policy")}
              </a>
            </li>
          </ul>

          <div className="footer__copyright">
            {!config.featureHideScrileLink && (
              <a className="footer__link" href="https://www.scrile.com/meet" target="_blank" rel="noreferrer">
                {t("Powered by Scrile Meet ")}
              </a>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default PageFooter;
