import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useUnmount } from "react-use";
import {
  LiveChatJoinResponse,
  LivechatSubjectType,
  LiveChatUser,
} from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { StreamingHelper } from "@scrile/streaming-client";
import { TrackOptions } from "@scrile/streaming-client/build/ConsumerClient";
import { parseISO } from "@scrile/tools/dist/lib/TimeHelpers";
import useLiveChatName from "hooks/useLiveChatName";
import useProduceController from "hooks/useProduceController";
import useStreamHelper from "hooks/useStreamHelper";
import { removeTracksFromStream } from "lib/mediaStreamHelpers";
import providers from "lib/providers";
import { ConferenceUser, ConferenceUserData } from "types";
import useLiveChat, { UserBroadcastingCB } from "pages/PageLiveChat/hooks/useLiveChat";

interface ConsumeProps {
  token: string;
  joinData: LiveChatJoinResponse | null;
  setOnUserBroadcastingCB: (cb: UserBroadcastingCB) => UserBroadcastingCB;
}

function useConsumeController({ token, joinData }: ConsumeProps) {
  const { checkStreamForChanges } = useStreamHelper();
  const consumeUserIds = useRef<string[]>([]);
  const [users, setUsers] = useState<Record<string, ConferenceUserData>>({});

  const updateUser = useCallback(
    (broadcasterChatUserId: string, { stream, ...data }: Partial<ConferenceUserData>) => {
      const user = joinData?.users.find((i) => i.id === broadcasterChatUserId);
      if (!user) return;
      setUsers((prevState) => {
        const localStream = prevState[broadcasterChatUserId]?.stream ?? new MediaStream([]);
        stream && localStream && checkStreamForChanges(stream, localStream);
        return {
          ...prevState,
          [broadcasterChatUserId]: {
            ...prevState[broadcasterChatUserId],
            user,
            ...data,
            stream: localStream,
          },
        };
      });
    },
    [checkStreamForChanges, joinData]
  );

  const removeUser = useCallback(
    (broadcasterChatUserId: string) => {
      const user = users[broadcasterChatUserId];
      if (!user) return;

      user.WebRTC.closeAllStreams();
      user.stream && removeTracksFromStream(user.stream, user.stream.getTracks());
      user.screenStream && removeTracksFromStream(user.screenStream, user.screenStream.getTracks());

      setUsers((prevState) => {
        return Object.entries(prevState).reduce((acc, [id, user]) => {
          if (id !== broadcasterChatUserId) acc[id] = user;
          return acc;
        }, {} as Record<string, ConferenceUserData>);
      });
      consumeUserIds.current = consumeUserIds.current.filter((i) => i !== broadcasterChatUserId);
    },
    [users]
  );

  const onConsumeStream = useCallback(
    async (broadcasterChatUserId: string) => {
      if (consumeUserIds.current.includes(broadcasterChatUserId)) return;
      consumeUserIds.current.push(broadcasterChatUserId);
      try {
        const connection = await providers.LivechatsProvider.watch({
          token,
          broadcasterChatUserId,
        });
        const WebRTC = new StreamingHelper();

        const stream = await WebRTC.consumeStream({
          connection: connection,
          tracks: {
            audio: !!users[broadcasterChatUserId]?.constrains?.audioEnabled,
            video: !!users[broadcasterChatUserId]?.constrains?.videoEnabled,
            screen: !!users[broadcasterChatUserId]?.constrains?.shareEnabled,
          },
        });
        updateUser(broadcasterChatUserId, { stream, connection, WebRTC });
      } catch (e) {
        consumeUserIds.current = consumeUserIds.current.filter((id) => id !== broadcasterChatUserId);
      }
    },
    [token, updateUser, users]
  );

  const closeAllStream = () => {
    for (const userId of Object.keys(users)) {
      removeUser(userId);
    }
  };

  useEffect(() => {
    joinData &&
      joinData.users
        .filter((i) => i.id !== joinData.me.id)
        .forEach((i) => {
          i.broadcasting && onConsumeStream(i.id).then();
        });
  }, [joinData, onConsumeStream]);

  useEffect(() => {
    if (!joinData) return;
    const activeUserIds = joinData.users.filter((i) => i.id !== joinData.me.id).map((i) => i.id);
    consumeUserIds.current.forEach((id) => !activeUserIds.includes(id) && removeUser(id));
  }, [joinData, removeUser, users]);

  useEffect(() => {
    async function CB(data: Required<TrackOptions>, user: ConferenceUserData) {
      const newMediaConstraints = { videoEnabled: data.video, audioEnabled: data.audio, shareEnabled: data.screen };
      const newStream = await user.WebRTC.consumeStream({
        connection: user.connection,
        tracks: { audio: data.audio, video: data.video, screen: data.screen },
      });
      updateUser(user.user.id, {
        stream: newStream,
        connection: user.connection,
        WebRTC: user.WebRTC,
        constrains: newMediaConstraints,
      });
    }
    const cbRecords: Record<string, typeof CB> = {};

    Object.values(users).forEach((user) => {
      const cb = (data: Required<TrackOptions>) => CB(data, user);
      cbRecords[user.user.id] = cb;
      user.WebRTC.on("producers-updated", cb);
    });
    return () => Object.values(users).forEach((user) => user.WebRTC.off("producers-updated", cbRecords[user.user.id]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useUnmount(closeAllStream);

  return {
    users,
    closeStream: closeAllStream,
  };
}

export default function useProduceConsumeController(livechatSubject: Subject<LivechatSubjectType>) {
  const { liveChatName } = useLiveChatName();
  const { loading, token, joinData, setOnUserBroadcastingCB, endChat, clientServerTimeDiff } = useLiveChat({
    livechatSubject,
    liveChatName,
  });

  const {
    streamToProduce: produceStream,
    screenStream: produceScreenStream,
    mediaConstraints: produceMediaConstraints,
    closeStream: closeProduceStream,
    onChangeConstraints,
    onScreenShare,
    audioInputs,
    videoInputs,
    processing,
    loading: loadingProduceData,
    onProducerFinishedStream,
  } = useProduceController({ token, joinData, autoProduceStream: true });

  const { closeStream: closeConsumeStream, users } = useConsumeController({
    joinData,
    token,
    setOnUserBroadcastingCB,
  });

  const { checkStreamForChanges } = useStreamHelper();

  const streamStartedDate = useMemo(() => {
    if (!joinData) {
      return "";
    }
    return new Date(parseISO(joinData.me.joinTime).getTime() + (clientServerTimeDiff ?? 0)).toISOString();
  }, [clientServerTimeDiff, joinData]);

  const localProduceStream = useRef<MediaStream>(produceStream);
  const localProduceScreenStream = useRef<MediaStream>(produceScreenStream);
  const [userList, setUserList] = useState<LiveChatUser[]>([]);
  const [usersToView, setUsersToView] = useState<ConferenceUser[]>([]);
  const [me, setMe] = useState<ConferenceUser | null>(null);

  useEffect(() => {
    if (!produceStream || !localProduceStream.current) return;
    checkStreamForChanges(produceStream, localProduceStream.current);
  }, [produceStream, checkStreamForChanges]);

  useEffect(() => {
    if (!produceScreenStream || !localProduceScreenStream.current) return;
    checkStreamForChanges(produceScreenStream, localProduceScreenStream.current);
  }, [produceScreenStream, checkStreamForChanges]);

  useEffect(() => {
    setMe(
      joinData
        ? {
            user: joinData.me,
            stream: localProduceStream.current,
            screenStream: localProduceScreenStream.current,
            constrains: produceMediaConstraints,
          }
        : null
    );
  }, [joinData, produceMediaConstraints, produceStream]);

  useEffect(() => {
    setUserList(joinData?.users.filter((user) => user.broadcasting) ?? []);
  }, [joinData]);

  useEffect(() => {
    setUsersToView(Object.values(users));
  }, [users]);

  useEffect(() => {
    window.addEventListener("beforeunload", endChat);
    return () => {
      window.removeEventListener("beforeunload", endChat);
    };
  }, [endChat]);

  const onCloseStream = async () => {
    closeProduceStream().then();
    closeConsumeStream();
    await Promise.all([onProducerFinishedStream(), endChat()]);
  };

  return {
    threadId: joinData?.threadId,
    userList,
    produceMediaConstraints,
    loading: loading || loadingProduceData || processing,
    token,
    audioInputs,
    videoInputs,
    users: usersToView,
    me,
    streamStartedDate,
    onChangeConstraints,
    onProducerFinishedStream,
    onScreenShare,
    onCloseStream,
  };
}
