import { useState } from "react";
import { Appointment } from "@scrile/api-provider/dist/projects/webvideo/AppointmentProvider";
import { closePopupWidget } from "react-calendly";
import config from "config";
import useAuthUser from "hooks/useAuthUser";
import { usePostMessage } from "hooks/usePostMessage";
import { EventScheduledEvent } from "components/CalendlyButton/types";
import useController from "./controller";

export default function useViewController() {
  const { user } = useAuthUser();
  const [showModal, setShowModal] = useState(false);
  const [appointment, setAppointment] = useState<Appointment | null>(null);

  const { providerUser, setProviderUser, registerAppointment } = useController();
  usePostMessage(window, config.webviewTargetOrigin, JSON.stringify(appointment));

  const onEventScheduled = async (event: EventScheduledEvent) => {
    closePopupWidget();
    setShowModal(true);

    const data = {
      calendlyUri: event.data.payload.event.uri,
      providerId: providerUser?.id || "",
    };
    const response = await registerAppointment(data);
    setAppointment(response);
  };

  const onClosePaymentBooking = () => {
    setShowModal(false);
    setAppointment(null);
    setProviderUser(null);
  };

  return {
    user,
    showModal,
    providerUser,
    appointment,
    onEventScheduled,
    onClosePaymentBooking,
  };
}
