import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetSet } from "react-use";
import { Message, MessagesSearchResult } from "@scrile/api-provider/dist/api/MessagesProvider";
import { MessageThread } from "@scrile/api-provider/dist/api/MessageThreadsProvider";
import { UserPublicData } from "@scrile/api-provider/dist/api/UserPublicDataProvider";
import { throttle } from "lodash-es";
import useMessages from "hooks/useMessages";
import emitter, { EVENTS } from "lib/emitter";
import providers from "lib/providers";

export default function useController(userId: string, shouldScrollToBottom: MutableRefObject<boolean>) {
  const [getMessagesState, setMessagesState] = useGetSet<MessagesSearchResult<Message> | null>(null);
  const [getMessageThread, setMessageThread] = useGetSet<MessageThread | null>(null);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [senderUser, setSenderUser] = useState<UserPublicData | null>(null);

  const history = useHistory();

  const { markRead } = useMessages();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const markReadThrottled = useCallback(throttle(markRead, 1000), []);

  const onObserve = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    const thread = getMessageThread();
    const messages = getMessagesState();
    let minUnreadMessageId = Number(thread?.minUnreadMessageId ?? "0");

    entries.forEach((entry) => {
      if (entry && entry.isIntersecting) {
        const interestingId = Number(entry.target.id.split("message-")[1]);

        if (minUnreadMessageId > 0 && minUnreadMessageId <= interestingId) {
          const latestReadMessage = messages?.result.find((message) => message.id === interestingId.toString());
          if (!latestReadMessage) return;
          markReadThrottled(latestReadMessage)?.then(() => {
            shouldScrollToBottom.current = true;
            if (minUnreadMessageId > 0 || minUnreadMessageId < interestingId) {
              const lastMessage = messages?.result[messages?.result.length - 1];
              if (lastMessage?.id === interestingId.toString()) {
                minUnreadMessageId = -1;
              }
              setMessageThread(
                (thread) =>
                  (thread && {
                    ...thread,
                    minUnreadMessageId: (minUnreadMessageId + 1).toString(),
                    unreadMessageCount: Math.max(thread.unreadMessageCount - 1, 0),
                  }) ||
                  thread
              );
            }
          });
        }
        observer.unobserve(entry.target);
      }
    });
  };
  const messageObserver = useRef<IntersectionObserver>(new IntersectionObserver(onObserve));
  const setObserve = (el: HTMLDivElement | null) => {
    if (el) {
      messageObserver.current?.observe(el);
    }
  };

  useEffect(() => {
    (async function () {
      setLoadingMessages(true);
      try {
        const localUser = await getUserFromRequest();
        setSenderUser(localUser);
        if (!localUser) {
          history.push("/messages");
          return;
        }
        await getMessagesFromRequest();
      } finally {
        setLoadingMessages(false);
      }
    })();

    return () => {
      setMessagesState(null);
      setMessageThread(null);
      setSenderUser(null);
      setLoadingMessages(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, history]);

  const loadMore = async (cb?: () => void) => {
    const messages = getMessagesState();
    if (messages && !messages.hasNextPage) return;
    await getMessagesFromRequest(messages ? messages.page + 1 : 0, false).finally(() => cb && cb());
  };
  const getUserFromRequest = async (): Promise<UserPublicData | null> => {
    const response = await providers.UserPublicDataProvider.search({ data: { id: [userId] } });
    return response.result.find((i) => i.id === userId) || null;
  };
  const getMessagesFromRequest = async (page = 0, replace = true) => {
    let thread: MessageThread | null = null;
    const messageThread = getMessageThread();
    if (!messageThread || replace) {
      thread = await providers.MessageThreadsProvider.findPersonal({ opponentId: userId }).catch(() => null);
      setMessageThread(thread);
    }
    if (!messageThread && !thread) {
      return;
    }

    const response = await providers.MessagesProvider.find({
      data: {
        threadId: thread?.id || messageThread?.id,
        sort: "TIME,DESC",
        page,
      },
    });
    setMessagesState((prevState) => {
      return {
        ...response,
        result: replace || !prevState?.result ? response.result : prevState.result.concat(response.result),
      };
    });
  };

  const addMessage = (message: Message) => {
    const messages = getMessagesState();
    const messageThread = getMessageThread();
    if (!messageThread || !messages) {
      getMessagesFromRequest();
      return;
    } else {
      if (messages.hasNextPage) {
        messages.result.pop();
      }
      messages.result.unshift(message);
      setMessagesState(messages);
    }
  };

  useEffect(() => {
    const localObserver = messageObserver.current;
    const receivedMessage = (message: Message) => {
      const messages = getMessagesState();
      const messageThread = getMessageThread();
      if (!messageThread) {
        return getMessagesFromRequest();
      }
      if (message.threadId !== messageThread?.id || messages?.result.find((m) => m.id === message.id)) {
        return;
      }
      addMessage(message);
      setMessageThread((thread) => {
        if (!thread) return thread;
        return {
          ...messageThread,
          unreadMessageCount: messageThread.unreadMessageCount + 1,
          minUnreadMessageId: messageThread.minUnreadMessageId === "0" ? message.id : messageThread.minUnreadMessageId,
        };
      });
    };
    emitter.on<any>(EVENTS.MESSAGES_NEW_MESSAGE, receivedMessage);
    return () => {
      localObserver.disconnect();
      emitter.off<any>(EVENTS.MESSAGES_NEW_MESSAGE, receivedMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    messages: getMessagesState()?.result || [],
    thread: getMessageThread(),
    loadingMessages,
    senderUser,
    loadMore,
    scrollMessages: !loadingMessages && (getMessagesState()?.hasNextPage ?? false),
    setObserve,
    minUnreadMessageId: getMessageThread()?.minUnreadMessageId,
  };
}
