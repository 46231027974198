import { t } from "locales";
import { FieldInterface } from "types";

export const questionnaireFields: FieldInterface[] = [
  {
    name: "title",
    type: "text",
    label: t("Title"),
  },
  {
    name: "description",
    type: "textarea",
    label: t("Description"),
    autoGrow: true,
    maxRows: 3,
    maxLength: 150,
    showLengthCounter: true,
  },
];
