import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-use";
import { isPublicLiveChatNotificationDetails } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { I18n } from "../../../../../locales";
import { Props, SystemNotificationFactory } from "../types";

export default function PublicLiveChatNotification({ addHandler, removeHandler }: Props) {
  const history = useHistory();
  const location = useLocation();

  const handler = useCallback<SystemNotificationFactory>(
    (notification) => {
      const user = notification.user;
      const path = `/${user?.username}/live`;
      if (!isPublicLiveChatNotificationDetails(notification.details) || !user || location.pathname === path) {
        return false;
      }
      return {
        text: (
          <I18n
            path={"Your favorite consultant <screenName>{{screenName}}</screenName> has just started a live session"}
            components={{
              screenName: <strong style={{ whiteSpace: "nowrap" }} />,
            }}
            values={{ screenName: user?.screenName ?? "" }}
          />
        ),
        image: {
          icon: "favorites",
        },
        onClick: () => history?.push(`/${user.username}/live`),
      };
    },
    [history, location.pathname]
  );

  useEffect(() => {
    addHandler("publicLiveChatNotification", handler);
    return () => removeHandler("publicLiveChatNotification");
  }, [addHandler, handler, removeHandler]);

  return null;
}
