import React from "react";
import { Transaction } from "@scrile/api-provider/dist/api/TransactionProvider";
import { useTranslation } from "locales";
import DefaultTransaction from "../DefaultTransaction";

interface Props {
  transaction: Transaction;
}

function TransactionCorrection({ transaction }: Props) {
  const { t } = useTranslation();
  return (
    <DefaultTransaction
      transaction={transaction}
      type={t("Correction")}
      description={<>{transaction.details.description}</>}
    />
  );
}

export default TransactionCorrection;
