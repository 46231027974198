import React from "react";
import { I18n, useTranslation } from "locales";
import BaseButton from "components/BaseButton";
import BaseIcon from "components/BaseIcon";
import BaseTabs from "components/BaseTabs";
import ContentHeader from "components/ContentHeader";
import ModalWindow from "components/ModalWindow";
import PayeeInformation from "./components/PayeeInformation";
import PersonalInformation from "./components/PersonalInformation";
import PhotoId from "./components/PhotoId";
import StepsMobile from "./components/StepsMobile";
import useViewController from "./useViewController";
import "./style.scss";

export default function PerformerVerification() {
  const { t } = useTranslation();
  const { onClose, onNext, onPrev, onTabClick, show, step, steps, success } = useViewController();
  const title = !success ? "Become a Consultant" : "Account was successfully sent for verification";

  return (
    <ModalWindow show={show} onClose={onClose}>
      <div className="performer-verification">
        <BaseIcon className="performer-verification__icon mb-4" size={70}>
          rocket
        </BaseIcon>
        <ContentHeader className="mb-2">{t(title)}</ContentHeader>
        {!success && (
          <>
            <div className="caption mb-5 text-center">
              <I18n
                path={"<span>{{step}}</span> of {{steps}}"}
                components={{
                  span: <span className="caption__mark" />,
                }}
                values={{
                  step,
                  steps: steps.length,
                }}
              />
            </div>
            <div className="performer-verification__desktop-only mb-8">
              <BaseTabs itemList={steps} gradual={true} selected={steps[step - 1]} onClick={onTabClick} />
            </div>
            <div className="performer-verification__mobile-only mb-8">
              <StepsMobile itemList={steps} selected={steps[step - 1]} onClick={onTabClick} />
            </div>
            <PersonalInformation
              className="performer-verification__step1"
              style={{ display: step !== 1 ? "none" : undefined }}
              onCancel={onClose}
              onNext={onNext}
            />
            <PhotoId
              className="performer-verification__step2"
              style={{ display: step !== 2 ? "none" : undefined }}
              onPrev={onPrev}
              onNext={onNext}
            />
            <PayeeInformation
              className="performer-verification__step3"
              style={{ display: step !== 3 ? "none" : undefined }}
              onPrev={onPrev}
              onNext={onNext}
            />
          </>
        )}
        {success && (
          <BaseButton className="mt-5" onClick={onClose} large={true}>
            {t("Awesome")}
          </BaseButton>
        )}
      </div>
    </ModalWindow>
  );
}
