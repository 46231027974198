import React from "react";
import { useHistory } from "react-router-dom";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import BasePage from "components/BasePage";
import ConferenceStreamController from "./components/ConferenceStreamController";
import "./style.scss";

interface Props {
  conferenceId: string;
}

export default function PageConferenceChat({ conferenceId }: Props) {
  const history = useHistory();
  const livechatSubject: Subject<LivechatSubjectType> = {
    subjectId: conferenceId,
    subjectType: LivechatSubjectType.CONFERENCE,
  };

  const onClose = () => history.push("/");

  return (
    <BasePage className="page-conference-chat">
      <ConferenceStreamController livechatSubject={livechatSubject} conferenceId={conferenceId} onClose={onClose} />
    </BasePage>
  );
}
