import { login, TwitterResponse, setTwitterResponse } from "@scrile/tools/dist/oauth/twitter";
import config from "config";
import providers from "./providers";

declare global {
  interface Window {
    TWTRAuthHandler: (data: TwitterResponse | null) => void;
  }
}

const getTwitterUrl = () =>
  providers.LoginProvider.twitterLoginGetAuthUrl({
    callbackUrl: config.twitterCallbackUrl,
  });

export default {
  login: () => {
    window.TWTRAuthHandler = setTwitterResponse;
    return login(getTwitterUrl).finally(() => {
      delete window.TWTRAuthHandler;
    });
  },
};
