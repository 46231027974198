import React from "react";
import { Link } from "react-router-dom";
import { I18n, useTranslation } from "locales";
import ActionStatus from "components/ActionStatus";
import BaseButton from "components/BaseButton";
import BaseForm from "components/BaseForm";
import ContentHeader from "components/ContentHeader";
import ModalWindow from "components/ModalWindow";
import useViewController from "./viewController";
import "./style.scss";

interface Props {
  show: boolean;
  userId: string;
  threadId: string;
  onClose: () => void;
}

export default function TipModal({ show, onClose, userId, threadId }: Props) {
  const {
    onSendTip,
    fields,
    values,
    onChange,
    errors,
    setErrors,
    processing,
    showRefillButton,
    onRefillBalance,
    showSuccessStatus,
    onCloseModal,
  } = useViewController(userId, threadId, onClose);
  const { t } = useTranslation();

  return (
    <ModalWindow className="tip-modal" show={show} onClose={onCloseModal}>
      {!showSuccessStatus ? (
        <BaseForm
          fields={fields}
          values={values}
          onChange={onChange}
          errors={errors}
          setErrors={setErrors}
          className="tip-modal__form"
          headline={<ContentHeader className="mb-10">{t("Pay service")}</ContentHeader>}
          onSubmitEnter={onSendTip}
        >
          {showRefillButton ? (
            <div className="tip-modal__wrapper-buttons mt-5">
              <BaseButton className="tip-modal__wrapper-buttons-btn" onClick={onCloseModal} large outline>
                {t("Not now")}
              </BaseButton>
              <BaseButton
                className="tip-modal__wrapper-buttons-btn"
                name="refill-balance"
                onClick={onRefillBalance}
                large
              >
                {t("Refill balance")}
              </BaseButton>
            </div>
          ) : (
            <BaseButton
              className="mt-6"
              name="send-tip"
              onClick={onSendTip}
              loading={processing}
              disabled={!values["tip-field"]}
              fluid
              large
            >
              {t("Send")}
            </BaseButton>
          )}
        </BaseForm>
      ) : (
        <ActionStatus
          className="tip-modal__success-window"
          status={"success"}
          title={t("The money was transferred successfully")}
          description={
            <span className="tip-modal__success-window-description">
              <I18n
                path="Payment information will be displayed in the <balance>Balance history</balance>"
                components={{
                  balance: <Link to="/user/transactions" className="tip-modal__link" />,
                }}
              />
            </span>
          }
        >
          <BaseButton className="mt-6" onClick={onCloseModal} fluid large>
            {t("Awesome")}
          </BaseButton>
        </ActionStatus>
      )}
    </ModalWindow>
  );
}
