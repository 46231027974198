import { useEffect, useMemo, useRef, useState } from "react";
import { useGetSet, useUnmount } from "react-use";
import { LivechatSubjectType } from "@scrile/api-provider/dist/api/LivechatsProvider";
import { Subject } from "@scrile/api-provider/dist/api/SubjectProvider";
import { WebRTCConnection } from "@scrile/api-provider/dist/api/WebRTCProvider";
import { TrackOptions } from "@scrile/streaming-client/build/ConsumerClient";
import { parseISO } from "@scrile/tools/dist/lib/TimeHelpers";
import providers from "lib/providers";
import { MediaConstraints } from "types";
import useLiveChat from "pages/PageLiveChat/hooks/useLiveChat";
import useOnAuthorised from "./useOnAuthorised";
import useWebRTC from "./useWebRTC";

function useConsumeController(userId: string, livechatSubject: Subject<LivechatSubjectType>) {
  const { loading, token, userList, joinData, setOnUserBroadcastingCB, clientServerTimeDiff, endChat } = useLiveChat({
    livechatSubject,
  });

  const WebRTC = useWebRTC();
  const [stream, setStream] = useState<MediaStream>(new MediaStream([]));
  const connection = useRef<WebRTCConnection>();
  const [getConnecting, setConnecting] = useGetSet(true);
  const [mediaConstraints, setMediaConstraints] = useState<MediaConstraints | null>(null);

  const streamStartedDate = useMemo(() => {
    const producer = joinData?.users.find((i) => i.userId === userId);
    if (!producer) {
      return "";
    }
    return new Date(parseISO(producer.joinTime).getTime() + clientServerTimeDiff).toISOString();
  }, [clientServerTimeDiff, joinData, userId]);

  const consumeStream = async (broadcasterChatUserId: string) => {
    setConnecting(true);
    connection.current = await providers.LivechatsProvider.watch({
      token,
      broadcasterChatUserId,
    });
    setStream(
      await WebRTC.consumeStream({
        connection: connection.current,
        tracks: {
          audio: !!mediaConstraints?.videoEnabled,
          video: !!mediaConstraints?.audioEnabled,
          screen: !!mediaConstraints?.shareEnabled,
        },
      }).then((res) => new MediaStream(res))
    );
    setMediaConstraints(null);
  };

  setOnUserBroadcastingCB(({ chatUserId, broadcasting }) => (broadcasting ? consumeStream(chatUserId) : closeStream()));

  const closeStream = () => {
    WebRTC.closeAllStreams();
    setStream(new MediaStream([]));
  };

  const shouldCallConsumeStream = useRef(true);
  useEffect(() => {
    if (!joinData || !shouldCallConsumeStream.current) return;
    const producer = joinData.users.find((i) => i.userId === userId);
    if (producer && producer.broadcasting) {
      shouldCallConsumeStream.current = false;
      consumeStream(producer.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinData, shouldCallConsumeStream.current]);

  useOnAuthorised(() => {
    shouldCallConsumeStream.current = true;
  });

  useEffect(() => {
    const CB = async (data: Required<TrackOptions>) => {
      if (getConnecting()) {
        setConnecting(false);
      }
      setMediaConstraints({ videoEnabled: data.video, audioEnabled: data.audio });
      setStream(
        await WebRTC.consumeStream({
          connection: connection.current!,
          tracks: { audio: data.audio, video: data.video },
        }).then((res) => new MediaStream(res))
      );
    };
    WebRTC.on("producers-updated", CB);
    return () => {
      WebRTC.off("producers-updated", CB);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WebRTC]);

  useUnmount(closeStream);

  return {
    stream,
    connecting: getConnecting(),
    loading,
    userList,
    streamStartedDate,
    mediaConstraints,
    threadId: joinData?.threadId,
    token,
    closeStream,
    endChat,
    users: joinData?.users ?? [],
  };
}

export default useConsumeController;
